<script setup>
defineProps({
  projectId: {
    type: Number,
    default: null,
  },
  timelogIds: {
    type: Array,
    default: undefined,
  },
  expenseIds: {
    type: Array,
    default: undefined,
  },
});

const emit = defineEmits(['close', 'save']);

const modelValue = defineModel({ type: Boolean, default: false });

const FinanceInvoiceMoveExpenseOrTimelogDialogPanel = defineAsyncComponent(
  () => import('./FinanceInvoiceMoveExpenseOrTimelogDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceInvoiceMoveExpenseOrTimelogDialogPanel
        :closing="!modelValue"
        :projectId="projectId"
        :timelogIds="timelogIds"
        :expenseIds="expenseIds"
        @close="
          close();
          emit('close');
        "
        @save="emit('save')"
      />
    </template>
  </WidgetDialog>
</template>
