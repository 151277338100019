<script setup>
import { useRoute, useCurrentProject } from '@/route';
import { useI18n } from '@/util';
import { useAppShellSidebar } from '@/appShell';
import { useFormPendoEvents } from '../useFormPendoEvents';

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    default: 10,
  },
  shouldLoadOnScroll: {
    type: Boolean,
    default: true,
  },
});

const count = defineModel('count', { type: Number, required: true });

const route = useRoute();
const { t } = useI18n();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { trackFormsPanelViewed } = useFormPendoEvents();
const forms = computed(() => unref(props.state.items));
const routeFormId = computed(() => Number(route.params?.formId));

function transformColorName(colorName) {
  switch (colorName) {
    case 'black':
      return '#000';
    case 'yellow':
      return '#ffc63b';
    case 'indigo':
      return '#4361db';
    case 'pink':
      return '#ff61c7';
    case 'purple':
      return '#895ef6';
    case 'orange':
      return '#ff7640';
    case 'blue':
      return '#4da1f7';
    case 'red':
      return '#e22c42';
    case 'teal':
      return '#4cd6e3';
    case 'green':
      return '#4dcc97';
    default: // white and grey will use accent color or default
      return '#bbb';
  }
}

function getFormIconBackgroundColor(form) {
  const colorName = form.content.banner.primaryColor;
  if (colorName === 'white') {
    return '#222';
  }
  return transformColorName(colorName);
}

onMounted(() => {
  const project = useCurrentProject();
  const isProjectTemplate = project.value?.isTemplate;
  // Using a timeout to make sure forms have loaded to get an accurate count before sending event.
  setTimeout(() => {
    trackFormsPanelViewed(forms.value.length, isProjectTemplate);
  }, 1000);
});
</script>

<template>
  <WidgetLoadingState :state="state" :loadingMessage="t('Loading forms')">
    <template #default>
      <div class="h-full overflow-y-auto">
        <VList density="compact" class="m-0 !px-4 !pt-2">
          <VListItem
            v-for="form in forms"
            :key="form.hostObject.id"
            :href="form.token.canonicalURL"
            target="blank"
            :active="routeFormId === form.id"
            v-bind="VListItemSidebarProjectsDrawer"
            class="group"
            @click="clearActiveDrawerPanelIfNotPinned"
          >
            <template #prepend>
              <div
                class="mr-2 flex size-8 shrink-0 items-center justify-center rounded-full transition-all duration-300 group-hover:scale-105"
                :style="{ 'background-color': getFormIconBackgroundColor(form) }"
              >
                <LscIcon class="text-icon-on-primary" icon="lsi-file-document" />
              </div>
            </template>
            <VListItemTitle :title="form.name" class="text-body-1 font-medium">
              {{ form.content.name }}
            </VListItemTitle>
            <VListItemSubtitle v-if="form.project" class="text-body-2">{{ form.project.name }}</VListItemSubtitle>
            <template #append>
              <RouterLink
                v-LsdTooltip="t('Edit form')"
                :aria-label="t('Edit form')"
                class="group ml-2 size-8"
                :to="`/projects/${form.hostObject.id}/forms/${form.id}`"
              >
                <LscIcon size="sm" class="text-icon-subtle group-hover:text-icon-default" icon="lsi-edit" />
              </RouterLink>
            </template>
          </VListItem>
        </VList>
        <WidgetLoadingLazy v-model:count="count" :step="step" :state="state" />
      </div>
    </template>
    <template #blank>
      <slot name="blank" />
    </template>
  </WidgetLoadingState>
</template>
