import { computed, unref } from 'vue';
import { useListLoader } from '../base/useListLoader';

const searchResultKeyMapping = new Map([['value', 'title']]);

/**
 * Search result item
 * @typedef {Object} KnowledgeBaseSearchResult search result item
 * @property {number} id
 * @property {string} url full url string for article
 * @property {string} title article name string
 * @property {string} categorySlug category slug (parsed from url)
 * @property {string} articleSlug article slug (parsed from url)
 */

function responseToItems({ data: rawData }) {
  const data = rawData.map((rawResult) => {
    const result = {};
    Object.keys(rawResult).forEach((key) => {
      const normalizedKey = searchResultKeyMapping.get(key) || key;
      result[normalizedKey] = rawResult[key];
    });

    // eslint-disable-next-line no-unsafe-optional-chaining
    const [articleSlug, categorySlug] = rawResult?.url?.replace(/\/$/, '').split('/').reverse();

    return {
      ...result,
      articleSlug,
      categorySlug,
    };
  });

  return data;
}

/**
 * Use the search endpoint to search for all articles in the knowledge base
 * @param {string} searchString query string for search
 * @returns {Object} state listLoader state
 * @returns {KnowledgeBaseSearchResult[]} state.items search results
 */
export function useKnowledgeBaseSearchResultLoader({ params: _params, count = 100, pageSize = 100 }) {
  const loaderParams = computed(() => {
    const params = unref(_params) || {};
    const searchString = unref(params?.searchTerm) ?? '';

    return {
      query: searchString.trim(),
    };
  });

  if (!loaderParams.value.query) {
    throw new Error('Search term not provided');
  }

  const { state } = useListLoader({
    url: `https://support.teamwork.com/projects/search.json`,
    params: loaderParams,
    responseToItems,
    count,
    pageSize,
  });

  return state;
}
