<script setup>
import { useI18n } from '@/util';
import { useCurrentAccount, usePermissions } from '@/api';
import { useLegacyBridge } from '@/module/legacy';

const { t } = useI18n();
const { showLegacyModal } = useLegacyBridge();
const { canViewSettings } = usePermissions();
const account = useCurrentAccount();

const templatesProjectId = computed(() => account.value?.tasklistTemplateProjectId);
</script>
<template>
  <LscEmptyState
    size="sm"
    class="my-8"
    :title="t('No templates yet')"
    :message="t('You haven\'t any task list templates yet')"
  >
    <template #actions>
      <LscButton
        v-if="canViewSettings"
        variant="primary"
        prependIcon="lsi-add"
        @click.stop="
          showLegacyModal({
            modalName: 'addOrEditTaskListTemplate',
            params: {
              projectId: templatesProjectId,
            },
          })
        "
      >
        {{ t('Add template list') }}
      </LscButton>
    </template>
  </LscEmptyState>
</template>
