<script setup>
import { CommandCenterDialog } from '@/module/commandCenter';
import { useI18n, useKeyboardShortcut } from '@/util';
import { useExperimentA2413c } from '@/api';
import AppShellSidebarNavigationItem from './AppShellSidebarNavigationItem.vue';
import AppShellSidebarNavigationMoreMenu from './AppShellSidebarNavigationMoreMenu.vue';
import AppShellSidebarQuickAddMenu from './AppShellSidebarQuickAddMenu.vue';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useAppShellSidebarTracking } from './useAppShellSidebarTracking';

const { t } = useI18n();
const {
  activeDrawerPanel,
  dockedNavItems,
  moreNavItems,
  isSidebarExpanded,
  closeSidebarOnMobile,
  toggleActiveDrawerPanel,
  clearActiveDrawerPanelIfNotPinned,
} = useAppShellSidebar();
const { trackLeftNavigationClicked, trackLeftNavigationLayout, trackShortcutKeyPressed } = useAppShellSidebarTracking({
  isSidebarExpanded,
});
const { DEFAULT_GROUP_ITEMS_KEYS, PLAN_REPORT_GROUP_ITEMS_KEYS, isExpA2413cVariation } = useExperimentA2413c();

const dialogName = shallowRef('');
const isDialogOpen = shallowRef(false);

// #region Experiment A-24-13c - Grouped & personalised side nav layout
const namedGroups = [
  {
    groupKey: 'manage',
    label: t('Manage'),
    dockedItems: computed(() =>
      dockedNavItems.value.filter(
        (item) =>
          !DEFAULT_GROUP_ITEMS_KEYS.includes(item.itemKey) && !PLAN_REPORT_GROUP_ITEMS_KEYS.includes(item.itemKey),
      ),
    ),
  },
  {
    groupKey: 'planReport',
    label: t('Plan & Report'),
    dockedItems: computed(() =>
      dockedNavItems.value.filter((item) => PLAN_REPORT_GROUP_ITEMS_KEYS.includes(item.itemKey)),
    ),
  },
];

const defaultGroupItems = computed(() =>
  dockedNavItems.value.filter((item) => DEFAULT_GROUP_ITEMS_KEYS.includes(item.itemKey)),
);
// #endregion

function handleNavItemClicked(navItem) {
  if (navItem.drawerPanel) {
    toggleActiveDrawerPanel(navItem.drawerPanel);
  } else {
    clearActiveDrawerPanelIfNotPinned();
  }

  // Do not send the event for the drawer closing click
  if (!navItem.drawerPanel || (navItem.drawerPanel && activeDrawerPanel.value)) {
    trackLeftNavigationClicked(navItem.pendoLabel);
  }

  if (!navItem.keepOpenOnMobile) {
    closeSidebarOnMobile();
  }
  navItem.onClick?.();

  if (navItem.dialogName) {
    dialogName.value = navItem.dialogName;
    isDialogOpen.value = true;
  }
}

[...dockedNavItems.value, ...moreNavItems.value].forEach((navItem) => {
  if (navItem.keyboardShortcut) {
    useKeyboardShortcut(
      navItem.keyboardShortcut,
      () => {
        handleNavItemClicked(navItem);
        trackShortcutKeyPressed(navItem.pendoLabel);
      },
      { activeOnInput: navItem.keyboardShortcutActiveOnInput ?? false },
    );
  }
});

onMounted(() => {
  trackLeftNavigationLayout(
    isExpA2413cVariation.value
      ? [
          ...defaultGroupItems.value.map((item) => item.pendoLabel),
          'more',
          ...namedGroups.flatMap((group) => group.dockedItems.value.map((item) => item.pendoLabel)),
        ].join('|')
      : [...dockedNavItems.value.map((item) => item.pendoLabel), 'more'].join('|'),
  );
});
</script>

<template>
  <nav class="overflow-y-auto overflow-x-hidden px-6 pt-5 scrollbar-on-dark" v-bind="$attrs">
    <!-- #region Experiment A-24-13c - Grouped & personalised side nav layout -->
    <template v-if="isExpA2413cVariation">
      <!-- First, "default" group without name -->
      <ul class="list-none space-y-1">
        <li class="pb-1">
          <AppShellSidebarQuickAddMenu />
        </li>
        <li v-for="item in defaultGroupItems" :key="item.itemKey">
          <AppShellSidebarNavigationItem :item="item" @click="handleNavItemClicked(item)" />
        </li>
        <li v-if="moreNavItems.length">
          <AppShellSidebarNavigationMoreMenu role="menu" aria-labelledby="more-menu-button">
            <template #activator="activator">
              <AppShellSidebarNavigationItem
                v-bind="activator.props"
                id="more-menu-button"
                :item="{
                  icon: 'lsi-sidenav-more',
                  text: t('More'),
                  fixed: true,
                  keepOpenOnMobile: true,
                  pendoLabel: 'more',
                  dataIdentifier: 'side-nav-more-button',
                }"
                :isActive="activator.isActive"
              />
            </template>
          </AppShellSidebarNavigationMoreMenu>
        </li>
      </ul>
      <template v-for="group in namedGroups" :key="group.groupKey">
        <div
          v-if="group.dockedItems.value?.length > 0"
          class="mt-4 min-w-9 border border-x-0 border-b-0 border-solid border-[--lsds-c-sidebar-drawer-upgrade-now-color-surface] pt-5"
        >
          <LscOverflowEllipsis
            v-if="isSidebarExpanded"
            class="mb-2 block text-body-2 font-semibold uppercase text-subtle"
          >
            {{ group.label }}
          </LscOverflowEllipsis>
          <ul class="list-none space-y-1">
            <li v-for="item in group.dockedItems.value" :key="item.itemKey">
              <AppShellSidebarNavigationItem :item="item" @click="handleNavItemClicked(item)" />
            </li>
          </ul>
        </div>
      </template>
    </template>
    <!-- #endregion -->
    <template v-else>
      <ul class="list-none space-y-2">
        <li>
          <AppShellSidebarQuickAddMenu />
        </li>
        <li v-for="item in dockedNavItems" :key="item.itemKey">
          <AppShellSidebarNavigationItem :item="item" @click="handleNavItemClicked(item)" />
        </li>
        <li v-if="moreNavItems.length">
          <AppShellSidebarNavigationMoreMenu role="menu" aria-labelledby="more-menu-button">
            <template #activator="activator">
              <AppShellSidebarNavigationItem
                v-bind="activator.props"
                id="more-menu-button"
                :item="{
                  icon: 'lsi-sidenav-more',
                  text: t('More'),
                  fixed: true,
                  keepOpenOnMobile: true,
                  pendoLabel: 'more',
                  dataIdentifier: 'side-nav-more-button',
                }"
                :isActive="activator.isActive"
              />
            </template>
          </AppShellSidebarNavigationMoreMenu>
        </li>
      </ul>
    </template>
  </nav>
  <LscSlotSwitch :name="dialogName">
    <template #CommandCenterDialog>
      <CommandCenterDialog v-model="isDialogOpen" />
    </template>
  </LscSlotSwitch>
</template>
