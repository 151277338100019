export const searchRoutes = [
  {
    path: '/search',
    component: () => import('./RouteSearch.vue'),
  },
  {
    path: '/search/:query(.*)*',
    component: () => import('./RouteSearch.vue'),
  },
];
