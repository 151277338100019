<script setup>
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import { useI18n } from '@/util';
import {
  STEP_ADD_AI_GENERATED_TASKS,
  PREVIEW_TAB_TABLE,
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  SAMPLE_AI_GENERATED_TASKS_DATA,
} from '../constants';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import { useOnboardingWizard } from '../useOnboardingWizard';
import { useAIGeneratedTasks } from './useAIGeneratedTasks';
import { useTasklistActions, useTaskActions, usePreferences } from '@/api';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n();
const { currentProjectId, currentProject } = useOnboardingWizard();
const { generateTasksJson, promise, tasksJSON, hasError, errorType } = useAIGeneratedTasks();
const { createTasklist } = useTasklistActions();
const { createTask } = useTaskActions();
const router = useRouter();
const { onboardingTasklistGeneration } = usePreferences();
const toast = useLsToast();
const { trackGoalBasedAddTasks } = useOnboardingWizardTracking();

const inputEl = shallowRef(null);
const projectType = shallowRef('');
const briefDescription = shallowRef('');
const thumbsUpOrDownClicked = shallowRef(false);
const generateTasksClicked = shallowRef(false);
const projectTypeError = shallowRef(false);
const briefDescriptionError = shallowRef(false);

const projectName = computed(() => currentProject.value?.name);
const hasGeneratedTasks = computed(() => tasksJSON.value?.length > 0);
const previewData = computed(() => {
  let tasklistsData = SAMPLE_AI_GENERATED_TASKS_DATA.tasklists;
  if (hasGeneratedTasks.value) {
    let identifierCounter = 1;
    tasklistsData = tasksJSON.value.map((taskList, index) => {
      return {
        ...taskList,
        name: taskList.taskListName,
        tasks: taskList.tasks.map((task) => ({
          ...task,
          identifier: `${identifierCounter++}`,
          columnId: `${[1, 2, 3].find((id) => id === index + 1)}`,
        })),
      };
    });
  }
  return {
    tasklists: tasklistsData,
    boardColumns: SAMPLE_AI_GENERATED_TASKS_DATA.boardColumns,
  };
});
const reachedTasklistGenerationLimit = computed(() => onboardingTasklistGeneration.value.count >= 3);
const generatedAtLeastOnce = computed(() => onboardingTasklistGeneration.value.count > 0);

function completeStep() {
  onboardingTasklistGeneration.value = {
    ...onboardingTasklistGeneration.value,
    completed: true,
  };
}

function nextStep() {
  if (hasGeneratedTasks.value) {
    createTaskLists(tasksJSON.value);
    toast.success({
      title: t('Task lists have been added to your project'),
      actionText: t('Visit project'),
      action: () => router.push({ path: `/projects/${currentProjectId.value}/` }),
    });
  }
  completeStep();
  trackGoalBasedAddTasks({
    event_action: 'finish_clicked',
    project_type_prompt: projectType.value,
    project_description_prompt: briefDescription.value,
  });
  emit('close', false);
}

function skipStep() {
  router.push({ path: `/projects/${currentProjectId.value}/` });
  trackGoalBasedAddTasks({
    event_action: 'no_thanks_clicked',
  });
  emit('close', false);
}

function createTaskLists(tasklists = []) {
  return Promise.all(
    tasklists.map(async (tl) => {
      const { id: tasklistId } = await createTasklist({
        name: tl.taskListName,
        description: tl.taskListDescription,
        projectId: currentProjectId.value,
      });
      trackGoalBasedAddTasks({
        event_action: 'tasklist_created',
        tasklist_id: tasklistId,
        tasklist_name: tl.taskListName,
        tasklist_description: tl.taskListDescription,
      });
      return Promise.all(
        tl.tasks.map((task) =>
          createTask({
            name: task.name,
            description: task.description,
            // tags: task.tag,
            priority: task.priority,
            startDate: DateTime.now(),
            dueDate: DateTime.now().plus({ days: task.dueDay }),
            estimateMinutes: task.estimatedMinutes,
            tasklistId,
          }),
        ),
      );
    }),
  );
}

function generateTasks() {
  if (!reachedTasklistGenerationLimit.value) {
    generateTasksJson(projectName.value, projectType.value, briefDescription.value);
    projectTypeError.value = projectType.value.length < 5 || /^[0-9]+$/.test(projectType.value);
    briefDescriptionError.value = briefDescriptionError.value.length < 10 || /^[0-9]+$/.test(briefDescription.value);
    thumbsUpOrDownClicked.value = false;
    generateTasksClicked.value = true;
    trackGoalBasedAddTasks({
      event_action: 'generate_tasks_clicked',
      project_type_prompt: projectType.value,
      project_description_prompt: briefDescription.value,
    });
  }
}

function thumbsUpClicked() {
  thumbsUpOrDownClicked.value = true;
  trackGoalBasedAddTasks({
    event_action: 'feedback_button_clicked',
    feedback_type: 'thumbs_up',
    project_type_prompt: projectType.value,
    project_description_prompt: briefDescription.value,
  });
}

function thumbsDownClicked() {
  thumbsUpOrDownClicked.value = true;
  trackGoalBasedAddTasks({
    event_action: 'feedback_button_clicked',
    feedback_type: 'thumbs_down',
    project_type_prompt: projectType.value,
    project_description_prompt: briefDescription.value,
  });
}

watch(hasGeneratedTasks, () => {
  if (hasGeneratedTasks.value) {
    toast.success({
      title: reachedTasklistGenerationLimit.value ? t('Your tasks are ready') : t('Your tasks have been created'),
      message: t(
        "Review and click 'Finish' to add these tasks to your project. You can always edit them later by visiting your project. | You can use AI to regenerate your tasks {n} more time | You can use AI to regenerate your tasks up to {n} more times",
        { n: 3 - onboardingTasklistGeneration.value.count },
      ),
    });
  }
});

watch(hasError, () => {
  if (hasError.value) {
    toast.critical(t('Error generating tasks. Please try again.'));
    trackGoalBasedAddTasks({
      event_action: 'error',
      error_type: errorType.value,
    });
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Build your project with AI-Generated tasks')"
    :description="
      t(
        'Let {teamwork}’s AI assistant create a tailored project for you. Using our internal and industry best practices, we’ll organize your tasks into structured task lists to help you meet your goals efficiently.',
        { teamwork: 'Teamwork.com' },
      )
    "
    class="-mb-4 mt-4"
  >
    <p class="font-semibold">{{ t('What type of project would you like to create?') }}</p>
    <VTextField
      ref="inputEl"
      v-model.trim="projectType"
      :placeholder="t('e.g. Website development')"
      :maxLength="255"
      :autoFocus="true"
      class="my-3"
    />

    <p class="font-semibold">{{ t('Please provide a brief description or objective for this project') }}</p>
    <VTextarea
      v-model.trim="briefDescription"
      :placeholder="
        t(
          'e.g. The objective of this project is to design a user-friendly, visually appealing website that effectively showcases our brand, engages visitors, and drives conversions.',
        )
      "
      rows="4"
      :autoGrow="true"
      class="my-3"
    />

    <LscButton
      variant="secondary"
      :disabled="Boolean(promise) || reachedTasklistGenerationLimit"
      @click="generateTasks"
      class="mb-4 mt-2"
    >
      {{ t('Generate tasks') }}
    </LscButton>

    <LscAlert
      v-if="reachedTasklistGenerationLimit"
      layout="alert"
      :title="t('You have reached the limit for generating tasks')"
      :message="t('Visit your project to make any further changes in the tasks')"
      icon="lsi-alert"
      variant="warning-subdued"
    />
    <LscAlert
      v-else-if="Boolean(promise) && (briefDescriptionError || projectTypeError)"
      layout="alert"
      :message="t('We noticed some info was missing, so we’ve used your account details to generate your tasks.')"
      icon="lsi-alert"
      variant="warning-subdued"
    />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :preselectedTab="PREVIEW_TAB_TABLE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :tabsClickable="true"
        :stepId="STEP_ADD_AI_GENERATED_TASKS"
        :tasksDataFromTemplate="hasGeneratedTasks"
        :tasksData="previewData"
        :isPreviewDataLoading="Boolean(promise)"
      />
      <div
        v-if="hasGeneratedTasks && !thumbsUpOrDownClicked && !Boolean(promise)"
        class="-mb-14 mt-4 flex justify-center"
      >
        <p class="mx-2 mt-2">
          {{ t('How would you rate the generated task lists?') }}
        </p>
        <LscIconButton
          :ariaLabel="t('Thumbs up')"
          v-LsdTooltip="t('Good')"
          icon="lsi-thumbs-up"
          @click.stop="thumbsUpClicked"
        />
        <LscIconButton
          :ariaLabel="t('Thumbs down')"
          v-LsdTooltip="t('Bad')"
          icon="lsi-thumbs-up"
          class="rotate-180"
          @click.stop="thumbsDownClicked"
        />
      </div>
    </template>
  </OnboardingWizardCommonStep>
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :isNextStepButtonDisabled="Boolean(promise) || !generatedAtLeastOnce"
    :nextStep="nextStep"
    :skipButtonText="t('No thanks, I\'ll create my own tasks')"
    :showSkipButton="true"
    :skipStep="skipStep"
  />
</template>
