import { useProjectFeatureOrderLoader, useExperimentA63, useFeatures, useCohort } from '@/api';
import { useI18n } from '@/util';
import { useCurrentProject } from './useCurrentProject';
import { useCurrentProjectPermissions } from './useCurrentProjectPermissions';
import { useRoute } from './useRoute';
import { useLegacySwitches } from '@/module/legacy';
import { useTicketPermissions } from '@/module/desk';

const symbol = Symbol('useCurrentProjectNavigation');

function CurrentProjectNavigationService() {
  const { t } = useI18n();
  const route = useRoute();
  const project = useCurrentProject();
  const { isDeskEnabled } = useTicketPermissions();
  const { isInFreeTrial, isCompanyRoleManagerGrp } = useCohort();
  const { tableView: tableViewSwitch, timeProject: timeProjectSwitch } = useLegacySwitches();
  const { lightspeedTableViewEnabled, lightspeedTableViewToggleEnabled, lightspeedTimePagesEnabled } = useFeatures();

  const { isExpA63Variation } = useExperimentA63();
  const projectOverviewPage = computed(() =>
    ['summary', 'default'].includes(project.value?.overviewStartPage) ? 'summary' : 'activity',
  );
  const { item: featureOrder } = useProjectFeatureOrderLoader({
    projectId: computed(() => project.value?.id),
  });

  const {
    isSiteOrProjectAdmin,
    canViewProjectList,
    canViewProjectTable,
    canViewProjectBoards,
    canViewProjectMessages,
    canViewProjectFiles,
    canViewProjectNotebooks,
    canViewProjectTime,
    canViewProjectTickets,
    canViewProjectDashboard,
    canViewProjectMilestones,
    canViewProjectComments,
    canViewProjectRisks,
    canViewProjectLinks,
    canViewProjectFinance,
    canViewProjectGantt,
    canViewProjectPeople,
    canViewProjectForms,
    canViewProjectSettings,
    canViewProjectProofs,
  } = useCurrentProjectPermissions();

  const isPMVariationEnabled = computed(() => {
    const customisedProjectNavBar = featureOrder.value?.id !== 0;
    return isCompanyRoleManagerGrp.value && !customisedProjectNavBar;
  });

  const listPath = computed(() =>
    route.params.tasklistId && route.meta.view === 'table'
      ? `/tasklists/${route.params.tasklistId}/list`
      : `/projects/${project.value.id}/tasks/list`,
  );

  const tablePath = computed(() =>
    route.params.tasklistId && route.meta.view === 'list'
      ? `/tasklists/${route.params.tasklistId}/table`
      : `/projects/${project.value.id}/tasks/table`,
  );

  const navItems = computed(() => [
    {
      name: t('List'),
      path: listPath.value,
      alias: /(\/tasklists\/\d+\/list|\/tasks\/\d+)/,
      permission: canViewProjectList,
      id: 'list',
      pendoLabel: 'list',
      dataIdentifier: 'project-tab-task-list',
      icon: 'lsi-bulleted-list',
      featureOrderParam: 'list',
    },
    {
      name: t('Table'),
      path: tablePath.value,
      alias: /\/tasklists\/\d+\/table/,
      permission: canViewProjectTable,
      id: 'table',
      pendoLabel: 'table',
      dataIdentifier: 'project-tab-task-table',
      icon: 'lsi-table',
      featureOrderParam: 'table',
      showBetaLabel:
        ((lightspeedTableViewToggleEnabled.value && tableViewSwitch.value) || lightspeedTableViewEnabled.value) &&
        !isInFreeTrial.value,
    },
    {
      name: t('Board'),
      path: `/projects/${project.value.id}/tasks/board`,
      permission: canViewProjectBoards,
      id: 'board',
      pendoLabel: 'board',
      dataIdentifier: 'project-tab-task-board',
      icon: 'lsi-board-column',
      featureOrderParam: 'board',
    },
    {
      name: t('Messages'),
      path: `/projects/${project.value.id}/messages`,
      alias: /\/messages\/\d+/,
      permission: canViewProjectMessages,
      id: 'messages',
      pendoLabel: 'messages',
      dataIdentifier: 'project-tab-messages',
      icon: 'lsi-comment',
      featureOrderParam: 'messages',
    },
    {
      name: t('Files'),
      path: `/projects/${project.value.id}/files`,
      alias: /\/files\/\d+/,
      permission: canViewProjectFiles,
      id: 'files',
      pendoLabel: 'files',
      dataIdentifier: 'project-tab-files',
      icon: 'lsi-files',
      featureOrderParam: 'files',
    },
    {
      name: t('Notebooks'),
      path: `/projects/${project.value.id}/notebooks`,
      alias: /\/notebooks\/\d+/,
      permission: canViewProjectNotebooks,
      id: 'notebooks',
      pendoLabel: 'notebooks',
      dataIdentifier: 'project-tab-notebooks',
      icon: 'lsi-notebook',
      featureOrderParam: 'notebooks',
    },
    {
      name: t('Time'),
      path: `/projects/${project.value.id}/time`,
      permission: canViewProjectTime,
      id: 'time',
      pendoLabel: 'time',
      dataIdentifier: 'project-tab-time',
      icon: 'lsi-time',
      featureOrderParam: 'time',
      showBetaLabel: lightspeedTimePagesEnabled.value && timeProjectSwitch.value,
    },
    {
      name: t('Tickets'),
      path: `/projects/${project.value.id}/tickets`,
      permission: canViewProjectTickets,
      id: 'tickets',
      pendoLabel: 'tickets',
      dataIdentifier: 'project-tab-tickets',
      icon: 'lsi-email',
      featureOrderParam: 'tickets',
      showNewLabel: !isDeskEnabled.value,
    },
    {
      name: t('Dashboard'),
      path: `/projects/${project.value.id}/overview/${projectOverviewPage.value}`,
      permission: canViewProjectDashboard,
      alias: /\/projects\/\d+\/overview\/*/,
      id: 'dashboard',
      pendoLabel: 'dashboard',
      dataIdentifier: 'project-tab-overview',
      icon: 'lsi-dashboard',
      featureOrderParam: 'dashboard',
    },
    {
      name: t('Milestones'),
      path: `/projects/${project.value.id}/milestones`,
      alias: /\/milestones\/\d+/,
      permission: canViewProjectMilestones,
      id: 'milestones',
      pendoLabel: 'milestones',
      dataIdentifier: 'project-tab-milestones',
      icon: 'lsi-milestone',
      featureOrderParam: 'milestones',
    },
    {
      name: t('Comments'),
      path: `/projects/${project.value.id}/comments`,
      alias: /\/comments\/\d+/,
      permission: canViewProjectComments,
      id: 'comments',
      pendoLabel: 'comments',
      dataIdentifier: 'project-tab-comments',
      icon: 'lsi-comment',
      featureOrderParam: 'comments',
    },
    {
      name: t('Risks'),
      path: `/projects/${project.value.id}/risks`,
      permission: canViewProjectRisks,
      id: 'risks',
      pendoLabel: 'risks',
      dataIdentifier: 'project-tab-risks',
      icon: 'lsi-alert',
      featureOrderParam: 'risks',
    },
    {
      name: t('Links'),
      path: `/projects/${project.value.id}/links`,
      alias: /\/links\/\d+/,
      permission: canViewProjectLinks,
      id: 'links',
      pendoLabel: 'links',
      dataIdentifier: 'project-tab-links',
      icon: 'lsi-link',
      featureOrderParam: 'links',
    },
    {
      name: isExpA63Variation.value ? t('Budgets') : t('Finance'),
      path: `/projects/${project.value.id}/finance`,
      permission: canViewProjectFinance,
      id: 'finance',
      pendoLabel: 'finance',
      dataIdentifier: 'project-tab-finance',
      showDot: true,
      icon: 'lsi-billable',
      featureOrderParam: 'finance',
    },
    {
      name: t('Gantt'),
      path: `/projects/${project.value.id}/tasks/gantt`,
      permission: canViewProjectGantt,
      id: 'gantt',
      pendoLabel: 'gantt',
      dataIdentifier: 'project-tab-gantt',
      icon: 'lsi-gantt',
      featureOrderParam: 'gantt',
    },
    {
      name: t('People'),
      path: `/projects/${project.value.id}/people`,
      permission: canViewProjectPeople,
      id: 'people',
      pendoLabel: 'people',
      dataIdentifier: 'project-tab-people',
      icon: 'lsi-assignees',
      featureOrderParam: 'people',
    },
    {
      name: t('Forms'),
      path: `/projects/${project.value.id}/forms`,
      alias: /\/forms\/\d+/,
      permission: canViewProjectForms,
      id: 'forms',
      pendoLabel: 'forms',
      dataIdentifier: 'project-tab-forms',
      icon: 'lsi-forms',
      featureOrderParam: 'forms',
    },
    {
      name: t('Settings'),
      path: `/projects/${project.value.id}/settings`,
      permission: canViewProjectSettings,
      id: 'settings',
      pendoLabel: 'settings',
      dataIdentifier: 'project-tab-settings',
      featureOrderParam: 'settings',
      icon: 'lsi-settings',
    },
    {
      name: t('Proofs'),
      path: `/projects/${project.value.id}/proofs`,
      permission: canViewProjectProofs,
      id: 'proofs',
      pendoLabel: 'proofs',
      dataIdentifier: 'project-tab-proofs',
      featureOrderParam: 'proofs',
      icon: 'lsi-proofs',
    },
  ]);

  const PM_FEATURE_ORDER = {
    list: 0,
    board: 1,
    table: 2,
    dashboard: 3,
    files: 4,
    time: 5,
    forms: 6,
    tickets: 7,
    finance: 8,
    milestones: 9,
    messages: 10,
    gantt: 11,
    comments: 12,
    notebooks: 13,
    links: 14,
    people: 15,
    risks: 16,
    billing: 17,
    settings: 18,
  };

  function sortPageTabs(a, b) {
    const tab = a.featureOrderParam;
    const lastTab = b.featureOrderParam;

    if (isPMVariationEnabled.value) {
      return PM_FEATURE_ORDER[tab] - PM_FEATURE_ORDER[lastTab];
    }
    return featureOrder.value[tab] - featureOrder.value[lastTab];
  }

  const navigationFeatureMap = {
    risks: 'riskRegister',
  };

  function isDisabledTab(item) {
    return Boolean(
      !(project.value?.activePages[navigationFeatureMap[item.featureOrderParam] || item.featureOrderParam] !== false),
    );
  }

  const enabledNavItems = computed(
    () =>
      (featureOrder.value && navItems.value.filter(({ permission }) => Boolean(permission.value)).sort(sortPageTabs)) ||
      [],
  );

  const disabledNavItems = computed(
    () =>
      (featureOrder.value && isSiteOrProjectAdmin.value && navItems.value.filter(isDisabledTab).sort(sortPageTabs)) ||
      [],
  );

  const maxVisibleItems = computed(() => {
    if (isPMVariationEnabled.value) {
      return 8;
    }
    return featureOrder.value?.numVisibleTabs ?? 7;
  });

  return {
    featureOrder,
    enabledNavItems,
    disabledNavItems,
    maxVisibleItems,
  };
}

export function provideCurrentProjectNavigation() {
  provide(symbol, CurrentProjectNavigationService());
}

/**
 * Provides project-level navigation information.
 * @type {CurrentProjectNavigationService}
 */
export function useCurrentProjectNavigation() {
  return inject(symbol);
}
