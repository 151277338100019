<script setup>
import { useI18n } from '@/util';
import SpacesDrawerSpacesBlankSlate from './SpacesDrawerSpacesBlankSlate.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState
    size="sm"
    class="my-8"
    :title="t('Get started with {productName}', { productName: 'Spaces' })"
    :message="
      t('{productName} are the best place to organize and access your team and company content.', {
        productName: 'Spaces',
      })
    "
  >
    <template #image>
      <SpacesDrawerSpacesBlankSlate class="block h-auto w-20" />
    </template>
    <template #action>
      <LscLink to="/spaces/home/all" variant="standalone" prependIcon="lsi-arrow-right">
        {{ t('Explore {productName}', { productName: 'Spaces' }) }}
      </LscLink>
    </template>
  </LscEmptyState>
</template>
