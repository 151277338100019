<script setup>
import { useElementSize } from '@vueuse/core';
import LscFileItemOverlayButton from './LscFileItemOverlayButton.vue';
import { escapeMarkdown, getFileIcon, isImage, isPreviewableFile, useI18n } from '@/util';
import LscChip from '../chip/LscChip.vue';
import { LscFileItemVariants } from './constants';

const props = defineProps({
  /**
   * Whether the user can download files or not. Ususally retrieved from the `project permissions`.
   */
  canDownloadFiles: {
    type: Boolean,
    required: true,
  },
  /**
   * Whether the user can preview files or not. Ususally retrieved from the `project permissions`.
   */
  canPreviewFiles: {
    type: Boolean,
    required: true,
  },
  /**
   * The `file` object. Refer to `fileUtils.js` for more details on how we normalize the `file` object.
   */
  file: {
    type: Object,
    required: true,
  },
  /**
   * The variants of the file item.
   * @type {PropType<typeof LscFileItemVariants[number]>}
   */
  variant: {
    type: String,
    default: 'default',
    validator(prop) {
      return LscFileItemVariants.includes(prop);
    },
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['preview', 'download', 'click']);

const { formatDateTime, t } = useI18n();

const GoogleDriveIcon = defineAsyncComponent(() => import('../../../assets/icons/GoogleDriveIcon.svg'));
const DropboxIcon = defineAsyncComponent(() => import('../../../assets/icons/DropboxIcon.svg'));
const OneDriveIcon = defineAsyncComponent(() => import('../../../assets/icons/OneDriveIcon.svg'));
const SharePointIcon = defineAsyncComponent(() => import('../../../assets/icons/SharePointIcon.svg'));
const BoxIcon = defineAsyncComponent(() => import('../../../assets/icons/BoxIcon.svg'));

const fileItemRef = shallowRef();
const { width } = useElementSize(fileItemRef);

const fileSourcesMap = {
  googledrive: {
    icon: GoogleDriveIcon,
    text: 'Google Drive',
  },
  onedrive: {
    icon: OneDriveIcon,
    text: 'OneDrive',
  },
  onedrivebusiness: {
    icon: OneDriveIcon,
    text: 'OneDrive Business',
  },
  sharepoint: {
    icon: SharePointIcon,
    text: 'Sharepoint',
  },
  dropbox: {
    icon: DropboxIcon,
    text: 'Dropbox',
  },
  'box.com': {
    icon: BoxIcon,
    text: 'Box.com',
  },
};

const fileName = computed(() => props.file.name || props.file.fileName || props.file.displayName);
const fileIcon = computed(() => getFileIcon(fileName.value));

const fileSource = computed(() => fileSourcesMap[props.file.fileSource]);

const isImageAvailable = computed(() => props.file.src && isImage(fileName.value) && props.file.mode === 'server');

const isFilePreviewable = computed(
  () => props.canPreviewFiles && props.file.mode === 'server' && isPreviewableFile(props.file, props.canPreviewFiles),
);

const isFileDownloadable = computed(() => {
  return props.canDownloadFiles && props.file.mode === 'server';
});

function previewFile() {
  emit('preview', props.file);
}

function downloadFile() {
  emit('download', props.file);
}

function onContentClick(event) {
  if (isFilePreviewable.value || isFileDownloadable.value) {
    event.stopPropagation();
  }
  if (isFilePreviewable.value) {
    previewFile();
  } else if (isFileDownloadable.value) {
    downloadFile();
  }
}

function getTooltipText(file) {
  let text = `**${escapeMarkdown(fileName.value)}**\n`;

  if (file.uploadDate?.isValid) {
    text += `${escapeMarkdown(
      t('Uploaded at {dateTime}', {
        dateTime: formatDateTime(file.uploadDate),
      }),
    )}`;

    if (file.size !== '0KB') {
      text += ' - ';
    }
  }

  if (file.size !== '0KB') {
    text += file.size;
  }
  return text;
}

const LscFileItemStyleVariantConfig = tv({
  base: [
    'relative flex w-full cursor-pointer overflow-hidden rounded-md outline-none transition-colors',
    'border transition-colors hover:border-form-hover focus-visible:border-form-hover',
  ],
  slots: {
    image: 'relative flex aspect-square items-center justify-center bg-surface-default',
    content: 'flex w-full flex-col justify-center gap-1 overflow-hidden py-3 pl-3 pr-2',
    progressBar: 'absolute top-0 w-full',
  },
  variants: {
    variant: {
      thumbnail: {
        base: 'flex-col',
        image: 'h-32 w-full',
      },
      compact: {
        image: 'size-14 shrink-0',
      },
      default: {
        image: 'size-18 shrink-0',
      },
    },
  },
});

const classes = computed(() => LscFileItemStyleVariantConfig(props));
</script>

<template>
  <button
    ref="fileItemRef"
    type="button"
    class="group/file"
    :class="classes.base()"
    :data-identifier="`${dataIdentifierPrefix}-file-item`"
    @click="emit('click', file)"
  >
    <LscProgressBar
      v-if="file.isUploading && file.uploadProgress"
      size="sm"
      :percentage="file.uploadProgress"
      :class="classes.progressBar()"
    />

    <div :class="classes.image()">
      <img v-if="isImageAvailable" :src="file.thumbURL" :alt="fileName" class="h-full w-full object-cover" />
      <LscIcon v-else-if="!file.isUploading" :icon="fileIcon.icon" :size="36" :color="fileIcon.color" />

      <div
        v-if="isFilePreviewable || isFileDownloadable"
        class="opacity-0 transition-opacity group-hover/file:opacity-100"
      >
        <div class="absolute inset-0 bg-surface-dark opacity-20" />
        <div class="absolute inset-0 flex items-center justify-center gap-2">
          <LscFileItemOverlayButton
            v-if="isFilePreviewable"
            icon="lsi-file-preview"
            :text="t('Preview')"
            :data-identifier="`${dataIdentifierPrefix}-file-item-preview`"
            @click.prevent="previewFile"
          />
          <LscFileItemOverlayButton
            v-if="isFileDownloadable"
            icon="lsi-download"
            :text="t('Download')"
            :data-identifier="`${dataIdentifierPrefix}-file-item-download`"
            @click.prevent="downloadFile"
          />
        </div>
      </div>
    </div>

    <!--TODO: Refactor to remove the nested interactive elements-->
    <div :class="classes.content()" role="button" @click="onContentClick">
      <div class="flex justify-between gap-1 text-body-1">
        <div v-LsdTooltip.markdown="getTooltipText(file)" class="flex min-w-0 items-center gap-1">
          <LscChip v-if="file?.latestFileVersionNo > 1" class="shrink-0" size="xs">
            V{{ file.latestFileVersionNo }}
          </LscChip>
          <span class="truncate">{{ fileName }}</span>
        </div>

        <div class="flex items-center gap-2">
          <template v-if="variant === 'thumbnail' || variant === 'compact'">
            <slot name="menu" :file="file" />
            <slot name="actions" :file="file" />
          </template>
          <template v-if="variant === 'default'">
            <slot name="menu" :file="file" />
          </template>
        </div>
      </div>

      <div v-if="variant === 'default'" class="flex justify-between gap-2">
        <div class="flex items-center gap-2 text-body-2 text-subtle">
          <template v-if="fileSource">
            <component :is="fileSource.icon" />
            <span v-if="width >= 350">{{ fileSource.text }}</span>
            <span v-if="file.sizeRaw > 0">·</span>
          </template>
          <span v-if="file.sizeRaw > 0">{{ file.size }}</span>
        </div>

        <div class="flex items-center gap-2 pr-1">
          <slot name="actions" :file="file" />
        </div>
      </div>
    </div>
  </button>
</template>
