<script setup>
import { provideInbox } from '../inbox/useInbox';
import { providePendo } from '../features/usePendo';
import { provideHotjar } from '../features/useHotjar';
import { provideNotifications } from '../notifications/useNotifications';

provideInbox();
providePendo();
provideHotjar();
provideNotifications();
</script>

<template>
  <slot />
</template>
