<script setup>
defineProps({
  invoiceId: {
    type: Number,
    default: null,
  },
  projectId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['createInvoice']);

const modelValue = defineModel({ type: Boolean, default: false });

const FinanceInvoiceAddOrEditDialogPanel = defineAsyncComponent(
  () => import('./FinanceInvoiceAddOrEditDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceInvoiceAddOrEditDialogPanel
        :closing="!modelValue"
        :projectId="projectId"
        :invoiceId="invoiceId"
        @close="close"
        @createInvoice="emit('createInvoice', $event)"
      />
    </template>
  </WidgetDialog>
</template>
