<script setup>
import { useI18n } from '@/util';
import ProjectJumpToDrawerEmptyProjectsStarred from './ProjectJumpToDrawerEmptyProjectsStarred.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState
    class="my-8"
    size="sm"
    :title="t('Starred projects')"
    :message="t('Star any project so that you can easily access it later from this space')"
  >
    <template #image>
      <ProjectJumpToDrawerEmptyProjectsStarred class="w-[80px]" />
    </template>
  </LscEmptyState>
</template>
