export const customfieldEntities = /** @type {const} */ ({
  CLIENT: 'company', // @TODO change it to "client" once the backend changes are done
  PROJECT: 'project',
  TASK: 'task',
  CR_TASK: 'cr-task',
  CR_PROJECT: 'cr-project',
});

export const customfieldTypes = {
  CHECKBOX: 'checkbox',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  NUMBER_INTEGER: 'number-integer',
  NUMBER_DECIMAL: 'number-decimal',
  STATUS: 'status',
  TEXT: 'text-short',
  URL: 'url',
  FORMULA: 'formula',
};

export const customfieldTypeIcons = {
  [customfieldTypes.CHECKBOX]: 'lsi-customfield-checkbox',
  [customfieldTypes.DATE]: 'lsi-customfield-date',
  [customfieldTypes.DROPDOWN]: 'lsi-customfield-dropdown',
  [customfieldTypes.NUMBER_INTEGER]: 'lsi-customfield-number',
  [customfieldTypes.NUMBER_DECIMAL]: 'lsi-customfield-number',
  [customfieldTypes.STATUS]: 'lsi-customfield-status',
  [customfieldTypes.TEXT]: 'lsi-customfield-text',
  [customfieldTypes.URL]: 'lsi-customfield-url',
  [customfieldTypes.FORMULA]: 'lsi-customfield-formula',
};
