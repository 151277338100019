import { useCurrentUser, useCheckoutV1Loader } from '@/api';

const defaults = {
  isNewInvoiceActive: shallowRef(undefined),
  latestInvoiceHostedPage: shallowRef(null),
};

export function useInvoice() {
  const user = useCurrentUser();

  if (!user.value.inOwnerCompany || !user.value.administrator) {
    return defaults;
  }

  const { item: checkoutData } = useCheckoutV1Loader();

  const latestInvoiceHostedPage = computed(() => checkoutData.value?.latestInvoiceHostedPage);

  const isNewInvoiceActive = computed(() => {
    const projects = checkoutData.value?.installation.apps.projects;

    return (
      projects &&
      projects.paymentMethod === 'stripe' &&
      projects.paymentCollectionMethod === 'send_invoice' &&
      projects.inGracePeriod
    );
  });

  return { isNewInvoiceActive, latestInvoiceHostedPage };
}
