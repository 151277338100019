<script setup>
import { useI18n } from '@/util';
import NotificationDrawerEmptyStateUnread from './assets/NotificationDrawerEmptyStateUnread.svg';

const { t } = useI18n();
</script>

<template>
  <LscEmptyState size="sm" class="my-8" :title="t('Woohoo!')" :message="t('You\'re all up to date!')">
    <template #image>
      <NotificationDrawerEmptyStateUnread class="size-24" />
    </template>
  </LscEmptyState>
</template>
