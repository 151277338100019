<script setup>
import { computedEager } from '@vueuse/core';
import { usePendo, usePermissions, useCohort, useFeatures, usePricePlan, useExperimentA2413c } from '@/api';
import { ClientAddDialog } from '@/module/client';
import { useLegacyBridge } from '@/module/legacy';
import { ProjectAddPeopleDialog } from '@/module/project';
import { TaskAddDialog } from '@/module/task';
import { TimeTimelogAddOrEditDialog, TimeTimerAddOrEditDialog, useTimeTimerTracking } from '@/module/time';
import { BudgetAddOrEditDialog } from '@/module/budget';
import { UserAddYourTeamDialog, UserInviteDialog } from '@/module/user';
import { useCurrentProject } from '@/route';
import { UseKeyboardShortcut, useI18n, formatKeyboardShortcut } from '@/util';
import AppShellSidebarQuickAddMenuSplitButton from './AppShellSidebarQuickAddMenuSplitButton.vue';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useAppShellSidebarTracking } from './useAppShellSidebarTracking';
import { useQuickAddPermissions } from './useQuickAddPermissions';

defineOptions({
  inheritAttrs: false,
});

const { t } = useI18n();
const project = useCurrentProject();
const { projectsNewBudgetAddOrEdit } = useFeatures();

const {
  canInviteUser,
  addMessage,
  addOrEditTimer,
  addOrEditEvent,
  addOrEditProjectUpdate,
  quickAddTask,
  addOrEditProjectWizard,
  addOrEditMilestone,
} = useQuickAddPermissions();
const { isCompanyRoleManagerGrp } = useCohort();
const { showLegacyModal } = useLegacyBridge();
const { trackPendoEvent } = usePendo();
const { canAccessPeopleCompaniesTeams, canAddCustomReports, isOwnerAdmin } = usePermissions();
const { trackTimerStartClicked } = useTimeTimerTracking();
const { isQuickAddOpen, isSidebarExpanded, isSidebarOpenOnMobile, clearActiveDrawerPanelIfNotPinned } =
  useAppShellSidebar();
const { trackLeftNavigationClicked, trackShortcutKeyPressed, trackQuickAddMenuItemClicked } =
  useAppShellSidebarTracking({ isSidebarExpanded });
const { canTrialInviteUsers } = usePricePlan();
const { isExpA2413cVariation, shouldShowInviteTeamSplitButton, trackTrialInviteUsersViaSidenavCta } =
  useExperimentA2413c();

const buttonActivatorRef = shallowRef();
const dialogName = shallowRef('');
const isDialogOpen = shallowRef(false);

const quickAddItems = [
  {
    text: t('Task'),
    icon: 'lsi-task',
    shortcutKey: 'T',
    dialogName: 'TaskAddDialog',
    permission: quickAddTask,
    pendoLabel: 'task',
    dataIdentifier: 'side-nav-quick-add-task',
  },
  {
    text: t('Start timer'),
    icon: 'lsi-start-timer',
    shortcutKey: 'S',
    dialogName: 'TimeTimerAddOrEditDialog',
    permission: addOrEditTimer,
    pendoLabel: 'start_timer',
    dataIdentifier: 'side-nav-quick-add-timer',
  },
  {
    text: t('Log time'),
    icon: 'lsi-time',
    shortcutKey: 'L',
    dialogName: 'TimeTimelogAddOrEditDialog',
    permission: addOrEditTimer,
    pendoLabel: 'log_time',
    dataIdentifier: 'side-nav-quick-add-log-time',
  },
  {
    text: t('Project'),
    icon: 'lsi-project',
    shortcutKey: 'P',
    legacyModal: 'addOrEditProjectWizard',
    permission: addOrEditProjectWizard,
    pendoLabel: 'project',
    dataIdentifier: 'side-nav-quick-add-project',
  },
  {
    text: t('Budget'),
    icon: 'lsi-budget',
    shortcutKey: 'B',
    legacyModal: 'ProjectBudgetModal',
    permission: isOwnerAdmin,
    pendoLabel: 'budget',
    dataIdentifier: 'side-nav-quick-add-budgets',
    modalProps: shallowRef({ openSource: 'quick_add_menu' }),
  },
  {
    text: t('Budget 2'),
    icon: 'lsi-budget',
    dialogName: 'BudgetAddOrEditDialog',
    permission: computed(() => projectsNewBudgetAddOrEdit.value),
    pendoLabel: 'budget',
    dataIdentifier: 'side-nav-quick-add-budgets-2',
  },
  {
    text: t('Invite'),
    icon: 'lsi-assignee',
    dialogName: computed(() => (project.value ? 'ProjectAddPeopleDialog' : 'UserInviteDialog')),
    permission: canInviteUser,
    pendoLabel: 'invite',
    dataIdentifier: 'side-nav-quick-add-people',
    hideFromMenu: shouldShowInviteTeamSplitButton,
  },
  {
    text: t('Message'),
    icon: 'lsi-message',
    shortcutKey: 'N',
    legacyModal: 'addMessage',
    permission: addMessage,
    pendoLabel: 'message',
    dataIdentifier: 'side-nav-quick-add-message',
  },
  {
    text: t('Event'),
    icon: 'lsi-event',
    shortcutKey: 'E',
    legacyModal: 'addOrEditEvent',
    permission: addOrEditEvent,
    pendoLabel: 'event',
    dataIdentifier: 'side-nav-quick-add-event',
  },
  {
    text: t('Milestone'),
    icon: 'lsi-milestone',
    shortcutKey: 'M',
    legacyModal: 'addOrEditMilestone',
    permission: addOrEditMilestone,
    pendoLabel: 'milestone',
    dataIdentifier: 'side-nav-quick-add-milestone',
  },
  {
    text: t('Update'),
    icon: 'lsi-announcement',
    legacyModal: 'addOrEditProjectUpdate',
    permission: addOrEditProjectUpdate,
    pendoLabel: 'update',
    dataIdentifier: 'side-nav-quick-add-project-update',
    modalProps: computed(() => {
      return { projectId: project.value?.id };
    }),
  },
  {
    text: t('Company'),
    icon: 'lsi-company',
    dialogName: 'ClientAddEditDialog',
    permission: computed(() => isCompanyRoleManagerGrp.value && canAccessPeopleCompaniesTeams.value),
    pendoLabel: 'company',
    dataIdentifier: 'side-nav-quick-add-company',
  },
  {
    text: t('User rate'),
    icon: 'lsi-billable',
    legacyModal: 'BudgetUserRatesModal',
    permission: computed(() => isCompanyRoleManagerGrp.value && isOwnerAdmin.value),
    pendoLabel: 'user_rate',
    dataIdentifier: 'side-nav-quick-add-user-rate',
  },
  {
    text: t('Report'),
    icon: 'lsi-reports',
    legacyModal: 'ReportBuilder',
    permission: computed(() => isCompanyRoleManagerGrp.value && canAddCustomReports.value),
    pendoLabel: 'report',
    dataIdentifier: 'side-nav-quick-add-report',
  },
  // #region Experiment A-24-13c - Grouped & personalised side nav layout
  {
    text: t('Invite team'),
    icon: 'lsi-add-user',
    dialogName: 'UserAddYourTeamDialog',
    permission: canTrialInviteUsers,
    pendoLabel: 'invite_team',
    dataIdentifier: 'side-nav-quick-invite-team',
    hideFromMenu: computed(() => true), // Permamently hidden, only accessible as primary action of split button. Added here for simplicity.
  },
  // #endregion
];
const shortcuts = quickAddItems.map(({ shortcutKey }) => shortcutKey).filter(Boolean);

const label = computed(() => (isExpA2413cVariation.value ? t('Create') : t('Quick add')));
const isSplitButtonVariant = computed(
  () => shouldShowInviteTeamSplitButton.value || (isCompanyRoleManagerGrp.value && !isExpA2413cVariation.value),
);

const allowedQuickAddItems = computedEager(() =>
  quickAddItems.filter((item) => item.permission.value && !item?.hideFromMenu?.value),
);

const menuItems = computedEager(() => {
  return allowedQuickAddItems.value;
});

function handleSplitButtonLeftClicked(quickAddItem, navigationItemSuffix) {
  if (!quickAddItem) {
    return;
  }

  handleQuickAddMenuItemClicked(quickAddItem, true);
  trackLeftNavigationClicked(`quick_add_split_button_${navigationItemSuffix}`);
}

function handleQuickAddMenuItemClicked(item, fromSplitButton = false) {
  document.activeElement?.blur();
  isQuickAddOpen.value = false;
  isSidebarOpenOnMobile.value = false;
  clearActiveDrawerPanelIfNotPinned();

  if (!fromSplitButton) {
    trackQuickAddMenuItemClicked(item.pendoLabel);
  }

  if (item.pendoLabel === 'start_timer') {
    trackTimerStartClicked('quick_add_menu');
  }

  if (item.dialogName) {
    dialogName.value = unref(item.dialogName);
    isDialogOpen.value = true;
  } else if (item.legacyModal) {
    if (item.legacyModal === 'addOrEditProjectWizard') {
      const source = 'quick_add_sidebar_menu';
      trackPendoEvent({
        eventName: 'PROJECTS_EVENT',
        commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
        metadata: {
          page_type: 'projects',
          event_action: 'add_project_clicked',
          event_category: 'advanced_discovery_event',
          source,
        },
      });

      showLegacyModal({ modalName: item.legacyModal, params: { ...item.modalProps, source } });
    } else {
      showLegacyModal({ modalName: item.legacyModal, params: item.modalProps });
    }
  }
}

function handleQuickAddShortcutClicked(key) {
  /**
   * Do not execute shortcuts with closed menu.
   */
  if (!isQuickAddOpen.value) {
    return false;
  }

  const item = allowedQuickAddItems.value.find(({ shortcutKey, permission }) => permission && shortcutKey === key);

  if (item) {
    handleQuickAddMenuItemClicked(item);
    // stop propagation and prevent default to prevent other shortcuts from executing
    return true;
  }

  return false;
}

function handleQShortcutClicked() {
  if (isSplitButtonVariant.value) {
    buttonActivatorRef.value.performRightButtonClick?.();
  } else {
    buttonActivatorRef.value.click?.();
    buttonActivatorRef.value.focus?.();
  }

  trackShortcutKeyPressed(`quick_add${isSplitButtonVariant.value ? '_split_button_menu' : ''}`);
}

watch(isQuickAddOpen, (isOpen) => {
  if (!isOpen) {
    return;
  }

  clearActiveDrawerPanelIfNotPinned();
  trackLeftNavigationClicked(`quick_add${isSplitButtonVariant.value ? '_split_button_menu' : ''}`);
});
</script>

<template>
  <WidgetOptionsMenu v-model="isQuickAddOpen" offset="12" location="end" aria-labelledby="side-nav-quick-add-button">
    <template #activator="activator">
      <AppShellSidebarQuickAddMenuSplitButton
        v-if="shouldShowInviteTeamSplitButton"
        v-bind="activator.props"
        id="side-nav-quick-add-button"
        ref="buttonActivatorRef"
        icon="lsi-profile-account"
        :primaryLabel="t('Invite team')"
        :secondaryLabel="label"
        @leftButtonClick="
          handleSplitButtonLeftClicked(
            quickAddItems.find((item) => item.dataIdentifier === 'side-nav-quick-invite-team'),
            'invite_team',
          )
        "
      />
      <AppShellSidebarQuickAddMenuSplitButton
        v-else-if="isCompanyRoleManagerGrp && !isExpA2413cVariation"
        v-bind="activator.props"
        id="side-nav-quick-add-button"
        ref="buttonActivatorRef"
        icon="lsi-task"
        :primaryLabel="t('Add task')"
        :secondaryLabel="label"
        @leftButtonClick="
          handleSplitButtonLeftClicked(
            quickAddItems.find((item) => item.dataIdentifier === 'side-nav-quick-add-task'),
            'add_task',
          )
        "
      />
      <button
        v-else
        v-bind="activator.props"
        id="side-nav-quick-add-button"
        ref="buttonActivatorRef"
        v-LsdTooltip:right="
          isSidebarExpanded || isQuickAddOpen
            ? undefined
            : {
                text: label.value,
                subtext: formatKeyboardShortcut('Q'),
              }
        "
        type="button"
        data-identifier="side-nav-quick-add-menu"
        :class="[
          'flex h-9 w-full min-w-9 items-center gap-1 rounded-full',
          'outline-none ring-0 ring-[color:--lsds-c-sidebar-drawer-list-item-background-color-on-focus]',
          'text-body-1 font-medium text-[--lsds-c-sidebar-drawer-quick-add-color-on-surface]',
          'bg-[--lsds-c-sidebar-drawer-quick-add-color-surface] transition-colors',
          'hover:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover]',
          'aria-expanded:ring-0',
          'focus-visible:z-10 focus-visible:bg-[--lsds-c-sidebar-drawer-quick-add-color-surface-hover] focus-visible:ring-2',
        ]"
      >
        <div class="flex size-9 shrink-0 items-center justify-center">
          <LscIcon icon="lsi-sidenav-quick-add" />
        </div>
        <LscOverflowEllipsis class="flex-1 text-left">{{ label }}</LscOverflowEllipsis>
        <div class="flex size-9 min-w-0 items-center justify-center">
          <LscIcon icon="lsi-open-panel" size="sm" />
        </div>
      </button>
    </template>
    <VListSubheader>{{ label }}</VListSubheader>
    <WidgetOptionsMenuItem
      v-for="item in menuItems"
      :key="item.code"
      :data-identifier="item.dataIdentifier"
      tabindex="0"
      link
      density="compact"
      class="focus-visible:group-focus-within:bg-selected"
      @click="handleQuickAddMenuItemClicked(item)"
    >
      <template #prepend>
        <LscIcon :icon="item.icon" />
      </template>
      <VListItemTitle>{{ item.text }}</VListItemTitle>

      <template #append>
        <VListItemAction v-if="item.shortcutKey">
          <span
            class="ml-auto flex size-5 content-center items-center justify-center rounded-sm border-2 border-subtle bg-default text-center text-body-3 uppercase text-subtle"
          >
            {{ item.shortcutKey }}
          </span>
        </VListItemAction>
      </template>
    </WidgetOptionsMenuItem>
    <UseKeyboardShortcut
      v-for="shortcut in shortcuts"
      :key="shortcut"
      :shortcut="shortcut"
      @activate="handleQuickAddShortcutClicked(shortcut)"
    />
  </WidgetOptionsMenu>
  <UseKeyboardShortcut shortcut="Q" @activate="handleQShortcutClicked" />
  <LscSlotSwitch :name="dialogName">
    <template #TaskAddDialog>
      <TaskAddDialog v-model="isDialogOpen" pendoSource="app_sidebar_quick_add" />
    </template>
    <template #TimeTimelogAddOrEditDialog>
      <TimeTimelogAddOrEditDialog v-model="isDialogOpen" openSource="quick_add_menu" />
    </template>
    <template #BudgetAddOrEditDialog>
      <BudgetAddOrEditDialog v-if="projectsNewBudgetAddOrEdit" v-model="isDialogOpen" />
    </template>
    <template #UserInviteDialog>
      <UserInviteDialog v-model="isDialogOpen" panelSource="quick_add_people" />
    </template>
    <template #ProjectAddPeopleDialog>
      <ProjectAddPeopleDialog v-model="isDialogOpen" :project="project" />
    </template>
    <template #TimeTimerAddOrEditDialog>
      <TimeTimerAddOrEditDialog v-model="isDialogOpen" />
    </template>
    <template #ClientAddEditDialog>
      <ClientAddDialog v-model="isDialogOpen" />
    </template>
    <template #UserAddYourTeamDialog>
      <UserAddYourTeamDialog
        v-model="isDialogOpen"
        panelSource="add_your_team"
        @usersInvited="trackTrialInviteUsersViaSidenavCta"
      />
    </template>
  </LscSlotSwitch>
</template>
