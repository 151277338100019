<script setup>
import { useDraggable } from '@vueuse/core';
import { useI18n } from '@/util';
import { useQuickView, DEFAULT_QUICK_VIEW_WIDTH } from './useQuickView';
import { useLegacyBridge } from '@/module/legacy';
import { useAppShellSidebar } from '@/appShell';

defineProps({
  resizable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

const DRAG_HANDLE_WIDTH = 40;
const SNAP_PADDING_WIDTH = 8;

const { t } = useI18n();
const {
  quickViewTrueWidth,
  quickViewBounds,
  isQuickViewExpanded,
  quickViewWidth,
  isQuickViewOpen,
  isQuickViewLocked,
  quickViewTransition,
} = useQuickView();
const { enableLegacyInteraction, disableLegacyInteraction } = useLegacyBridge();
const { clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();

const dragAndDropButton = shallowRef(null);
const { x: dragXPosition } = useDraggable(dragAndDropButton, {
  initialValue: { x: quickViewBounds.right.value - quickViewTrueWidth.value - DRAG_HANDLE_WIDTH },
  onStart: disableLegacyInteraction,
  onEnd: enableLegacyInteraction,
});

// Resize drawer
watch(dragXPosition, (pos) => {
  const minXpos = quickViewBounds.right.value - DEFAULT_QUICK_VIEW_WIDTH - DRAG_HANDLE_WIDTH;
  const maxXpos = quickViewBounds.left.value - SNAP_PADDING_WIDTH;

  if (pos >= minXpos) {
    quickViewTrueWidth.value = DEFAULT_QUICK_VIEW_WIDTH;
  }
  if (pos > maxXpos && pos < minXpos) {
    quickViewTrueWidth.value = quickViewBounds.right.value - pos - DRAG_HANDLE_WIDTH;
  }

  // toggle quick view lock when dragging over the max boundary
  isQuickViewLocked.value = Boolean(pos <= maxXpos);
});

const isDrawerOpen = computed({
  get: () => isQuickViewOpen.value,
  set: (value) => {
    if (!value) {
      emit('close');
    }
  },
});

watch(isDrawerOpen, (isOpen) => {
  if (isOpen) {
    clearActiveDrawerPanelIfNotPinned();
  }
});
</script>

<template>
  <LscDrawer
    v-model="isDrawerOpen"
    :closeOnBack="false"
    :persistent="resizable && isQuickViewExpanded"
    :retainFocus="false"
    :noClickAnimation="true"
    :transition="quickViewTransition"
    :class="{ '!z-[12]': resizable && isQuickViewLocked }"
  >
    <LscDrawerCard :size="`${resizable ? quickViewWidth : DEFAULT_QUICK_VIEW_WIDTH}px`">
      <template v-if="resizable" #expandButton>
        <div
          ref="dragAndDropButton"
          class="absolute z-[13] flex h-full items-center justify-end px-5 opacity-0 transition-opacity hover:opacity-100"
          :class="{ '-left-10': isDrawerOpen, '-left-5': isQuickViewExpanded }"
        >
          <button
            type="button"
            :aria-label="t('Drag to resize')"
            class="flex h-full w-5 cursor-ew-resize items-center justify-center bg-surface-default shadow-drawer-expand-button hover:bg-surface-hover"
          >
            <LscIcon size="sm" icon="lsi-drag" />
          </button>
        </div>
      </template>
      <template #container>
        <slot v-if="isDrawerOpen" />
      </template>
    </LscDrawerCard>
  </LscDrawer>
</template>
