import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import { useFileActions } from '@/api';
import { TEAMWORK_MAX_FILE_SIZE } from './constants';

/**
 * @param {Object} params
 * @param {number?} params.maxUploadSize Files over this size will not be uploaded
 */
function FileUploads({ taskId: _taskId, maxUploadSize = TEAMWORK_MAX_FILE_SIZE }) {
  const taskId = shallowRef(_taskId);

  // const emit = defineEmits(['filesUploading']);
  const { getPresignedUrl, hasSpaceToUpload, uploadFileToServer, updateFileDetails, uploadNewFileVersion } =
    useFileActions();
  const toast = useLsToast();
  const { t, formatFileSize } = useI18n();

  const filesToUpload = ref([]);
  const showSelectExistingFileDialog = shallowRef(false);

  const fileRefs = computed(() => filesToUpload.value.map(({ fileRef }) => fileRef).filter(Boolean));

  const fileMetaList = computed(() => {
    let files = filesToUpload.value;
    // user can quickly move between tasks
    // avoid showing files(s) being uploaded from preivous task
    if (taskId.value) {
      files = files.filter((fileObj) => (fileObj.taskId ? fileObj.taskId === taskId.value : true));
    }

    return files.map((fileObj) => {
      return {
        mode: 'client',
        fileId: 0,
        fileName: fileObj.file.name,
        displayName: fileObj.file.name,
        src: '0',
        uploadedAt: DateTime.now(),
        uploadDate: DateTime.now(),
        isUploading: fileObj.isUploading,
        uploadProgress: fileObj.uploadProgress,
        size: fileObj.file.size,
        fileSizeInBytes: fileObj.file.size,
        fileSize: formatFileSize(fileObj.file.size),
        isOverMaxSize: fileObj.file.size > maxUploadSize,
        fileRef: fileObj.fileRef,
      };
    });
  });

  function removeFile(fileRef) {
    filesToUpload.value = filesToUpload.value.filter((file) => file.fileRef !== fileRef);
  }

  function resetFiles() {
    filesToUpload.value = [];
  }

  async function uploadFile({ url, fileObj }) {
    // eslint-disable-next-line no-param-reassign
    fileObj.isUploading = true;
    const onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      // eslint-disable-next-line no-param-reassign
      fileObj.uploadProgress = percentCompleted;
    };

    await uploadFileToServer({ url, file: fileObj.file, onUploadProgress });
    // eslint-disable-next-line no-param-reassign
    fileObj.isUploading = false;
  }

  async function processFileAttachment(fileObj, skipFreeSpaceCheck = false) {
    if (fileObj.fileRef) {
      return null;
    }

    // TODO: check allowed file types
    // presign file to be able to upload
    const presignRes = await getPresignedUrl({ fileName: fileObj.file.name, fileSize: fileObj.file.size });
    const { url, ref: presignedRef } = presignRes.data;
    // eslint-disable-next-line no-param-reassign
    fileObj.fileRef = presignedRef;

    if (fileObj.file.size > maxUploadSize) {
      return null;
    }

    const hasEnoughSpace = !skipFreeSpaceCheck ? await hasSpaceToUpload(fileObj.file.size) : true;

    if (hasEnoughSpace) {
      await uploadFile({ url, fileObj });
    } else {
      toast.critical(t("You have reached your plan's allocated file storage"));
    }

    // eslint-disable-next-line no-param-reassign
    fileObj.isUploading = false;

    return { ref: presignedRef };
  }

  function wrapFiles(files) {
    return files.map((file) => {
      return {
        file,
        isUploading: false,
        uploadProgress: 0,
        mode: 'client',
        fileRef: '',
        taskId: taskId.value,
      };
    });
  }

  async function uploadFiles(files, skipFreeSpaceCheck = false) {
    const promises = [];
    filesToUpload.value = [...filesToUpload.value, ...wrapFiles(Array.from(files))];
    filesToUpload.value.forEach((file) => {
      promises.push(
        processFileAttachment(file, skipFreeSpaceCheck).catch(() => {
          toast.critical(t('Unable to upload file'));
        }),
      );
    });

    const result = await Promise.allSettled(promises);
    filesToUpload.value = filesToUpload.value.filter((file) => file.fileRef !== '');
    return result;
  }

  return {
    showSelectExistingFileDialog,
    fileMetaList,
    fileRefs,
    uploadFiles,
    removeFile,
    resetFiles,
    updateFileDetails,
    uploadNewFileVersion,
  };
}

export function useFileUploads({ taskId = undefined, maxUploadSize = TEAMWORK_MAX_FILE_SIZE } = {}) {
  return FileUploads({ taskId, maxUploadSize });
}
