import { getLastChildPath } from '../lastChildPath';

export const projectsRoutes = [
  {
    path: '/projects',
    component: () => import('./RouteProjects.vue'),
    children: [
      {
        path: '',
        redirect() {
          return `/projects/${getLastChildPath('projects', 'list')}`;
        },
      },
      {
        path: 'list',
        component: () => import('./RouteProjectsList.vue'),
        meta: {
          sampleProjectsBannerVisible: true,
        },
      },
      {
        path: 'templates',
        component: () => import('./RouteProjectsTemplates.vue'),
        children: [
          {
            path: '',
            redirect() {
              return `/projects/templates/projects/${getLastChildPath('projects/templates/projects', 'teamwork')}`;
            },
          },
          {
            path: 'projects/custom',
            component: () => import('./RouteProjectsTemplatesProjectsCustom.vue'),
          },
          {
            path: 'projects/teamwork',
            component: () => import('./RouteProjectsTemplatesProjectsTeamwork.vue'),
            children: [
              {
                path: 'categories/:id',
                component: () => import('./RouteProjectsTemplatesProjectsTeamwork.vue'), // Update with the correct component path
              },
            ],
          },
          {
            path: 'projects/upgrade',
            component: () => import('./RouteProjectsTemplatesProjectsUpgrade.vue'),
          },
          {
            path: ':pathMatch(.*)*',
            redirect() {
              return '/projects/templates';
            },
          },
        ],
      },
      {
        path: 'portfolio*',
        redirect() {
          return '/planning/portfolio';
        },
      },
      {
        path: 'chart',
        redirect() {
          return '/planning/chart';
        },
      },
      {
        path: ':pathMatch(.*)*',
        redirect() {
          return '/projects/list';
        },
      },
    ],
  },
  {
    path: '/projects/:projectId(\\d+)',
    component: () => import('./RouteProject.vue'),
    props: true,
    meta: {
      sampleProjectsBannerVisible: true,
    },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsStartPageRedirect.vue'),
      },
      {
        path: 'finance',
        component: () => import('./RouteProjectsFinance.vue'),
        children: [
          {
            path: '',
            redirect(to) {
              return `/projects/${to.params.projectId}/finance/${getLastChildPath('projects/finance', 'budgets')}`;
            },
          },
          {
            path: 'billing',
            redirect(to) {
              return `/projects/${to.params.projectId}/finance/${getLastChildPath('projects/finance', 'billing/open')}`;
            },
          },
          {
            path: 'billing/:billingTab(open|closed|completed)',
            component: () => import('./RouteProjectsFinanceBilling.vue'),
            props: true,
          },
          {
            path: 'billing/:invoiceId(\\d+)',
            component: () => import('./RouteProjectsFinanceBilling.vue'),
            props: true,
          },
          {
            path: 'budgets',
            component: () => import('./RouteProjectsFinanceBudgets.vue'),
            props: true,
          },
          {
            path: 'rates',
            component: () => import('./RouteProjectsFinanceRates.vue'),
            props: true,
          },
          {
            path: 'time',
            component: () => import('./RouteProjectsFinanceTime.vue'),
            props: true,
          },
          {
            path: 'expenses',
            component: () => import('./RouteProjectsFinanceExpenses.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'billing/:status?',
        redirect(to) {
          return `/projects/${to.params.projectId}/finance/billing/open`;
        },
      },
      {
        path: 'comments',
        component: () => import('./RouteProjectsComments.vue'),
      },
      {
        path: 'files',
        component: () => import('./RouteProjectsFiles.vue'),
      },
      {
        path: 'files/:fileId(\\d+)',
        component: () => import('./RouteProjectsFile.vue'),
        props(route) {
          const props = {
            ...route.params,
          };
          props.object = { type: 'files', id: parseInt(props.fileId, 10) };
          return props;
        },
      },
      {
        path: 'forms',
        component: () => import('./RouteProjectsForms.vue'),
        children: [
          {
            path: '',
            component: () => import('../form/RouteFormList.vue'),
          },
          {
            path: 'create',
            component: () => import('../form/RouteFormEdit.vue'),
          },
          {
            path: ':formId(\\d+)',
            props(route) {
              const props = {
                ...route.params,
              };
              props.object = { type: 'forms', id: parseInt(props.formId, 10) };
              return props;
            },
            component: () => import('../form/RouteFormEdit.vue'),
          },
        ],
      },
      {
        path: 'gantt',
        redirect: (to) => `/projects/${to.params.projectId}/tasks/gantt`,
      },
      {
        path: 'tickets',
        component: () => import('./RouteProjectsTickets.vue'),
      },
      {
        path: 'links',
        component: () => import('./RouteProjectsLinks.vue'),
      },
      {
        path: 'links/:linkId(\\d+)',
        component: () => import('./RouteProjectsLink.vue'),
        props(route) {
          const props = {
            ...route.params,
          };
          props.object = { type: 'links', id: parseInt(props.linkId, 10) };
          return props;
        },
      },
      {
        path: 'messages',
        component: () => import('./RouteProjectsMessages.vue'),
      },
      {
        path: 'messages/create',
        component: () => import('./RouteProjectsMessagesCreate.vue'),
      },
      {
        path: 'messages/:messageId(\\d+)',
        component: () => import('./RouteProjectsMessage.vue'),
        props(route) {
          const props = {
            ...route.params,
          };
          props.object = { type: 'messages', id: parseInt(props.messageId, 10) };
          return props;
        },
      },
      {
        path: 'milestones',
        component: () => import('./RouteProjectsMilestones.vue'),
        children: [
          {
            path: '',
            redirect(to) {
              return `/projects/${to.params.projectId}/milestones/${getLastChildPath('project/milestones', 'all')}`;
            },
          },
          {
            path: ':milestoneTab(all|calendar|completed|late|upcoming)',
            component: () => import('./RouteProjectsMilestonesList.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'milestones/:milestoneId(\\d+)',
        component: () => import('./RouteProjectsMilestone.vue'),
        props(route) {
          const props = {
            ...route.params,
          };
          props.object = { type: 'milestones', id: parseInt(props.milestoneId, 10) };
          return props;
        },
      },
      {
        path: 'notebooks',
        component: () => import('./RouteProjectsNotebooks.vue'),
      },
      {
        path: 'notebooks/create',
        component: () => import('./RouteProjectsNotebooksCreate.vue'),
      },
      {
        path: 'notebooks/:notebookId(\\d+)',
        component: () => import('./RouteProjectsNotebook.vue'),
        props(route) {
          const props = {
            ...route.params,
          };
          props.object = { type: 'notebooks', id: parseInt(props.notebookId, 10) };
          return props;
        },
      },
      {
        path: 'notebooks/:notebookId(\\d+)/edit',
        component: () => import('./RouteProjectsNotebookEdit.vue'),
        props(route) {
          const props = {
            ...route.params,
          };
          props.object = { type: 'notebooks', id: parseInt(props.notebookId, 10) };
          return props;
        },
      },
      {
        path: 'overview',
        component: () => import('./RouteProjectsOverview.vue'),
        children: [
          {
            path: '',
            component: () => import('./RouteProjectsOverviewStartPageRedirect.vue'),
          },
          {
            path: 'activity',
            component: () => import('./RouteProjectsOverviewActivity.vue'),
          },
          {
            path: 'summary',
            component: () => import('./RouteProjectsOverviewSummary.vue'),
          },
        ],
      },
      {
        path: 'people',
        component: () => import('./RouteProjectsPeople.vue'),
        children: [
          {
            path: '',
            redirect(to) {
              return `/projects/${to.params.projectId}/people/${getLastChildPath('project/people', 'people')}`;
            },
          },
          {
            path: 'people',
            component: () => import('./RouteProjectsPeoplePeople.vue'),
          },
          {
            path: 'roles',
            component: () => import('./RouteProjectsPeopleRoles.vue'),
          },
          {
            path: 'teams',
            component: () => import('./RouteProjectsPeopleTeams.vue'),
            children: [
              {
                path: ':teamId(\\d+)/members',
                component: () => import('./RouteProjectsPeopleTeamMembers.vue'),
                props({ params }) {
                  return {
                    teamId: Number(params.teamId),
                  };
                },
              },
            ],
          },
        ],
      },
      {
        path: 'proofs',
        component: () => import('./RouteProjectsProofs.vue'),
      },
      {
        path: 'risks',
        component: () => import('./RouteProjectsRisks.vue'),
      },
      {
        path: 'report',
        component: () => import('./RouteProjectsReport.vue'),
      },
      {
        path: 'settings',
        component: () => import('./RouteProjectsSettings.vue'),
        children: [
          {
            path: '',
            redirect(to) {
              return `/projects/${to.params.projectId}/settings/${getLastChildPath('project/settings', 'general')}`;
            },
          },
          {
            path: ':settingsTab(general|integrations|notifications|tags|customfields|webhooks)',
            component: () => import('./RouteProjectsSettingsView.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'tasks',
        redirect(to) {
          return `/projects/${to.params.projectId}/tasks/${getLastChildPath('project/tasks', 'table')}`;
        },
      },
      {
        path: 'tasks/list',
        component: () => import('./RouteProjectsTasksList.vue'),
        props: (to) => ({
          all: to.query.all === 'true',
        }),
        meta: {
          view: 'list',
          tracesSampleRate: 0.01,
          enableTaskDetailsListNavigation: true,
        },
      },
      {
        path: 'tasks/board',
        component: () => import('./RouteProjectsTasksBoard.vue'),
        meta: {
          view: 'board',
        },
      },
      {
        path: 'tasks/table',
        component: () => import('./RouteProjectsTasksTable.vue'),
        meta: {
          view: 'table',
        },
      },
      {
        path: 'tasks/gantt',
        component: () => import('./RouteProjectsGantt.vue'),
      },
      {
        path: 'tasks/:taskId(\\d+)',
        redirect(to) {
          return `/tasks/${to.params.taskId}`;
        },
      },
      {
        path: 'tasklists/:tasklistId(\\d+)',
        redirect(to) {
          return `/tasklists/${to.params.tasklistId}`;
        },
      },
      {
        path: 'time',
        component: () => import('./RouteProjectsTime.vue'),
      },
    ],
  },
  {
    path: '/tasklists/:tasklistId(\\d+)',
    component: () => import('./RouteProject.vue'),
    props(route) {
      const props = {
        ...route.params,
      };
      props.object = { type: 'tasklists', id: parseInt(props.tasklistId, 10) };
      return props;
    },
    children: [
      {
        path: '',
        redirect(to) {
          return `/tasklists/${to.params.tasklistId}/list`;
        },
      },
      {
        path: 'table',
        component: () => import('./RouteProjectsTasklistTable.vue'),
        meta: {
          view: 'table',
        },
        props(route) {
          const props = { ...route.params };
          props.tasklistId = parseInt(props.tasklistId, 10);
          return props;
        },
      },
      {
        path: 'list',
        component: () => import('./RouteProjectsTasksList.vue'),
        meta: {
          view: 'list',
          enableTaskDetailsListNavigation: true,
        },
        props(route) {
          return {
            tasklistId: Number(route.params.tasklistId),
          };
        },
      },
    ],
  },
  {
    path: '/tasks/:taskId(\\d+)',
    component: () => import('./RouteProjectsTask.vue'),
    props({ params }) {
      return {
        taskId: Number(params.taskId),
      };
    },
    meta: {
      isQuickViewRoute: true,
      tracesSampleRate: 0.01,
    },
  },
  {
    path: '/files/:fileId(\\d+)',
    component: () => import('./RouteProject.vue'),
    props(route) {
      const props = {
        ...route.params,
      };
      props.object = { type: 'files', id: parseInt(props.fileId, 10) };
      return props;
    },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsFile.vue'),
      },
    ],
  },
  {
    path: '/milestones/:milestoneId(\\d+)',
    component: () => import('./RouteProjectsMilestone.vue'),
    props(route) {
      return {
        milestoneId: Number(route.params.milestoneId),
      };
    },
    meta: {
      isQuickViewRoute: true,
      isQuickViewResizable: false,
    },
  },
  {
    path: '/links/:linkId(\\d+)',
    component: () => import('./RouteProject.vue'),
    props(route) {
      const props = {
        ...route.params,
      };
      props.object = { type: 'links', id: parseInt(props.linkId, 10) };
      return props;
    },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsLink.vue'),
      },
    ],
  },
  {
    path: '/messages/:messageId(\\d+)',
    component: () => import('./RouteProject.vue'),
    props(route) {
      const props = {
        ...route.params,
      };
      props.object = { type: 'messages', id: parseInt(props.messageId, 10) };
      return props;
    },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsMessage.vue'),
      },
    ],
  },
  {
    path: '/notebooks/:notebookId(\\d+)',
    component: () => import('./RouteProject.vue'),
    props(route) {
      const props = {
        ...route.params,
      };
      props.object = { type: 'notebooks', id: parseInt(props.notebookId, 10) };
      return props;
    },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsNotebook.vue'),
      },
      {
        path: 'edit',
        component: () => import('./RouteProjectsNotebookEdit.vue'),
      },
    ],
  },
];
