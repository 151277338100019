import { useFeatures, usePermissions } from '@/api';
import { useReps } from '@/module/reps';
import { useTicketPermissions } from './useTicketPermissions';

export function useDeskCTAPermissions(_client = {}) {
  const client = shallowRef(_client);
  const { projectsClientTicketsEnabled } = useFeatures();
  const { isOwnerAdmin } = usePermissions();
  const { deskManagedBookingFormEnabled } = useFeatures();
  const { accountAssociatedReps } = useReps();
  const { isDeskEnabled } = useTicketPermissions();

  const hasAccountManager = computed(() => Boolean(accountAssociatedReps.value?.accountManager));

  const isUsingMangedBookingForm = computed(
    () => isOwnerAdmin.value && deskManagedBookingFormEnabled.value && hasAccountManager.value,
  );
  const canAccessClientTickets = computed(() =>
    Boolean(
      projectsClientTicketsEnabled.value &&
        !client.value?.isOwner &&
        (isDeskEnabled.value || isUsingMangedBookingForm.value),
    ),
  );

  return {
    isUsingMangedBookingForm,
    canAccessClientTickets,
  };
}
