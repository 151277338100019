import { useFeatures } from '../features/useFeatures';
import { useCurrentUser } from '../user/useCurrentUser';
import { useCurrentAccount } from './useCurrentAccount';

const PRICEPLANS = {
  ENTERPRISE: 1,
  CORPORATE: 2,
  BUSINESS2: 3,
  BUSINESS1: 4,
  PERSONAL: 5,
  FREE: 6,
  ENTERPRISEPLUS: 7,
  STARTUP: 8,
  SMALLOFFICE: 9,
  PROFESSIONAL: 10,
  BUSINESS: 11,
  ENTERPRISECUSTOM: 12,
  OFFICE: 13,
  BUSINESSPLAN: 14,
  SMALLOFFICE010617: 15,
  PROFESSION010617: 16,
  BUSINESS010617: 17,
  PERUSER_FREE: 18,
  PERUSER_PRO2017: 19,
  PERUSER_ENTERPRISE: 20,
  PERUSER_PRO: 21,
  PERUSER_PREMIUM: 22,
  PERUSER_CORPORATE: 34,
  PERUSER_PRO_2020_07: 41,
  PERUSER_PREMIUM_2020_07: 42,
  PERUSER_TWONE_2020: 43,
  PERUSER_DELIVER_2021: 47,
  PERUSER_GROW_2021: 48,
  SPACES_ADDON: 49,
  SCALE_2021: 51,
  DELIVER_2022: 64,
  DELIVER_2024: 82,
  DELIVER_2024_Q3: 83,
  GROW_2022: 65,
  SCALE_2022: 66,
  STARTER: 67,
  ENTERPRISE_2023: 68,
  GROW_2023: 69,
  MAXP_STARTUP_2024: 74,
  MAXP_SMALLOFFICE_2024: 75,
  MAXP_OFFICE_2024: 76,
  MAXP_CORPORATE_2024: 77,
  MAXP_PREMIUM_2024: 78,
  MAXP_PROFESSIONAL_2024: 79,
  MAXP_BUSINESS_2024: 80,
  MAXP_BUSINESS_PLUS_2024: 81,
};

const FREE_PRICEPLANS = [PRICEPLANS.FREE, PRICEPLANS.PERUSER_FREE];
const ENTERPRISE_PRICEPLANS = [
  PRICEPLANS.ENTERPRISECUSTOM,
  PRICEPLANS.PERUSER_ENTERPRISE,
  PRICEPLANS.PERUSER_CORPORATE,
];
const DELIVER_PRICEPLANS = [PRICEPLANS.DELIVER_2022, PRICEPLANS.PERUSER_DELIVER_2021, PRICEPLANS.DELIVER_2024];
const STARTER_PRICEPLANS = [PRICEPLANS.STARTER];

export function usePricePlan() {
  const account = useCurrentAccount();
  const { hideUpgradePathsForMaxpSmallAccountsEnabled } = useFeatures();
  const user = useCurrentUser();

  const pricePlanId = computed(() => account.value?.pricePlanId);
  const pricePlanCode = computed(() => account.value?.pricePlanCode);
  const pricePlanName = computed(() => account.value?.pricePlanName);
  const pricePlanData = computed(() => account.value?.pricePlanData);
  const pricePlanType = computed(() => account.value?.pricePlanType);

  const isEnterprisePlan = computed(() =>
    Boolean(
      ENTERPRISE_PRICEPLANS.includes(pricePlanData.value?.id) ||
        pricePlanData.value?.code.toLowerCase().includes('enterprise'),
    ),
  );
  const isPlanFree = computed(() => Boolean(FREE_PRICEPLANS.includes(account.value?.pricePlanId)));
  const isPlanDeliver = computed(() => Boolean(DELIVER_PRICEPLANS.includes(account.value?.pricePlanId)));
  const isPlanStarter = computed(() => Boolean(STARTER_PRICEPLANS.includes(account.value?.pricePlanId)));

  const isPlanMaxProjects = computed(() => Boolean(pricePlanType.value === 'maxProjects'));
  const isPlanPerUser = computed(() => Boolean(pricePlanType.value === 'perUser'));

  const isInUpgradeSegment = computed(() => {
    const isAccountOlderThan60Days = Boolean(account.value?.dateSignedUp?.diffNow().as('days') > 60);
    return Boolean(
      !(isPlanFree.value || isPlanDeliver.value || isPlanStarter.value) &&
        !(
          hideUpgradePathsForMaxpSmallAccountsEnabled.value ||
          (!isPlanMaxProjects.value && isPlanFree.value && isAccountOlderThan60Days)
        ),
    );
  });

  const isPaymentOverdue = computed(() =>
    Boolean(account.value?.paymentStatus === 'paymentDue' && account.value?.inGracePeriod),
  );

  const canUpgradeAccount = computed(() =>
    Boolean(
      isPlanPerUser.value &&
        user.value?.administrator &&
        user.value?.inOwnerCompany &&
        !isEnterprisePlan.value &&
        !isPaymentOverdue.value,
    ),
  );

  const isPaid = computed(() => Boolean(account.value?.isPaid));

  const canTrialInviteUsers = computed(() => {
    /**
     * Can't invite user if it's a paid account.
     */
    if (isPaid.value || isPaymentOverdue.value || isEnterprisePlan.value) {
      return false;
    }

    return Boolean(
      account.value?.paymentStatus === 'trial' &&
        (user.value?.administrator || user.value?.permissions?.canManagePeople),
    );
  });

  const paidUserCount = computed(() => account.value?.paidForUsers);

  const maxUserCount = computed(() => account.value?.pricePlanMaxUsers);

  const hasReachedPlanMaxUsersCount = computed(() => Boolean(paidUserCount.value >= maxUserCount.value));

  const isInFreeTrial = computed(() => {
    return (
      !isPaid.value && account.value?.paymentStatus === 'trial' && Number(account.value?.trialDaysRemaining || 0) > 0
    );
  });

  const paymentOverdue = computed(() => {
    if (account.value) {
      const { inGracePeriod, paymentStatus } = account.value;

      return paymentStatus === 'paymentDue' && inGracePeriod;
    }
    return undefined;
  });
  const shouldWarnTrialExpiry = computed(() => {
    if (account.value && user.value) {
      const { paymentStatus, trialDaysRemaining } = account.value;
      const { administrator } = user.value;

      return (
        administrator &&
        paymentStatus !== '' &&
        !['free', 'paid'].includes(paymentStatus) &&
        trialDaysRemaining < 0 &&
        trialDaysRemaining > -31 &&
        // paymentOverdue is its own action
        !paymentOverdue.value
      );
    }

    return false;
  });

  const shouldWarnTrialEndingSoon = computed(() => {
    if (account.value && user.value) {
      const { paymentStatus, trialDaysRemaining } = account.value;
      const { administrator } = user.value;

      return (
        administrator &&
        paymentStatus !== '' &&
        !['free', 'paid'].includes(paymentStatus) &&
        trialDaysRemaining >= 0 &&
        trialDaysRemaining < 11 &&
        // make sure to treat paymentOverdue as its own state
        !paymentOverdue.value
      );
    }

    return false;
  });

  const isPaymentMethodInvoice = computed(() => account.value?.paymentMethod?.toLowerCase() === 'invoice');

  const canMaxpUpgradeAccount = computed(() =>
    Boolean(
      isPlanMaxProjects.value &&
        user.value?.administrator &&
        user.value?.inOwnerCompany &&
        !isEnterprisePlan.value &&
        !isPaymentOverdue.value,
    ),
  );

  return {
    canTrialInviteUsers,
    canUpgradeAccount,
    hasReachedPlanMaxUsersCount,
    isInFreeTrial,
    isInUpgradeSegment,
    isPaid,
    isPlanFree,
    isPlanMaxProjects,
    isPlanPerUser,
    isPlanDeliver,
    isPlanStarter,
    isPaymentOverdue,
    maxUserCount,
    paidUserCount,
    pricePlanId,
    pricePlanCode,
    pricePlanData,
    pricePlanName,
    pricePlanType,
    paymentOverdue,
    shouldWarnTrialExpiry,
    shouldWarnTrialEndingSoon,
    isPaymentMethodInvoice,
    canMaxpUpgradeAccount,
  };
}
