import { useLocalStorage } from '@vueuse/core';

const validTabs = computed(() => ['projects', 'tasklists'].filter(Boolean));

function validTab(tab) {
  return validTabs.value.includes(tab) ? tab : validTabs.value[0];
}

export function useTemplatesDrawer() {
  const selectedTabRaw = useLocalStorage('teamwork/useTemplatesDrawer/lastSelectedTab', validTab());
  const selectedTab = computed({
    get() {
      return validTab(selectedTabRaw.value);
    },
    set(tab) {
      selectedTabRaw.value = validTab(tab);
    },
  });

  return {
    selectedTab,
  };
}
