/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  const { updates } = response.data;

  updates.forEach((update) => {
    update.updateTime = DateTime.fromISO(update.date);
  });
  return updates;
}

export function useProductUpdatesV1Loader({ count, pageSize = 25 }) {
  const { state } = useListLoader({
    url: '/updates/latest.json',
    count,
    pageSize,
    responseToItems,
  });
  return state;
}
