<script setup>
import { useIntersectionObserver } from '@vueuse/core';
import { WidgetLoadingLazyDirection } from './constants';

/**
 * This component makes lazy loading trivial.
 *
 * It renders a DOM node and requests more items when it becomes visible.
 * It only ever sets `count` to positive integers.
 * It prevents excessive loading after the loader state is reset.
 *
 * Usage: <WidgetLoadingLazy v-model:count="loaderCount" :state="loaderState" margin="100px" />
 */
const props = defineProps({
  /** The `state` object from `useListLoader`. */
  state: {
    type: Object,
    required: true,
  },
  /** The increment value for `count`. */
  step: {
    type: Number,
    default: 5,
  },
  /** The min value for `count`. */
  minCount: {
    type: Number,
    default: 20,
  },
  /**
   * The scrolling direction which should trigger lazy loading.
   * @type {PropType<typeof WidgetLoadingLazyDirection[number]>}
   */
  direction: {
    type: String,
    default: 'down',
    validator: (value) => WidgetLoadingLazyDirection.includes(value),
  },
  /**
   * Triggers load on scroll when WidgetLoadingLazy is within the specified distance from the edge of the scroll container.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/length
   */
  margin: {
    type: String,
    default: '1px',
  },
});

/** The `count` param for `useListLoader`. */
const count = defineModel('count', {
  type: Number,
  required: true,
});

const step = computed(() => Math.max(props.step, 1));
const minCount = computed(() => Math.max(props.minCount, 1));
const items = computed(() => props.state.items.value);

const visible = shallowRef(false);
const targetElement = shallowRef();
let handle = null;

useIntersectionObserver(
  targetElement,
  (entries) => {
    const isVisible = entries[entries.length - 1].isIntersecting;
    if (visible.value !== isVisible) {
      visible.value = isVisible;
    }
  },
  { threshold: 0 },
);

watch(
  [count, step, items, visible],
  () => {
    cancelAnimationFrame(handle);

    // Ensure we never request too many items.
    const maxCount = Math.max(items.value.length + step.value, minCount.value);
    if (count.value > maxCount) {
      count.value = maxCount;
      return;
    }

    // Wait a bit to ensure that new items are rendered before requesting more.
    handle = requestAnimationFrame(() => {
      handle = requestAnimationFrame(() => {
        handle = requestAnimationFrame(() => {
          if (visible.value) {
            count.value = Math.max(items.value.length + step.value, minCount.value);
          }
        });
      });
    });
  },
  { immediate: true },
);

const wrapperClass = computed(() => {
  switch (props.direction) {
    case 'left':
    case 'right':
      // Using bottom-px to leave space for 1px height of the nested element.
      return 'top-0 bottom-px';
    case 'top':
    case 'down':
    default:
      // Using right-px to leave space for 1px width of the nested element.
      return 'left-0 right-px';
  }
});
const targetStyle = computed(() => {
  switch (props.direction) {
    case 'left':
      return { height: '1px', top: '0', width: props.margin, left: '0' };
    case 'right':
      return { height: '1px', top: '0', width: props.margin, right: '0' };
    case 'top':
      return { width: '1px', left: '0', height: props.margin, top: '0' };
    case 'down':
    default:
      return { width: '1px', left: '0', height: props.margin, bottom: '0' };
  }
});
</script>

<template>
  <div class="pointer-events-none sticky size-0" :class="wrapperClass">
    <div ref="targetElement" class="absolute" :style="targetStyle" />
  </div>
</template>
