import plugin from 'tailwindcss/plugin.js';
import AnimateCssPlugin from 'tailwindcss-animatecss';
import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { scrollbarPlugin } from '../plugins/scrollbarPlugin.js';

/** @type {import('tailwindcss').Config} */
export const BasePreset = {
  theme: {
    screens: {
      sm: TOKENS.LsdsGScreenSm,
      md: TOKENS.LsdsGScreenMd,
      lg: TOKENS.LsdsGScreenLg,
      xl: TOKENS.LsdsGScreenXl,
      '2xl': TOKENS.LsdsGScreen2xl,
    },
    spacing: {
      px: 'var(--lsds-g-spacing-px)',
      0: 'var(--lsds-g-spacing-0)',
      0.5: 'var(--lsds-g-spacing-0-5)',
      1: 'var(--lsds-g-spacing-1)',
      1.5: 'var(--lsds-g-spacing-1-5)',
      2: 'var(--lsds-g-spacing-2)',
      3: 'var(--lsds-g-spacing-3)',
      3.5: 'var(--lsds-g-spacing-3-5)',
      4: 'var(--lsds-g-spacing-4)',
      5: 'var(--lsds-g-spacing-5)',
      6: 'var(--lsds-g-spacing-6)',
      7: 'var(--lsds-g-spacing-7)',
      8: 'var(--lsds-g-spacing-8)',
      9: 'var(--lsds-g-spacing-9)',
      10: 'var(--lsds-g-spacing-10)',
      11: 'var(--lsds-g-spacing-11)',
      12: 'var(--lsds-g-spacing-12)',
      14: 'var(--lsds-g-spacing-14)',
      16: 'var(--lsds-g-spacing-16)',
      18: 'var(--lsds-g-spacing-18)',
      20: 'var(--lsds-g-spacing-20)',
      24: 'var(--lsds-g-spacing-24)',
      28: 'var(--lsds-g-spacing-28)',
      30: 'var(--lsds-g-spacing-30)',
      32: 'var(--lsds-g-spacing-32)',
      36: 'var(--lsds-g-spacing-36)',
      40: 'var(--lsds-g-spacing-40)',
      44: 'var(--lsds-g-spacing-44)',
      48: 'var(--lsds-g-spacing-48)',
      52: 'var(--lsds-g-spacing-52)',
      56: 'var(--lsds-g-spacing-56)',
      60: 'var(--lsds-g-spacing-60)',
      64: 'var(--lsds-g-spacing-64)',
      72: 'var(--lsds-g-spacing-72)',
      80: 'var(--lsds-g-spacing-80)',
      96: 'var(--lsds-g-spacing-96)',
      100: 'var(--lsds-g-spacing-100)',
    },
    // This is an intentional naming difference from Tailwind,
    // it lets us share the same tokens
    sizing: {
      px: 'var(--lsds-g-size-px)',
      0: 'var(--lsds-g-size-0)',
      0.5: 'var(--lsds-g-size-0-5)',
      1: 'var(--lsds-g-size-1)',
      1.5: 'var(--lsds-g-size-1-5)',
      2: 'var(--lsds-g-size-2)',
      3: 'var(--lsds-g-size-3)',
      3.5: 'var(--lsds-g-size-3-5)',
      4: 'var(--lsds-g-size-4)',
      5: 'var(--lsds-g-size-5)',
      6: 'var(--lsds-g-size-6)',
      7: 'var(--lsds-g-size-7)',
      8: 'var(--lsds-g-size-8)',
      9: 'var(--lsds-g-size-9)',
      10: 'var(--lsds-g-size-10)',
      11: 'var(--lsds-g-size-11)',
      12: 'var(--lsds-g-size-12)',
      14: 'var(--lsds-g-size-14)',
      16: 'var(--lsds-g-size-16)',
      18: 'var(--lsds-g-size-18)',
      20: 'var(--lsds-g-size-20)',
      24: 'var(--lsds-g-size-24)',
      28: 'var(--lsds-g-size-28)',
      30: 'var(--lsds-g-size-30)',
      32: 'var(--lsds-g-size-32)',
      36: 'var(--lsds-g-size-36)',
      40: 'var(--lsds-g-size-40)',
      44: 'var(--lsds-g-size-44)',
      48: 'var(--lsds-g-size-48)',
      52: 'var(--lsds-g-size-52)',
      56: 'var(--lsds-g-size-56)',
      60: 'var(--lsds-g-size-60)',
      64: 'var(--lsds-g-size-64)',
      72: 'var(--lsds-g-size-72)',
      80: 'var(--lsds-g-size-80)',
      96: 'var(--lsds-g-size-96)',
      100: 'var(--lsds-g-size-100)',
      144: 'var(--lsds-g-size-144)',
      160: 'var(--lsds-g-size-160)',
    },
    sizingPercentages: {
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
    },
    flexBasis: ({ theme }) => ({
      auto: 'auto',
      ...theme('sizing'),
      ...theme('sizingPercentages'),
      full: '100%',
    }),
    height: ({ theme }) => ({
      auto: 'auto',
      ...theme('sizing'),
      ...theme('sizingPercentages'),
      full: '100%',
      screen: '100vh',
      svh: '100svh',
      lvh: '100lvh',
      dvh: '100dvh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    maxHeight: ({ theme }) => ({
      ...theme('sizing'),
      none: 'none',
      full: '100%',
      screen: '100vh',
      svh: '100svh',
      lvh: '100lvh',
      dvh: '100dvh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    maxWidth: ({ theme, breakpoints }) => ({
      ...theme('sizing'),
      none: 'none',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      ...breakpoints(theme('screens')),
    }),
    minHeight: ({ theme }) => ({
      ...theme('sizing'),
      full: '100%',
      screen: '100vh',
      svh: '100svh',
      lvh: '100lvh',
      dvh: '100dvh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    minWidth: ({ theme }) => ({
      ...theme('sizing'),
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    size: ({ theme }) => ({
      auto: 'auto',
      ...theme('sizing'),
      ...theme('sizingPercentages'),
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    width: ({ theme }) => ({
      auto: 'auto',
      ...theme('sizing'),
      ...theme('sizingPercentages'),
      full: '100%',
      screen: '100vw',
      svw: '100svw',
      lvw: '100lvw',
      dvw: '100dvw',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    colors: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: 'var(--lsds-g-palette-black)',
      white: 'var(--lsds-g-palette-white)',
    },
    textStrokeColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: 'var(--lsds-g-palette-black)',
      white: 'var(--lsds-g-palette-white)',
    },
    backgroundColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      default: 'var(--lsds-a-color-background-default)',
      hover: 'var(--lsds-a-color-background-hover)',
      selected: 'var(--lsds-a-color-background-selected)',
      surface: {
        default: 'var(--lsds-a-color-surface-default)',
        hover: 'var(--lsds-a-color-surface-hover)',
        selected: 'var(--lsds-a-color-surface-selected)', // insert what this is for
        emphasis: {
          default: 'var(--lsds-a-color-surface-emphasis-default)',
          hover: 'var(--lsds-a-color-surface-emphasis-hover)',
          selected: 'var(--lsds-a-color-surface-emphasis-selected)',
          contrast: 'var(--lsds-a-color-surface-emphasis-contrast)',
        },
        info: {
          default: 'var(--lsds-a-color-surface-info-default)',
          subdued: 'var(--lsds-a-color-surface-info-subdued)',
        },
        success: {
          default: 'var(--lsds-a-color-surface-success-default)',
          subdued: 'var(--lsds-a-color-surface-success-subdued)',
        },
        warning: {
          default: 'var(--lsds-a-color-surface-warning-default)',
          subdued: 'var(--lsds-a-color-surface-warning-subdued)',
        },
        critical: {
          default: 'var(--lsds-a-color-surface-critical-default)',
          subdued: 'var(--lsds-a-color-surface-critical-subdued)',
        },
        accent: 'var(--lsds-a-color-surface-accent)',
        highlight: 'var(--lsds-a-color-surface-highlight)',
        dark: 'var(--lsds-a-color-surface-dark)',
      },
      action: {
        primary: {
          default: 'var(--lsds-a-color-action-primary-default)',
          pressed: 'var(--lsds-a-color-action-primary-pressed)',
          hover: 'var(--lsds-a-color-action-primary-hover)',
          disabled: 'var(--lsds-a-color-action-primary-disabled)',
        },
        secondary: {
          default: 'var(--lsds-a-color-action-secondary-default)',
          hover: 'var(--lsds-a-color-action-secondary-hover)',
          pressed: 'var(--lsds-a-color-action-secondary-pressed)',
          disabled: 'var(--lsds-a-color-action-secondary-disabled)',
        },
        tertiary: 'var(--lsds-a-color-action-tertiary-default)',
        critical: {
          default: 'var(--lsds-a-color-action-critical-default)',
          pressed: 'var(--lsds-a-color-action-critical-pressed)',
          hover: 'var(--lsds-a-color-action-critical-hover)',
          disabled: 'var(--lsds-a-color-action-critical-disabled)',
        },
      },
      separator: 'var(--lsds-c-separator-color)',
      decorative: {
        1: 'var(--lsds-a-color-decorative-1)',
        2: 'var(--lsds-a-color-decorative-2)',
        3: 'var(--lsds-a-color-decorative-3)',
        4: 'var(--lsds-a-color-decorative-4)',
        5: 'var(--lsds-a-color-decorative-5)',
        6: 'var(--lsds-a-color-decorative-6)',
        7: 'var(--lsds-a-color-decorative-7)',
        8: 'var(--lsds-a-color-decorative-8)',
        9: 'var(--lsds-a-color-decorative-9)',
        10: 'var(--lsds-a-color-decorative-10)',
        11: 'var(--lsds-a-color-decorative-11)',
        12: 'var(--lsds-a-color-decorative-12)',
        13: 'var(--lsds-a-color-decorative-13)',
        14: 'var(--lsds-a-color-decorative-14)',
        15: 'var(--lsds-a-color-decorative-15)',
        16: 'var(--lsds-a-color-decorative-16)',
      },
    },
    textColor: {
      inherit: 'inherit',
      current: 'currentColor',
      // Clashes with vuetify's transparent class
      // transparent: 'transparent',
      default: 'var(--lsds-a-color-text-default)',
      placeholder: 'var(--lsds-a-color-text-placeholder)',
      subtle: 'var(--lsds-a-color-text-subtle)',
      disabled: 'var(--lsds-a-color-text-disabled)',
      selected: 'var(--lsds-a-color-background-selected)',
      primary: 'var(--lsds-a-color-text-primary)',
      accent: 'var(--lsds-a-color-text-accent)',
      tertiary: 'var(--lsds-a-color-text-tertiary)',
      info: 'var(--lsds-a-color-text-info)',
      success: 'var(--lsds-a-color-text-success)',
      warning: 'var(--lsds-a-color-text-warning)',
      critical: 'var(--lsds-a-color-text-critical)',
      reversed: 'var(--lsds-a-color-text-reversed)',
      on: {
        primary: 'var(--lsds-a-color-text-on-primary)',
        secondary: 'var(--lsds-a-color-text-on-secondary)',
        critical: 'var(--lsds-a-color-text-on-critical)',
        warning: 'var(--lsds-a-color-text-on-warning)',
        success: 'var(--lsds-a-color-text-on-success)',
        info: 'var(--lsds-a-color-text-on-info)',
        accent: 'var(--lsds-a-color-text-on-accent)',
        dark: 'var(--lsds-a-color-text-on-dark)',
        emphasis: 'var(--lsds-a-color-text-on-emphasis)',
      },
      icon: {
        default: 'var(--lsds-a-color-icon-default)',
        subtle: 'var(--lsds-a-color-icon-subtle)',
        disabled: 'var(--lsds-a-color-icon-disabled)',
        info: 'var(--lsds-a-color-icon-info)',
        success: 'var(--lsds-a-color-icon-success)',
        warning: 'var(--lsds-a-color-icon-warning)',
        critical: 'var(--lsds-a-color-icon-critical)',
        primary: {
          default: 'var(--lsds-a-color-icon-primary-default)',
          hover: 'var(--lsds-a-color-icon-primary-hover)',
        },
        on: {
          primary: 'var(--lsds-a-color-icon-on-primary)',
          emphasis: 'var(--lsds-a-color-icon-on-emphasis)',
          dark: 'var(--lsds-a-color-icon-on-dark)',
        },
      },
      action: {
        primary: {
          default: 'var(--lsds-a-color-action-primary-default)',
          pressed: 'var(--lsds-a-color-action-primary-pressed)',
          hover: 'var(--lsds-a-color-action-primary-hover)',
        },
      },
      decorative: {
        1: 'var(--lsds-a-color-decorative-1)',
        2: 'var(--lsds-a-color-decorative-2)',
        3: 'var(--lsds-a-color-decorative-3)',
        4: 'var(--lsds-a-color-decorative-4)',
        5: 'var(--lsds-a-color-decorative-5)',
        6: 'var(--lsds-a-color-decorative-6)',
        7: 'var(--lsds-a-color-decorative-7)',
        8: 'var(--lsds-a-color-decorative-8)',
        9: 'var(--lsds-a-color-decorative-9)',
        10: 'var(--lsds-a-color-decorative-10)',
        11: 'var(--lsds-a-color-decorative-11)',
        12: 'var(--lsds-a-color-decorative-12)',
        13: 'var(--lsds-a-color-decorative-13)',
        14: 'var(--lsds-a-color-decorative-14)',
        15: 'var(--lsds-a-color-decorative-15)',
        16: 'var(--lsds-a-color-decorative-16)',
      },
    },
    borderColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      DEFAULT: 'var(--lsds-a-color-border-default)',
      default: 'var(--lsds-a-color-border-default)',
      bold: 'var(--lsds-a-color-border-bold)',
      subtle: 'var(--lsds-a-color-border-subtle)',
      strong: 'var(--lsds-a-color-border-strong)',
      contrast: 'var(--lsds-a-color-border-contrast)',
      reversed: 'var(--lsds-a-color-border-reversed)',
      accent: 'var(--lsds-a-color-border-accent)',
      separator: 'var(--lsds-a-color-border-separator)',
      'separator-on-surface-default': 'var(--lsds-a-color-border-separator-on-surface-default)',
      emphasis: 'var(--lsds-a-color-border-emphasis)',
      primary: {
        default: 'var(--lsds-a-color-border-primary-default)',
        hover: 'var(--lsds-a-color-border-primary-hover)',
        disabled: 'var(--lsds-a-color-border-primary-disabled)',
      },
      info: {
        default: 'var(--lsds-a-color-border-info-default)',
        hover: 'var(--lsds-a-color-border-info-hover)',
        disabled: 'var(--lsds-a-color-border-info-disabled)',
      },
      success: {
        default: 'var(--lsds-a-color-border-success-default)',
        hover: 'var(--lsds-a-color-border-success-hover)',
        disabled: 'var(--lsds-a-color-border-success-disabled)',
      },
      warning: {
        default: 'var(--lsds-a-color-border-warning-default)',
        hover: 'var(--lsds-a-color-border-warning-hover)',
        disabled: 'var(--lsds-a-color-border-warning-disabled)',
      },
      critical: {
        default: 'var(--lsds-a-color-border-critical-default)',
        hover: 'var(--lsds-a-color-border-critical-hover)',
        disabled: 'var(--lsds-a-color-border-critical-disabled)',
      },
      form: {
        default: 'var(--lsds-c-form-color-border-default)',
        hover: 'var(--lsds-c-form-color-border-hover)',
        active: 'var(--lsds-c-form-color-border-active)',
        disabled: 'var(--lsds-c-form-color-border-disabled)',
      },
      focus: {
        primary: 'var(--lsds-a-color-border-focus-primary)',
        secondary: 'var(--lsds-a-color-border-focus-secondary)',
        tertiary: 'var(--lsds-a-color-border-focus-tertiary)',
        critical: 'var(--lsds-a-color-border-focus-critical)',
        success: 'var(--lsds-a-color-border-focus-success)',
      },
    },
    outlineColor: ({ theme }) => theme('borderColor'),
    ringColor: ({ theme }) => theme('borderColor'),
    divideColor: ({ theme }) => theme('borderColor'),
    boxShadow: {
      1: 'var(--lsds-g-shadow-1)',
      2: 'var(--lsds-g-shadow-2)',
      3: 'var(--lsds-g-shadow-3)',
      4: 'var(--lsds-g-shadow-4)',
      drawer: 'var(--lsds-c-drawer-shadow)',
      'drawer-expand-button': 'var(--lsds-c-drawer-expand-button-shadow)',
      dialog: 'var(--lsds-c-dialog-shadow)',
      popover: 'var(--lsds-c-popover-shadow)',
      none: 'none',
    },
    dropShadow: {
      1: 'var(--lsds-g-shadow-1)',
      2: 'var(--lsds-g-shadow-2)',
      3: 'var(--lsds-g-shadow-3)',
      4: 'var(--lsds-g-shadow-4)',
      none: '0 0 #0000',
    },
    borderRadius: {
      none: 'var(--lsds-g-border-rounded-none)',
      sm: 'var(--lsds-g-border-rounded-sm)',
      md: 'var(--lsds-g-border-rounded-md)',
      lg: 'var(--lsds-g-border-rounded-lg)',
      xl: 'var(--lsds-g-border-rounded-xl)',
      full: 'var(--lsds-g-border-rounded-full)',
    },
    borderWidth: {
      0: 'var(--lsds-g-border-width-0)',
      DEFAULT: 'var(--lsds-g-border-width-1)',
      2: 'var(--lsds-g-border-width-2)',
      3: 'var(--lsds-g-border-width-3)',
      4: 'var(--lsds-g-border-width-4)',
    },
    fontFamily: {
      sans: 'var(--lsds-g-font-family-default)',
      mono: 'var(--lsds-g-font-family-mono)',
    },
    fontSize: {
      h1: ['var(--lsds-a-typography-heading-1-font-size)', 'var(--lsds-a-typography-heading-1-line-height)'],
      h2: ['var(--lsds-a-typography-heading-2-font-size)', 'var(--lsds-a-typography-heading-2-line-height)'],
      h3: ['var(--lsds-a-typography-heading-3-font-size)', 'var(--lsds-a-typography-heading-3-line-height)'],
      h4: ['var(--lsds-a-typography-heading-4-font-size)', 'var(--lsds-a-typography-heading-4-line-height)'],
      h5: ['var(--lsds-a-typography-heading-5-font-size)', 'var(--lsds-a-typography-heading-5-line-height)'],
      h6: ['var(--lsds-a-typography-heading-6-font-size)', 'var(--lsds-a-typography-heading-6-line-height)'],
      'subtitle-1': [
        'var(--lsds-a-typography-subtitle-1-font-size)',
        'var(--lsds-a-typography-subtitle-1-line-height)',
      ],
      'subtitle-2': [
        'var(--lsds-a-typography-subtitle-2-font-size)',
        'var(--lsds-a-typography-subtitle-2-line-height)',
      ],
      'body-1': [
        'var(--lsds-a-typography-body-1-regular-font-size)',
        'var(--lsds-a-typography-body-1-regular-line-height)',
      ],
      'body-2': [
        'var(--lsds-a-typography-body-2-regular-font-size)',
        'var(--lsds-a-typography-body-2-regular-line-height)',
      ],
      'body-3': [
        'var(--lsds-a-typography-body-3-regular-font-size)',
        'var(--lsds-a-typography-body-3-regular-line-height)',
      ],
      'button-1': ['var(--lsds-a-typography-button-1-font-size)', 'var(--lsds-a-typography-button-1-line-height)'],
      'button-2': ['var(--lsds-a-typography-button-2-font-size)', 'var(--lsds-a-typography-button-2-line-height)'],
    },
    fontWeight: {
      normal: 'var(--lsds-g-font-weight-regular)',
      medium: 'var(--lsds-g-font-weight-medium)',
      semibold: 'var(--lsds-g-font-weight-semibold)',
      bold: 'var(--lsds-g-font-weight-bold)',
    },
    lineHeight: {
      0: 'var(--lsds-g-font-line-height-0)',
      1: 'var(--lsds-g-font-line-height-1)',
      2: 'var(--lsds-g-font-line-height-2)',
      3: 'var(--lsds-g-font-line-height-3)',
      4: 'var(--lsds-g-font-line-height-4)',
      5: 'var(--lsds-g-font-line-height-5)',
      6: 'var(--lsds-g-font-line-height-6)',
      7: 'var(--lsds-g-font-line-height-7)',
      none: 'var(--lsds-g-font-line-height-none)',
      full: 'var(--lsds-g-font-line-height-100)',
    },
    transitionTimingFunction: {
      DEFAULT: 'var(--lsds-g-motion-ease-in-out)',
      ease: 'var(--lsds-g-motion-ease)',
      in: 'var(--lsds-g-motion-ease-in)',
      out: 'var(--lsds-g-motion-ease-out)',
      'in-out': 'var(--lsds-g-motion-ease-in-out)',
      linear: 'var(--lsds-g-motion-ease-linear)',
    },
    transitionDuration: {
      DEFAULT: 'var(--lsds-g-motion-duration-100)',
      100: 'var(--lsds-g-motion-duration-100)',
      300: 'var(--lsds-g-motion-duration-300)',
      400: 'var(--lsds-g-motion-duration-400)',
      500: 'var(--lsds-g-motion-duration-500)',
    },
    extend: {
      aspectRatio: {
        '4/3': '4 / 3', // Use of / is deprecated in sass so for now it's placed here directly rather than in a token
      },
    },
  },
  plugins: [
    AnimateCssPlugin,
    scrollbarPlugin({
      layer: 'base',
      className: '*',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--lsds-c-scrollbar-color-default)',
    }),
    scrollbarPlugin({
      className: '.scrollbar-lg',
      scrollbarWidth: 'auto',
      scrollbarColor: 'var(--lsds-c-scrollbar-color-default)',
    }),
    scrollbarPlugin({
      className: '.scrollbar-on-dark',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--lsds-c-scrollbar-color-on-dark-default)',
    }),
    plugin(({ addUtilities }) => {
      addUtilities({
        '.text-overline': {
          fontSize: 'var(--lsds-a-typography-overline-font-size)',
          fontWeight: 'var(--lsds-a-typography-overline-font-weight)',
          letterSpacing: 'var(--lsds-a-typography-overline-letter-spacing)',
          lineHeight: 'var(--lsds-a-typography-overline-line-height)',
          textTransform: 'var(--lsds-a-typography-overline-text-transform)',
        },
      });
      addUtilities({
        '.text-label': {
          fontSize: 'var(--lsds-a-typography-label-font-size)',
          fontWeight: 'var(--lsds-a-typography-label-font-weight)',
          letterSpacing: 'var(--lsds-a-typography-label-letter-spacing)',
          lineHeight: 'var(--lsds-a-typography-label-line-height)',
          textTransform: 'var(--lsds-a-typography-label-text-transform)',
        },
      });
    }),
  ],
};
