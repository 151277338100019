import { DRAWERS, useAppDrawer } from '@/module/app';

const symbol = Symbol('useDeskDrawer');

function DeskDrawer() {
  const { isOpen, open, close } = useAppDrawer(DRAWERS.desk);
  const task = ref(null);
  const ticket = ref(null);

  function openCreateTicket(newTask) {
    ticket.value = null;
    task.value = unref(newTask);
  }

  function openTicket(newTicket) {
    task.value = null;
    ticket.value = unref(newTicket);
  }

  watch([task, ticket], ([newTask, newTicket]) => {
    if (newTask || newTicket) {
      open();
    } else {
      close();
    }
  });

  watch(isOpen, (newIsOpen) => {
    if (!newIsOpen) {
      task.value = null;
      ticket.value = null;
    }
  });

  return {
    isOpen,
    openCreateTicket,
    openTicket,
    task,
    ticket,
  };
}

export function provideDeskDrawer() {
  const drawer = DeskDrawer();
  provide(symbol, drawer);
  return drawer;
}

export function useDeskDrawer() {
  return inject(symbol);
}
