<script setup>
import { useI18n } from '@/util';
import SpacesDrawerSpacesBlankSlate from './SpacesDrawerSpacesBlankSlate.svg';

const { t } = useI18n();
</script>
<template>
  <LscEmptyState size="sm" class="my-8" :title="t('No results')" :message="t('No pages found')">
    <template #image>
      <SpacesDrawerSpacesBlankSlate />
    </template>
    <template #action>
      <LscLink to="/spaces/home/all" variant="standalone" prependIcon="lsi-arrow-right">
        {{ t('{productName} home', { productName: 'Spaces' }) }}
      </LscLink>
    </template>
  </LscEmptyState>
</template>
