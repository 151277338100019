<script setup>
import { ChatDrawer } from '@/module/chat';
import { FormDrawer } from '@/module/form';
import { HelpCenterDrawer } from '@/module/helpCenter';
import { NotificationDrawer } from '@/module/notification';
import { ProjectJumpToDrawer } from '@/module/project';
import { SearchDrawer } from '@/module/search';
import { SpacesDrawer } from '@/module/spaces';
import { TemplatesDrawer } from '@/module/template';
import { useAppShellSidebar } from '../sidebar/useAppShellSidebar';

const { activeDrawerPanel, isDrawerPanelPinned, activeDrawerPanelWidth } = useAppShellSidebar();
</script>

<template>
  <div
    class="fixed top-0 z-[13] h-screen w-[--drawer-width] bg-surface-default"
    :class="{
      'shadow-drawer': !isDrawerPanelPinned,
      hidden: !activeDrawerPanel,
      block: activeDrawerPanel,
    }"
    :style="{
      '--drawer-width': activeDrawerPanelWidth,
    }"
  >
    <div class="position-sticky overflow-hidden">
      <div class="fixed h-full w-[--drawer-width]">
        <SearchDrawer />
        <ProjectJumpToDrawer />
        <NotificationDrawer />
        <HelpCenterDrawer />
        <FormDrawer />
        <TemplatesDrawer />
        <SpacesDrawer />
        <ChatDrawer />
      </div>
    </div>
  </div>
</template>
