export const defaultCurrency = Object.freeze({
  id: 1,
  name: 'US dollar',
  code: 'USD',
  symbol: '$',
  decimalPoints: 2,
});

/**
 * @typedef {object} Currency
 * @property {String} code - The currency code.
 * @property {Number} decimalPoints - The number of decimal points for the currency.
 */

/**
 * @param {Object} options
 * @param {ShallowRef<Currency>} options.installationCurrency
 * @param {ShallowRef<String>} options.languageCode
 */
export function createFormatCurrency({ currency: installationCurrency, languageCode }) {
  /**
   * Returns a formatted currency value as a string.
   * @param {Parameters<Intl.NumberFormat['format']>[0]} value
   * @param {Object} options - Formatting options.
   * @param {MaybeRef<Currency>?} options.currency - Provide only if it differs from the installation currency.
   * @param {'standard' | 'compact'} options.notation - The notation to use for formatting the number.
   * @returns {String} Formatted currency string.
   */
  function formatCurrency(value, { currency, notation = 'standard' } = {}) {
    const selectedCurrency = shallowRef(currency ?? installationCurrency);
    if (!Object.hasOwn(selectedCurrency.value, 'code') || !Object.hasOwn(selectedCurrency.value, 'decimalPoints')) {
      throw new Error('formatCurrency: The provided currency is malformed.');
    }
    return new Intl.NumberFormat(languageCode.value, {
      compactDisplay: 'short',
      currency: selectedCurrency.value.code,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: Number.isInteger(value) ? 0 : selectedCurrency.value.decimalPoints,
      notation,
      style: 'currency',
    }).format(value);
  }

  /**
   * Converts a standard currency value to a cent value.
   * @param {Number} value
   * @returns {Number}
   */
  function convertToCentValue(value) {
    if (!value && value !== 0) {
      return undefined;
    }
    const { decimalPoints } = installationCurrency.value;
    const centValue = value * 10 ** decimalPoints;
    return Number(`${Math.round(`${centValue}e${decimalPoints}`)}e-${decimalPoints}`);
  }

  /**
   * Converts a cent value to a standard currency value.
   * @param {Number} value
   * @returns {String}
   */
  function convertFromCentValue(value) {
    if (!value && value !== 0) {
      return undefined;
    }
    const { decimalPoints } = installationCurrency.value;
    const singleCentValue = 10 ** (decimalPoints * -1);
    const zeroDecimalString = `.${'0'.repeat(decimalPoints)}`;
    return (value * singleCentValue).toFixed(decimalPoints).replace(zeroDecimalString, '');
  }

  return {
    formatCurrency,
    convertToCentValue,
    convertFromCentValue,
  };
}
