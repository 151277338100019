import { useCurrentUser, useCurrentAccount, useDeskCurrentUser } from '@/api';

export function useTicketPermissions() {
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const deskUser = useDeskCurrentUser();

  const isDeskEnabled = computed(() =>
    Boolean(account.value?.deskEnabled && (user.value?.inOwnerCompany || !account.value?.strictBranding)),
  );

  const hasDeskAccount = computed(() => Boolean(deskUser.value));

  return {
    isDeskEnabled,
    hasDeskAccount,
  };
}
