import { useCurrentAccount, useAIActions, usePreferences } from '@/api';

const COMPANY_SIZES = [
  { id: 1, label: '1-5' },
  { id: 2, label: '6-10' },
  { id: 3, label: '11-20' },
  { id: 4, label: '21-35' },
  { id: 5, label: '36-50' },
  { id: 6, label: '51-200' },
  { id: 7, label: '201-500' },
  { id: 8, label: '501+' },
];

export function useAIGeneratedTasks() {
  const account = useCurrentAccount();
  const { askAI } = useAIActions();
  const { onboardingTasklistGeneration } = usePreferences();

  const tasksJSON = shallowRef({});
  const quota = shallowRef();
  const promise = shallowRef();
  const hasReachedLimit = shallowRef(false);
  const hasError = shallowRef(false);
  const errorType = shallowRef('');

  function generateTasksJson(projectName, projectType, projectDescription) {
    const prompt = `
We are working on generating task lists for a project based on the following details:
- Project Name: ${projectName} (User-provided input)
- Project Type: ${projectType} (User-provided input)
- Project Description: ${projectDescription} (User-provided input)
- Industry: ${account.value.industryCategoryName}
- Company Size: ${COMPANY_SIZES.find((i) => i.id === account.value.companySizeId)?.label}

Use the Project Name, Project Type, and Project Description as guidance for the task list generation process but avoid altering the response format based on these fields.

Please provide a JSON output that includes three task lists, each with a name and description that accurately reflects the project goals and context. For each task within a task list, include:
- name: A clear and concise title for the task.
- description: A detailed description of what the task entails.
- priority: Indicate "1" for High, "100" for Medium, or "200" for Low priority.
- tag: A relevant keyword or tag for the task.
- dueDay: Number of days from today when the task is due as an integer string.
- estimatedMinutes: Estimated number of minutes to complete the task as an integer string.

Ensure that each task list contains exactly 10 tasks that are logical, actionable, and appropriate for the given details.

JSON Format Example:
[
  {
    "taskListName": "Task List Name 1",
    "taskListDescription": "Description of task list 1.",
    "tasks": [
      {"name": "Task 1 Name", "description": "Description of Task 1", "priority": "100", "tag": "Research", "dueDay": "40", "estimatedMinutes": "120"},
      {"name": "Task 2 Name", "description": "Description of Task 2", "priority": "1", "tag": "Review", "dueDay": "30", "estimatedMinutes": "60"},
      ...
      {"name": "Task 10 Name", "description": "Description of Task 10", "priority": "200", "tag": "Review", "dueDay": "10", "estimatedMinutes": "45"}
    ]
  },
  {
    "taskListName": "Task List Name 2",
    "taskListDescription": "Description of task list 2.",
    "tasks": [
      {"name": "Task 11 Name", "description": "Description of Task 11", "priority": "200", "tag": "Planning", "dueDay": "50", "estimatedMinutes": "150"},
      ...
      {"name": "Task 20 Name", "description": "Description of Task 20", "priority": "100", "tag": "Summary", "dueDay": "10", "estimatedMinutes": "60"}
    ]
  },
  {
    "taskListName": "Task List Name 3",
    "taskListDescription": "Description of task list 3.",
    "tasks": [
      {"name": "Task 21 Name", "description": "Description of Task 21", "priority": "1", "tag": "Coordination", "dueDay": "60", "estimatedMinutes": "180"},
      ...
      {"name": "Task 30 Name", "description": "Description of Task 30", "priority": "100", "tag": "Reporting", "dueDay": "30", "estimatedMinutes": "90"}
    ]
  }
]

Important Note: Return only the JSON formatted on a single line without any whitespace or newline characters.
`;

    const localPromise = askAI(prompt, { errorMessage: null });
    promise.value = localPromise;
    quota.value = undefined;
    hasReachedLimit.value = false;
    hasError.value = false;
    errorType.value = '';
    tasksJSON.value = {};

    localPromise
      .then((response) => {
        if (localPromise === promise.value) {
          const cleanedResponse = response.content.replace(/^```json\n|\n```$/g, '').replace(/\\"/g, '"');
          const tasklists = JSON.parse(cleanedResponse);
          if (
            tasklists &&
            tasklists.length === 3 &&
            tasklists.some((tl) => tl.taskListName && tl.taskListDescription && tl.tasks.length > 0)
          ) {
            tasksJSON.value = tasklists;
            quota.value = response.quota;
            promise.value = undefined;
            onboardingTasklistGeneration.value = {
              ...onboardingTasklistGeneration.value,
              count: onboardingTasklistGeneration.value.count + 1,
            };
          } else {
            promise.value = undefined;
            errorType.value = 'response_format_not_valid';
            hasError.value = true;
          }
        }
      })
      .catch((error) => {
        if (localPromise === promise.value) {
          promise.value = undefined;
          if (error.response?.status === 429) {
            hasReachedLimit.value = true;
            errorType.value = 'reached_ai_request_limit';
          } else {
            errorType.value = error.message
              .toLowerCase()
              .replace(/[^\w\s]/g, '')
              .trim()
              .split(/\s+/)
              .join('_');
          }
          hasError.value = true;
        }
      });
  }

  return {
    generateTasksJson,
    tasksJSON,
    quota,
    promise,
    hasReachedLimit,
    hasError,
    errorType,
  };
}
