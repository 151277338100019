/**
 * Very basic email testing regex.
 */
export function isEmail(input = '') {
  return /^.+@.+\..{2,}$/.test(input);
}

export function isUrl(value) {
  /*
    this regex was taken from TinyMCE - https://github.com/tinymce/tinymce/blob/9b6ef28b8b849ee346e88e3b2fdf375246b58ec9/modules/polaris/src/main/ts/ephox/polaris/api/Regexes.ts#L63
    previously it used this one - https://www.freecodecamp.org/news/check-if-a-javascript-string-is-a-url#how-to-use-a-regular-expression-constructor
    the reason for the change is to include domain/#text/ and domain/#/text/ as valid options
  */
  const pattern =
    // eslint-disable-next-line no-useless-escape
    /^(?:[A-Za-z][A-Za-z\d.+-]{0,14}:\/\/(?:[-.~*+=!&;:'%@?^${}(),\w]+@)?|www\.|[-;:&=+$,.\w]+@)[A-Za-z\d-]+(?:\.[A-Za-z\d-]+)*(?::\d+)?(?:\/(?:[-.~*+=!;:'%@$(),\/\w]*[-~*+=%@$()\/\w])?)?(?:\?(?:[-.~*+=!&;:'%@?^${}(),\/\w]+))?(?:#(?:[-.~*+=!&;:'%@?^${}(),\/\w]+))?$/;

  return pattern.test(value);
}

export function isValidId(value) {
  return Number.isInteger(value) && value > 0;
}

/**
 * Matches email thread id from gmail.
 * we might need to modify if adding more email providers.
 * @param {*} value
 * @returns
 */
export function isValidEmailThreadId(value) {
  return /^[a-zA-Z0-9_-]+$/.test(value);
}
