import { useLocalStorage } from '@vueuse/core';
import { Settings } from 'luxon';
import { useNowByMinute } from '../date/useNowByMinute';
import { createFormatCurrency, defaultCurrency } from './currency';
import {
  createDateFormat,
  createFormatDate,
  createFormatDateRange,
  createFormatDateTime,
  createFormatTime,
  createFormatDateRelative,
  createHourCycle,
  createTimeFormat,
  defaultDateFormatId,
  defaultTimeFormatId,
  defaultTimeZoneName,
  parseDate,
  createToTimeParts,
} from './dateTime';
import { parseDuration, formatMinutes, formatDuration, formatDecimalHours } from './duration';
import { createFormatList } from './list';
import { createFormatNumber } from './number';
import { createT, defaultLanguageCode, defaultLocale, loadLocale } from './translate';

const symbol = Symbol('useI18n');

/**
 * @param {Map<string, function>} param.localeLoaders A Map from locale names to functions
 *   which load the corresponding locale config.
 */
function I18n({ localeLoaders = new Map() } = {}) {
  const options = {
    deep: false,
    listenToStorageChanges: false,
    writeDefaults: false,
    mergeDefaults: false,
    shallow: true,
  };
  const languageCode = useLocalStorage('teamwork/useI18n/languageCode', defaultLanguageCode, options);
  const timeZoneName = useLocalStorage('teamwork/useI18n/timeZoneName', defaultTimeZoneName, options);
  const dateFormatId = useLocalStorage('teamwork/useI18n/dateFormatId', defaultDateFormatId, options);
  const timeFormatId = useLocalStorage('teamwork/useI18n/timeFormatId', defaultTimeFormatId, options);
  const ellipsis = useLocalStorage('teamwork/useI18n/ellipsis', '\u2026', options);
  const weekStartsOnSunday = useLocalStorage('teamwork/useI18n/weekStartsOnSunday', false, options);
  const currency = useLocalStorage('teamwork/useI18n/currency', defaultCurrency, options);
  const now = useNowByMinute();
  const dateFormat = createDateFormat({ dateFormatId });
  const timeFormat = createTimeFormat({ timeFormatId });
  const hourCycle = createHourCycle({ timeFormatId });
  const is12HourCycle = computed(() => hourCycle.value === 'h12');
  const locale = shallowRef(defaultLocale);
  const localeError = shallowRef();
  const error = computed(() => (languageCode.value === locale.value.languageCode ? undefined : localeError.value));
  const ready = computed(() => languageCode.value === locale.value.languageCode);
  const { formatNumber, formatPercentage, formatFileSize, convertToPercentage, convertFromPercentage } =
    createFormatNumber({ languageCode });
  const formatList = createFormatList({ languageCode });
  const t = createT({ locale, formatNumber });
  const formatDate = createFormatDate({ t, dateFormat, now });
  const formatDateRange = createFormatDateRange({ dateFormat, now });
  const formatDateRelative = createFormatDateRelative({ t, now });
  const formatTime = createFormatTime({ t, now, hourCycle });
  const toTimeParts = createToTimeParts({ hourCycle });

  const formatDateTime = createFormatDateTime({ hourCycle, formatDate, formatTime, now });
  const { formatCurrency, convertFromCentValue, convertToCentValue } = createFormatCurrency({ currency, languageCode });

  // Configure Luxon.
  watch(
    [languageCode, timeZoneName, weekStartsOnSunday],
    () => {
      // Teamwork locale code contains only the language code, for example `en`.
      // The OS locale code contains the language code and country code, for example `en-IE`.
      // When the Teamwork and OS language codes match, we use the OS locale for better localization.
      const fullLocale =
        window.navigator.language.slice(0, 2) === languageCode.value ? window.navigator.language : languageCode.value;
      Settings.defaultZone = timeZoneName.value;
      Settings.defaultLocale = fullLocale;
      // We set `defaultNumberingSystem` and `defaultOutputCalendar` explicitly
      // to ensure that we can continue using `fromFormat` and `toFormat` without issues
      // when interacting with the API.
      Settings.defaultNumberingSystem = 'latn';
      Settings.defaultOutputCalendar = 'gregory';
      // We set the first day of the week based on the user's localization settings.
      // The minimum number of days in the first week should be 4 to align with the ISO 8601 standard.
      // Define Saturday and Sunday as the weekend days.
      Settings.defaultWeekSettings = {
        firstDay: weekStartsOnSunday.value ? 7 : 1,
        minimalDays: 4,
        weekend: [6, 7],
      };

      document.documentElement.lang = fullLocale;
    },
    { immediate: true },
  );

  // Configure the locale.
  watch(
    languageCode,
    () => {
      if (languageCode.value === locale.value.languageCode) {
        localeError.value = undefined;
        return;
      }
      if (languageCode.value === defaultLocale.languageCode) {
        locale.value = defaultLocale;
        localeError.value = undefined;
        return;
      }
      loadLocale(languageCode.value, localeLoaders).then(
        (loadedLocale) => {
          if (languageCode.value === loadedLocale.languageCode) {
            locale.value = loadedLocale;
            localeError.value = undefined;
          }
        },
        (e) => {
          if (languageCode.value !== locale.value.languageCode) {
            // eslint-disable-next-line no-console
            console.error(`useI18n: failed to load the "${languageCode.value}" locale"`, e);
            localeError.value = e;
          }
        },
      );
    },
    { immediate: true },
  );

  return {
    languageCode,
    timeZoneName,
    dateFormatId,
    dateFormat,
    timeFormatId,
    timeFormat,
    hourCycle,
    is12HourCycle,
    ellipsis,
    weekStartsOnSunday,
    currency,
    error,
    ready,
    convertFromCentValue,
    convertFromPercentage,
    convertToCentValue,
    convertToPercentage,
    formatCurrency,
    formatDate,
    formatDateRange,
    formatDateRelative,
    formatDateTime,
    formatDuration,
    formatFileSize,
    formatList,
    formatMinutes,
    formatDecimalHours,
    formatNumber,
    formatPercentage,
    formatTime,
    parseDate,
    parseDuration,
    t,
    toTimeParts,
  };
}

export const i18nPlugin = {
  install(app, config) {
    app.provide(symbol, I18n(config));
  },
};

/**
 * @type {I18n}
 */
export function useI18n() {
  return inject(symbol);
}
