<script setup>
import { getAccessibleTextColor, useI18n } from '@/util';
import { LscChipSizes, LscChipTags, LscChipVariants } from './constants';

const props = defineProps({
  /**
   * The HTML tag to use for the chip.
   * @type {PropType<typeof LscChipTags[number]>}
   */
  is: {
    type: String,
    default: 'div',
    validator: (value) => LscChipTags.includes(value),
  },
  /**
   * The variant of the chip.
   * @type {PropType<typeof LscChipVariants[number]>}
   */
  variant: {
    type: String,
    default: 'default',
    validator: (value) => LscChipVariants.includes(value),
  },
  /**
   * The size of the chip.
   * @type {PropType<typeof LscChipSizes[number]>}
   */
  size: {
    type: String,
    default: 'lg',
    validator: (value, { variant }) => {
      if (variant === 'tag' && !['xs', 'sm'].includes(value)) {
        // eslint-disable-next-line no-console
        console.warn('The tag variant only accepts `xs` and `sm` sizes');
        return false;
      }
      return LscChipSizes.includes(value);
    },
  },
  /**
   * Whether the chip is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the chip is clearable.
   */
  clearable: {
    type: Boolean,
    default: false,
  },
  /**
   * The tooltip to show when hovering over the clear button.
   */
  clearTooltip: {
    type: String,
    default: '',
  },
  /**
   * The icon to show in the chip.
   * @type {PropType<LscIcon|undefined>}
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * The color of the chip.
   * NOTE: Currenly only used and accepted for the tag variant.
   */
  color: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['clear']);
const { t } = useI18n();
const buttonType = computed(() => (props.is === 'button' ? 'button' : undefined));

const iconSizeMap = {
  xs: 'xs',
  sm: 'sm',
  lg: 'md',
};

const iconSize = computed(() => iconSizeMap[props.size]);

const clearTooltip = computed(() => props.clearTooltip || t('Clear'));

const chipVariantStyleConfig = tv({
  base: 'content-border inline-flex cursor-default items-center gap-1 rounded-full px-2',
  slots: {
    prepend: 'flex shrink-0 items-center justify-center',
    icon: 'text-icon-subtle',
    clearButton: 'flex shrink-0 items-center justify-center',
  },
  variants: {
    size: {
      xs: {
        base: 'h-[--lsds-c-chip-height-xs] max-w-40 text-body-2',
      },
      sm: {
        base: 'h-[--lsds-c-chip-height-sm] max-w-40 text-body-2',
      },
      lg: {
        base: 'h-[--lsds-c-chip-height-lg] max-w-48 text-body-1',
      },
    },
    variant: {
      default: {
        base: 'bg-[--lsds-c-chip-color-bg-default-default] text-default',
      },
      'on-surface': {
        base: [
          'bg-[--lsds-c-chip-color-bg-on-surface-default] text-default',
          'border border-[--lsds-c-chip-color-border-on-surface-default]',
        ],
      },
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      is: 'button',
      class: {
        base: 'hover:bg-[--lsds-c-chip-color-bg-default-hover]',
      },
    },
    {
      variant: 'on-surface',
      is: 'button',
      class: {
        base: 'hover:border-[--lsds-c-chip-color-border-on-surface-hover]',
      },
    },
    {
      is: 'button',
      disabled: false,
      class: {
        base: '!cursor-pointer',
      },
    },
    {
      variant: 'tag',
      hasColor: true,
      class: {
        base: 'bg-[--lsc-chip-bg-color] text-[--lsc-chip-text-color]',
        icon: 'text-[--lsc-chip-text-color]',
      },
    },
    {
      variant: 'tag',
      hasColor: false,
      class: {
        base: 'bg-[--lsds-c-chip-color-bg-default-default] text-default',
      },
    },
  ],
});

const classes = computed(() =>
  chipVariantStyleConfig({
    ...props,
    hasColor: Boolean(props.color),
  }),
);

const shouldShowClearButton = computed(() => props.clearable && !props.disabled);

const style = computed(() => {
  if (props.variant !== 'tag' || !props.color) {
    return {};
  }
  return {
    '--lsc-chip-bg-color': props.color,
    '--lsc-chip-text-color': getAccessibleTextColor(props.color),
  };
});
</script>

<template>
  <Component
    :is="is"
    :type="buttonType"
    :class="classes.base()"
    :style="style"
    :aria-disabled="disabled"
    :disabled="disabled"
  >
    <div v-if="prependIcon || $slots.prepend" :class="classes.prepend()">
      <slot name="prepend">
        <LscIcon :icon="prependIcon" :size="iconSize" :class="classes.icon()" />
      </slot>
    </div>

    <LscOverflowEllipsis><slot /></LscOverflowEllipsis>

    <button
      v-if="shouldShowClearButton"
      v-LsdTooltip="clearTooltip"
      :aria-label="clearTooltip"
      :class="classes.clearButton()"
      type="button"
      @click="emit('clear')"
    >
      <LscIcon icon="lsi-close" :size="iconSize" />
    </button>
  </Component>
</template>
