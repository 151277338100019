<script setup>
import { computed } from 'vue';
import { useI18n } from '@/util';
import { useCurrentAccount, usePendo } from '@/api';
import { useHelpCenter } from '../useHelpCenter';
import { STATE_KNOWLEDGE_BASE_ARTICLE } from '../constants';
import HelpCenterDrawerMenuMedium from './HelpCenterDrawerMenuMedium.vue';

const { t } = useI18n();
const { trackPendoEvent } = usePendo();
const { pushState, HELP_CENTER_COMMON_METRICS } = useHelpCenter();

const account = useCurrentAccount();
const isAccountPaid = computed(() => {
  return account.value?.isPaid;
});

const menuItemsTrial = [
  {
    title: t('Create a project'),
    categorySlug: 'using-teamwork',
    articleSlug: 'adding-a-project',
    pendoLabel: 'create_project',
  },
  {
    title: t('Project structure'),
    categorySlug: 'efficiency',
    articleSlug: 'hierarchy-of-information-in-teamwork',
    pendoLabel: 'project_structure',
  },
  {
    title: t('Time tracking'),
    categorySlug: 'getting-started',
    articleSlug: 'time-tracking-overview',
    pendoLabel: 'time_tracking',
  },
  {
    title: t('Reports'),
    categorySlug: 'project-reports',
    articleSlug: 'reporting-options-for-teamwork',
    pendoLabel: 'reports',
  },
  {
    title: t('User types & companies'),
    categorySlug: 'people',
    articleSlug: 'license-types',
    pendoLabel: 'user_types_and_companies',
  },
  {
    title: t('Resource management'),
    categorySlug: 'schedule',
    articleSlug: 'resource-scheduling-overview',
    pendoLabel: 'resource_management',
  },
  {
    title: t('Invite your team'),
    categorySlug: 'using-teamwork',
    articleSlug: 'inviting-people-to-your-teamwork-site',
    pendoLabel: 'invite_your_team',
  },
];
const menuItemsPaid = [
  {
    title: t('My work'),
    categorySlug: 'my-work',
    articleSlug: 'using-the-my-work-table-view',
    pendoLabel: 'my_work',
  },
  {
    title: t('Create work'),
    categorySlug: 'table-view',
    articleSlug: 'adding-tasks-in-table-view',
    pendoLabel: 'create_work',
  },
  {
    title: t('Communicate on work'),
    categorySlug: 'collaboration',
    articleSlug: 'commenting-on-items',
    pendoLabel: 'communicate_on_work',
  },
  {
    title: t('Complete work'),
    categorySlug: 'project-sections',
    articleSlug: 'completing-and-reopening-tasks',
    pendoLabel: 'complete_work',
  },
  {
    title: t('Track time'),
    categorySlug: 'getting-started',
    articleSlug: 'time-tracking-overview',
    pendoLabel: 'track_time',
  },
];

function gettingStartedNavItemClicked(categorySlug, articleSlug, pendoLabel) {
  trackPendoEvent({
    eventName: 'HELP_EVENT',
    commonMetrics: HELP_CENTER_COMMON_METRICS,
    metadata: {
      event_action: 'getting_started_item_clicked',
      event_label: pendoLabel,
    },
  });

  pushState(STATE_KNOWLEDGE_BASE_ARTICLE, { categorySlug, articleSlug });
}

function getItems() {
  if (isAccountPaid.value) {
    return menuItemsPaid;
  }
  return menuItemsTrial;
}
</script>
<template>
  <HelpCenterDrawerMenuMedium
    :title="t('Getting started')"
    :items="getItems()"
    @itemClicked="gettingStartedNavItemClicked($event.categorySlug, $event.articleSlug, $event.pendoLabel)"
  />
</template>
