<script setup>
import { defineAsyncComponent } from 'vue';
import { useFormHelpLearnMoreModalDialog } from './useFormHelpLearnMoreModalDialog';

const FormHelpLearnMoreModalPanel = defineAsyncComponent(() => import('./FormHelpLearnMoreModalPanel.vue'));

const { isVisible } = useFormHelpLearnMoreModalDialog();
</script>

<template>
  <WidgetDialog v-model="isVisible">
    <template #default="{ close }">
      <FormHelpLearnMoreModalPanel @close="close" />
    </template>
  </WidgetDialog>
</template>
