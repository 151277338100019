<script setup>
import { useI18n } from '@/util';

defineProps({
  title: {
    type: String,
    default: undefined,
  },
  message: {
    type: String,
    default: undefined,
  },
});

const { t } = useI18n();
</script>

<template>
  <div class="flex h-dvh w-dvw items-center justify-center">
    <LscEmptyState :title="title ?? t('Unauthenticated')" :message="message" variant="error" />
  </div>
</template>
