import { useCurrentAccount } from '../account/useCurrentAccount';
import { usePricePlan } from '../account/usePricePlan';
import { useCurrentUser } from '../user/useCurrentUser';
import { useFeatures } from '../features/useFeatures';
import { usePlanTrial } from '@/module/planTrial';

const symbol = Symbol('usePermissions');

function PermissionsService() {
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const {
    clientUsersEnabled,
    customfieldsProjectsEnabled,
    customfieldsTasksEnabled,
    inboxEnabled,
    lightspeedCalendarEnabled,
    calendarEnabled,
    projectTemplatesEnabled,
    reportsEnabled,
    proofsEnabled,
    timeRemindersEnabled,
    timeApprovalsEnabled,
    timeApprovalsFlagEnabled,
    intakeFormsEnabled,
    resourceSchedulingEnabled,
    profitabilityEnabled,
    projectsFixedFeePermissionEnabled,
  } = useFeatures();
  const { isInUpgradeSegment } = usePricePlan();
  const { shouldShowPlanTrialUpgradeSlates } = usePlanTrial();

  const isAccount = computed(() => Boolean(user.value?.userType === 'account'));
  const isSiteAdmin = computed(() => Boolean(user.value?.administrator));
  const isSiteOwner = computed(() => Boolean(user.value?.siteOwner));
  const isOwnerAdmin = computed(() => Boolean(isSiteAdmin.value && user.value?.inOwnerCompany));
  const isOwnerAccount = computed(() => Boolean(user.value?.inOwnerCompany && isAccount.value));
  const isCollaborator = computed(() => Boolean(user.value?.userType === 'collaborator'));
  const isClientUser = computed(() => Boolean(clientUsersEnabled.value && user.value?.isClientUser));
  const isInOwnerCompany = computed(() => Boolean(user.value?.inOwnerCompany));
  const isAdminOnAProject = computed(() => Boolean(user.value?.permissions?.isAdminOnAProject));
  const canManageCostRates = computed(() => Boolean(isOwnerAdmin.value || isSiteOwner.value));

  const canOpenSupportCenter = computed(() => account.value?.strictBranding || user.value?.inOwnerCompany);
  const canAccessPeopleCompaniesTeams = computed(() =>
    Boolean(isOwnerAccount.value || (user.value?.permissions?.canManagePeople && isAccount.value)),
  );
  const canAccessPortfolio = computed(() => Boolean(user.value?.permissions?.canAccessPortfolio));

  const canAddProjects = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions?.canAddProjects));

  const canAddCustomReports = computed(() => Boolean(user.value?.permissions?.canAddCustomReports));

  const canManageProjectTemplates = computed(() =>
    Boolean(isSiteAdmin.value || user.value?.permissions?.canManageProjectTemplates),
  );
  const canManageTaskTemplates = computed(() => {
    return Boolean(isSiteAdmin.value || user.value?.permissions?.canAccessTemplates);
  });
  const canManagePeople = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions?.canManagePeople));

  const canManagePortfolio = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions?.canManagePortfolio));

  const canManageCustomfields = computed(() => Boolean(user.value?.permissions?.canManageCustomFields));

  const canViewGlobalSettingsTags = computed(() => Boolean(isOwnerAdmin.value && account.value?.tagsEnabled));
  const canViewGlobalSettingsCustomfields = computed(() =>
    Boolean(
      (customfieldsProjectsEnabled.value || customfieldsTasksEnabled.value) &&
        (isOwnerAdmin.value || canManageCustomfields.value),
    ),
  );
  const canViewProjectTemplates = computed(() =>
    Boolean(projectTemplatesEnabled.value && (isSiteAdmin.value || user.value?.permissions?.canViewProjectTemplates)),
  );
  const canViewProjectTemplatesUpgradeCta = computed(() =>
    Boolean(
      !projectTemplatesEnabled.value && canAddProjects.value && user.value?.inOwnerCompany && isInUpgradeSegment.value,
    ),
  );

  const shouldShowNewUpgradePath = computed(() => !shouldShowPlanTrialUpgradeSlates.value);
  const canViewReportsUpgradeCta = computed(() =>
    Boolean(
      (isInUpgradeSegment.value || shouldShowNewUpgradePath.value) &&
        (isSiteAdmin.value || isAdminOnAProject.value) &&
        user.value?.inOwnerCompany,
    ),
  );
  const canViewReports = computed(() =>
    Boolean((isSiteAdmin.value || isAdminOnAProject.value) && (reportsEnabled.value || canViewReportsUpgradeCta.value)),
  );

  const canViewFormsPanel = computed(() => Boolean(!isClientUser.value) && intakeFormsEnabled.value);
  const canViewInbox = inboxEnabled;
  const canViewCalendar = computed(() => Boolean(isSiteAdmin.value || user.value?.permissions?.canAccessCalendar));
  const canViewMyCalendar = computed(() =>
    Boolean(
      lightspeedCalendarEnabled.value &&
        calendarEnabled.value &&
        !isClientUser.value &&
        !isCollaborator.value &&
        !user.value?.impersonating,
    ),
  );
  const canViewProofs = computed(() =>
    Boolean(proofsEnabled.value && (isAccount.value || isCollaborator.value) && !isClientUser.value),
  );
  const canAddProofs = computed(() => {
    return isAccount.value && !isCollaborator.value && !isClientUser.value;
  });
  const canViewPeople = computed(() => Boolean(isAccount.value && canManagePeople.value));

  const canManageTimeReminders = computed(() => Boolean(user.value?.permissions?.canManageTimeReminders));

  const canApproveTime = computed(() => Boolean(user.value?.permissions?.canApproveTime));

  const ssoEnabledAutoStart = computed(() =>
    Boolean(account.value?.SSO?.enabled && account.value?.SSO?.loginStyle === 'SSO automatic-start'),
  );

  const canViewAutomations = computed(() => isOwnerAccount.value);
  const canViewSchedule = computed(
    () => (isSiteAdmin.value || user.value?.permissions?.canViewSchedule) && resourceSchedulingEnabled.value,
  );
  const canManageSchedule = computed(() => canViewSchedule.value && user.value?.permissions?.canManageSchedule);

  const canAccessCompanyTimesheets = computed(() => Boolean(user.value?.companyTimesheetsPermissions?.canAccess));

  const canViewProfitabilityReport = computed(() => isOwnerAdmin.value && profitabilityEnabled.value);

  const canViewGlobalWorkflows = computed(() => !isCollaborator.value);
  const canManageGlobalWorkflows = computed(
    () => isOwnerAdmin.value || Boolean(user.value?.permissions?.canEditWorkflows),
  );

  const canViewSettings = computed(() => {
    return (
      isOwnerAdmin.value ||
      canViewGlobalSettingsTags.value ||
      canViewGlobalSettingsCustomfields.value ||
      (canManageTimeReminders.value && timeRemindersEnabled.value) ||
      (timeApprovalsEnabled.value && timeApprovalsFlagEnabled.value && isSiteAdmin.value) ||
      canManageGlobalWorkflows.value
    );
  });

  const canManageFixedFeeBudgets = computed(
    () =>
      isSiteAdmin.value ||
      (projectsFixedFeePermissionEnabled.value && user.value?.permissions?.canManageFixedFeeBudgets),
  );

  return {
    isAccount,
    isSiteAdmin,
    isSiteOwner,
    isCollaborator,
    isClientUser,
    isInOwnerCompany,
    isOwnerAccount,
    isOwnerAdmin,
    isAdminOnAProject,
    canAccessPeopleCompaniesTeams,
    canAccessPortfolio,
    canAddProjects,
    canAddCustomReports,
    canManagePeople,
    canManagePortfolio,
    canManageProjectTemplates,
    canManageSchedule,
    canManageTaskTemplates,

    canManageCustomfields,

    canViewFormsPanel,
    canViewGlobalSettingsCustomfields,
    canViewGlobalSettingsTags,
    canViewProjectTemplates,
    canViewProjectTemplatesUpgradeCta,
    canViewReportsUpgradeCta,
    canViewReports,
    canViewAutomations,

    canViewInbox,
    canViewCalendar,
    canViewMyCalendar,
    canViewProofs,
    canAddProofs,
    canViewPeople,
    canViewSchedule,

    canOpenSupportCenter,
    canViewSettings,

    ssoEnabledAutoStart,
    canAccessCompanyTimesheets,
    canManageTimeReminders,
    canApproveTime,
    canManageCostRates,
    canViewProfitabilityReport,
    shouldShowPlanTrialUpgradeSlates,

    canViewGlobalWorkflows,
    canManageGlobalWorkflows,
    canManageFixedFeeBudgets,
  };
}

export function providePermissions() {
  provide(symbol, PermissionsService());
}

/**
 * Provides global & project-level user permissions.
 * @type {PermissionsService}
 */
export function usePermissions() {
  return inject(symbol);
}
