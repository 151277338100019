import { useLocalStorage } from '@vueuse/core';

const validTabs = ['all', 'global', 'project'];

function validTab(tab) {
  return validTabs.includes(tab) ? tab : validTabs[0];
}

export function useFormDrawer() {
  const selectedTabRaw = useLocalStorage('teamwork/useFormDrawer/lastSelectedTab', validTab());
  const selectedTab = computed({
    get() {
      return validTab(selectedTabRaw.value);
    },
    set(tab) {
      selectedTabRaw.value = validTab(tab);
    },
  });

  return {
    selectedTab,
  };
}
