import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { useI18n } from '@/util';

export function useWorkflows() {
  const { t } = useI18n();

  const BACKLOG = Object.freeze({
    id: 0,
    name: t('Backlog'),
    color: TOKENS.LsdsCColorPicker12,
  });

  const HELP_DOCS_URL = 'https://support.teamwork.com/projects/workflows/workflows-introduction';

  function openHelpDocs(target = '_blank') {
    window.open(HELP_DOCS_URL, target);
  }

  return {
    BACKLOG,
    HELP_DOCS_URL,
    openHelpDocs,
  };
}
