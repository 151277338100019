import { DateTime } from 'luxon';
import { getEntity } from '@/util';
import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  return response.data.recents.map((_recent) => {
    const recent = _recent;
    recent.createdAt = DateTime.fromISO(recent.createdAt);
    recent.entity = getEntity({
      type: recent.entityType?.toLowerCase(),
      action: recent.actionType?.toLowerCase(),
      reaction: recent.extraDescription?.toLowerCase(),
      link: recent.itemLink?.toLowerCase(),
    });
    return recent;
  });
}

/**
 * Loads a list of a users recent activity from the Teamwork API.
 * This may include views on entities as well as
 * CRUD actions on entities, similar to activity log actions
 */
export function useRecentsV3Loader({ params, count, pageSize = 20 }) {
  const { state } = useListLoader({
    url: '/projects/api/v3/recency.json',
    params,
    count,
    responseToItems,
    pageSize,
  });

  return state;
}
