import { useI18n } from '@/util';

import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { toAssigneeString } from '../tasks/toAssigneeString';

const newTasklistId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

function normalizeFollowerIds(followers = []) {
  return followers
    .map(({ id, type }) => {
      if (type === 'teams') {
        return `t${id}`;
      }

      if (type === 'companies') {
        return `c${id}`;
      }

      return id;
    })
    .filter(Boolean)
    .join(',');
}

function toApiV1TasklistNewTaskDefaults(taskDefaults) {
  const newTaskDefaults = {};

  if (Object.hasOwn(taskDefaults, 'changeFollowers')) {
    newTaskDefaults['change-follower-ids'] = normalizeFollowerIds(taskDefaults.changeFollowers);
  }

  if (Object.hasOwn(taskDefaults, 'commentFollowers')) {
    newTaskDefaults['comment-follower-ids'] = normalizeFollowerIds(taskDefaults.commentFollowers);
  }

  if (Object.hasOwn(taskDefaults, 'description')) {
    newTaskDefaults.description = taskDefaults.description;
  }

  if (Object.hasOwn(taskDefaults, 'dueDateOffset')) {
    newTaskDefaults['due-date-offset'] = taskDefaults.dueDateOffset ?? '';
  }

  if (Object.hasOwn(taskDefaults, 'estimateMinutes')) {
    newTaskDefaults['estimated-minutes'] = taskDefaults.estimateMinutes ?? 0;
  }

  if (Object.hasOwn(taskDefaults, 'grantAccessTo')) {
    newTaskDefaults['grant-access-to'] = taskDefaults.grantAccessTo;
  }

  if (Object.hasOwn(taskDefaults, 'isPrivate')) {
    newTaskDefaults.private = taskDefaults.isPrivate;
  }

  if (Object.hasOwn(taskDefaults, 'assignees')) {
    newTaskDefaults['responsible-party-id'] = !taskDefaults.assignees ? '' : toAssigneeString(taskDefaults.assignees);
  }

  if (Object.hasOwn(taskDefaults, 'startDateOffset')) {
    newTaskDefaults['start-date-offset'] = taskDefaults.startDateOffset ?? '';
  }

  if (Object.hasOwn(taskDefaults, 'tagIds')) {
    newTaskDefaults.tagIds = taskDefaults.tagIds.join(',');
  }

  if (Object.hasOwn(taskDefaults, 'priority')) {
    newTaskDefaults.priority = taskDefaults.priority;
  }

  if (Object.hasOwn(taskDefaults, 'customFields')) {
    newTaskDefaults.customFields = taskDefaults.customFields?.map(({ id: customFieldId, value }) => ({
      customFieldId,
      value,
    }));
  }

  if (Object.hasOwn(taskDefaults, 'column')) {
    newTaskDefaults['column-id'] = taskDefaults.column?.id ?? 0;
  }

  if (Object.hasOwn(taskDefaults, 'workflowStages')) {
    newTaskDefaults.workflowId = taskDefaults.workflowStages?.[0]?.workflowId;
    newTaskDefaults.stageId = taskDefaults.workflowStages?.[0]?.stageId || 0;
  }

  if (Object.hasOwn(taskDefaults, 'reminders')) {
    newTaskDefaults.reminders = taskDefaults.reminders;
  }

  return newTaskDefaults;
}

function toApiV1Tasklist(tasklistPayload) {
  const tasklist = {};

  if (Object.hasOwn(tasklistPayload, 'isPinned')) {
    tasklist.pinned = tasklistPayload.isPinned;
  }

  if (Object.hasOwn(tasklistPayload, 'isPrivate')) {
    tasklist.private = tasklistPayload.isPrivate;
  }

  if (Object.hasOwn(tasklistPayload, 'isBillable')) {
    tasklist.isBillable = tasklistPayload.isBillable;
  }

  if (Object.hasOwn(tasklistPayload, 'description')) {
    tasklist.description = tasklistPayload.description;
  }

  if (Object.hasOwn(tasklistPayload, 'position')) {
    tasklist.position = Number(tasklistPayload.position);
  }

  if (Object.hasOwn(tasklistPayload, 'milestoneId')) {
    tasklist['milestone-id'] = tasklistPayload.milestoneId;
  }

  if (Object.hasOwn(tasklistPayload, 'icon')) {
    tasklist.icon = tasklistPayload.icon;
  }

  if (Object.hasOwn(tasklistPayload, 'name')) {
    tasklist.name = tasklistPayload.name;
  }

  if (Object.hasOwn(tasklistPayload, 'grantAccessTo')) {
    tasklist['grant-access-to'] = tasklistPayload.grantAccessTo;
  }

  if (Object.hasOwn(tasklistPayload, 'skipAutoClosing')) {
    tasklist['skip-auto-closing'] = tasklistPayload.skipAutoClosing;
  }

  // task list template
  if (Object.hasOwn(tasklistPayload, 'todoListTemplateAssignments')) {
    tasklist['todo-list-template-assignments'] = tasklistPayload.todoListTemplateAssignments;
  }

  if (Object.hasOwn(tasklistPayload, 'todoListTemplateId')) {
    tasklist['todo-list-template-id'] = tasklistPayload.todoListTemplateId;
  }

  if (Object.hasOwn(tasklistPayload, 'todoListTemplateKeepOffWeekends')) {
    tasklist['todo-list-template-keep-off-weekends'] = tasklistPayload.todoListTemplateKeepOffWeekends;
  }

  if (Object.hasOwn(tasklistPayload, 'todoListTemplateNotify')) {
    tasklist['todo-list-template-notify'] = tasklistPayload.todoListTemplateNotify;
  }

  if (Object.hasOwn(tasklistPayload, 'todoListTemplateStartDate')) {
    tasklist['todo-list-template-start-date'] = tasklistPayload.todoListTemplateStartDate;
  }

  if (Object.hasOwn(tasklistPayload, 'newTaskDefaults')) {
    tasklist['new-task-defaults'] = toApiV1TasklistNewTaskDefaults(tasklistPayload.newTaskDefaults);
  }

  return tasklist;
}

export function useTasklistActions() {
  const api = useAxios();
  const toast = useLsToast();
  const { t } = useI18n();

  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  // TODO remove this helper
  function emitOptimisticUpdateDeprecated(promise, action, tasklist) {
    emitOptimisticUpdate({
      promise,
      type: 'tasklist',
      action,
      tasklist,
    });
  }

  // TODO remove this helper
  function emitRealTimeUpdateDeprecated(action, tasklist, previousTasklist) {
    emitRealTimeUpdate({
      type: 'tasklist',
      action,
      projectId: tasklist.projectId ?? previousTasklist.projectId,
      tasklistId: tasklist.id ?? previousTasklist.id,
    });
  }

  function createTasklist(tasklist, { applyDefaultsToExistingTasks = false, isTemplate = false } = {}) {
    const promise = api
      .post(
        `/projects/${tasklist.projectId}/tasklists.json`,
        { applyDefaultsToExistingTasks, 'todo-list': toApiV1Tasklist(tasklist) },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: isTemplate
            ? `${t('There was a problem creating the template.')} ${t('Please try again.')}`
            : `${t('There was a problem creating the task list.')} ${t('Please try again.')}`,
        },
      )
      .then((response) => {
        emitRealTimeUpdateDeprecated('added', { ...tasklist, id: response.data.TASKLISTID }, {});
        return { ...tasklist, id: response.data.TASKLISTID };
      });
    emitOptimisticUpdateDeprecated(promise, 'create', { ...tasklist, id: newTasklistId() });
    return promise;
  }

  function updateTasklist(
    tasklist,
    previousTasklist,
    { applyDefaultsToExistingTasks = false, isTemplate = false } = {},
  ) {
    const promise = api
      .put(
        `/tasklists/${tasklist.id}.json`,
        { applyDefaultsToExistingTasks, 'todo-list': toApiV1Tasklist(tasklist) },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (
              error.response?.status === 422 &&
              error.response?.data?.MESSAGE === 'Task start date can not be later than the task due date'
            ) {
              return t('Task start date can not be later than the task due date');
            }
            if (
              error.response?.status === 422 &&
              error.response?.data?.MESSAGE ===
                "Privacy can't be changed on this list as some tasks in this list are marked private"
            ) {
              return t("Privacy can't be changed on this list as some tasks in this list are marked private");
            }
            if (isTemplate) {
              return `${t('There was a problem updating the template.')} ${t('Please try again')}`;
            }
            return `${t('There was a problem updating the task list.')} ${t('Please try again')}`;
          },
        },
      )
      .then(() => {
        emitRealTimeUpdateDeprecated('edited', tasklist, previousTasklist);

        return tasklist;
      });

    emitOptimisticUpdateDeprecated(promise, 'update', tasklist);
    return promise;
  }

  function moveTasklist(tasklist, projectId) {
    const promise = api
      .put(
        `/tasklists/${tasklist.id}/move.json`,
        {
          projectId,
          tasklistId: tasklist.id,
        },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to move the task list'),
        },
      )
      .then(() => {
        emitRealTimeUpdateDeprecated('edited', tasklist);
        return tasklist;
      });
    emitOptimisticUpdateDeprecated(promise, 'update', tasklist);
    return promise;
  }

  function copyTasklist(tasklist, options) {
    const promise = api
      .put(`/tasklist/${tasklist.id}/copy.json`, options, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to copy the task list'),
      })
      .then(({ data: { id } }) => {
        emitRealTimeUpdateDeprecated('added', { ...tasklist, id: Number(id) }, {});
        return { id: Number(id) };
      });
    emitOptimisticUpdateDeprecated(promise, 'create', { ...tasklist, id: newTasklistId() });
    return promise;
  }

  /**
   * Repositions the given tasklist to be before or after another tasklist.
   * @param {Object} tasklist
   * @param {Object} position
   * @param {number} position.positionAfterId
   * @param {number} position.positionBeforeId
   */
  function repositionTasklist(tasklist, position) {
    const promise = api
      .put(`/tasklists/${tasklist.id}/reposition.json`, position, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to move the task list'),
      })
      .then(() => {
        emitRealTimeUpdateDeprecated('reposition', tasklist);
      });

    emitOptimisticUpdateDeprecated(promise, 'reposition', { ...tasklist, ...position });
    return promise;
  }

  function deleteTasklist(tasklist) {
    const promise = api
      .delete(`/tasklists/${tasklist.id}.json`, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to delete the task list'),
      })
      .then(() => {
        emitRealTimeUpdateDeprecated('deleted', tasklist);
      });

    emitOptimisticUpdateDeprecated(promise, 'delete', tasklist);
    return promise;
  }

  async function saveTasklistAsTemplate({
    tasklistId,
    projectId,
    templateName,
    templateDescription,
    templateIsPrivate,
    includeCompletedTasks,
  }) {
    const formData = new FormData();
    formData.append('tasklistId', tasklistId);
    formData.append('projectId', projectId);
    formData.append('templateName', templateName);
    formData.append('templateDescription', templateDescription);
    formData.append('templateIsPrivate', templateIsPrivate);
    formData.append('includeCompletedTasks', includeCompletedTasks);

    const res = await api.post(`/?action=taskLists.OnAjaxSaveListAsTemplate()`, formData, {
      headers: { 'Socket-ID': socketId },
      errorMessage: t('Failed to save the task list as a template'),
    });

    if (res.status === 200) {
      if (res.data?.errMsg) {
        toast.critical(res.data?.errMsg);
      } else if (res.data?.msg === 'That template name is already in use') {
        toast.critical(t('That template name is already in use'));
      } else if (res.data?.msg === `This task list has no tasks so a template can\\'t be created`) {
        toast.critical(t("This task list has no tasks so a template can't be created"));
      }
    }

    return res;
  }

  function addTasksFromTaskTemplate(tasklist, templateId, data) {
    const promise = api
      .post(`/tasklists/${tasklist.id}/template/${templateId}.json`, data, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to add tasks from the template'),
      })
      .then(() => {
        emitRealTimeUpdate({
          type: 'projectTasks',
          action: 'edited',
          projectId: tasklist.projectId,
        });
      });
    return promise;
  }

  function sortTasklists(projectId, sortBy) {
    return api
      .put(
        `/projects/${projectId}/tasklists/sort.json`,
        { sortBy },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to reorder the task lists'),
        },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'projectTasks',
          action: 'edited',
          projectId,
        });
      });
  }

  function sortTasklist(tasklist, sortBy) {
    return api
      .put(
        `/tasklists/${tasklist.id}/sort.json`,
        { sortBy },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to reorder the tasks'),
        },
      )
      .then(() => {
        emitRealTimeUpdate({
          type: 'projectTasks',
          action: 'edited',
          tasklist,
        });
      });
  }

  function updateTasklistEmailAddress(tasklist, code) {
    return api.put(
      `/tasklists/${tasklist.id}/emailaddress.json`,
      { code },
      {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to update this email address'),
      },
    );
  }

  return {
    createTasklist,
    updateTasklist,
    moveTasklist,
    copyTasklist,
    repositionTasklist,
    deleteTasklist,
    sortTasklists,
    sortTasklist,
    saveTasklistAsTemplate,
    addTasksFromTaskTemplate,
    updateTasklistEmailAddress,
  };
}
