<script setup>
import { useI18n } from '@/util';
import NotificationDrawerEmptyStateRead from './assets/NotificationDrawerEmptyStateRead.svg';

const { t } = useI18n();
</script>

<template>
  <LscEmptyState
    size="sm"
    class="my-8"
    :title="t('All quiet for now')"
    :message="t('You do not have any read notifications.')"
  >
    <template #image>
      <NotificationDrawerEmptyStateRead class="size-24" />
    </template>
  </LscEmptyState>
</template>
