import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import { useI18n } from '@/util';
import {
  useFeatures,
  usePreferences,
  usePricePlan,
  useCohort,
  useCurrentUser,
  useCurrentAccount,
  useProjectActions,
  usePersonActions,
  useTrashcanActions,
  useClientActions,
  useCompaniesActions,
  useUserRateActions,
  useTemplatesActions,
  useAllocationsActions,
  useExperimentA2301,
} from '@/api';
import { useCurrentProject, useRoute } from '@/route';

export function useSampleProjects() {
  const currentProject = useCurrentProject();
  const toast = useLsToast();
  const { projectsDummyDataEnabled, projectsDummyDataV3Enabled } = useFeatures();
  const {
    sampleProjectsBannerDismissed,
    sampleProjectsVisible,
    sampleProjectIds,
    sampleUserIds,
    sampleClientIds,
    sampleProjectsRemovedDuringCheckout,
  } = usePreferences();
  const account = useCurrentAccount();
  const { createAllocation } = useAllocationsActions();
  const { deleteProject, createProjectUpdate } = useProjectActions();
  const { createProjectFromSampleTemplate } = useTemplatesActions();
  const { updateUserCost, updateUserRate } = useUserRateActions();
  const { deletePeople, sendInvite, updatePerson } = usePersonActions();
  const { restoreItem } = useTrashcanActions();
  const { createCompany } = useCompaniesActions();
  const { deleteClient, restoreClient } = useClientActions();
  const router = useRouter();
  const route = useRoute();
  const { t } = useI18n();
  const { isPaid } = usePricePlan();
  const { isICP, isCompanySizeAboveTen } = useCohort();
  const { isExpA2301Variation } = useExperimentA2301();
  const currentUser = useCurrentUser();

  const expA27AppLevelTargeting = computed(() => isICP.value && isCompanySizeAboveTen.value);

  const isSampleProjectRoute = computed(() => {
    return (
      route.matched.some(({ meta }) => meta.sampleProjectsBannerVisible) &&
      currentProject.value != null &&
      sampleProjectIds.value.includes(currentProject.value.id)
    );
  });

  const isExpA27Variation = computed(() => projectsDummyDataEnabled.value && expA27AppLevelTargeting.value);
  const isExpA27V3Variation = computed(() => projectsDummyDataV3Enabled.value && isExpA27Variation.value);

  const isSampleProjectsBannerRoute = computed(
    () =>
      (route.matched.some(({ meta }) => meta.sampleProjectsBannerVisible) && currentProject.value == null) ||
      isSampleProjectRoute.value,
  );

  const isSampleProjectsBannerVisible = computed(
    () => isExpA27Variation.value && isSampleProjectsBannerRoute.value && sampleProjectIds.value.length > 0,
  );

  const areSampleProjectsVisible = computed(() => sampleProjectsVisible.value.length > 0);

  // #region Sample Project Data
  const sampleTemplates = [
    { id: 45, name: '(Fixed-fee Sample Project) Project Management Plan' },
    { id: 46, name: '(Retainer Sample Project) Digital Marketing Plan' },
    { id: 47, name: '(Time & Expenses Sample Project) Creative Requests' },
  ];

  const sampleClients = [
    {
      name: '(Sample Client A)',
      clientSampleProjectIds: [45],
    },
    {
      name: '(Sample Client B)',
      clientSampleProjectIds: [46],
    },
  ];

  const sampleUsersList = [
    { email: 'pm@teamwork.sample', firstName: 'Project', lastName: 'Manager (Sample User)' },
    { email: 'marketing@teamwork.sample', firstName: 'Marketing', lastName: 'Specialist (Sample User)' },
  ];

  // Last two digits are decimals (70.00)
  // cost rate is 60% of billableRate
  const userBillableRate = 5000;
  const userCostRate = 3000;

  const allocations = [
    {
      assignedUserID: currentUser.value.id,
      color: 'afdff9',
      description: '',
      distributeType: 'distributed',
      duration: 480,
      hoursPerDay: 8,
      ignoreCollisions: false,
      projectId: 0,
      startedAt: DateTime.now().startOf('month').plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().startOf('month').plus({ days: 2 }).toFormat('yyyy-MM-dd'),
      title: 'Initial',
    },
    {
      assignedUserID: currentUser.value.id,
      color: 'f7a8c1',
      description: '',
      distributeType: 'distributed',
      duration: 480,
      hoursPerDay: 8,
      ignoreCollisions: false,
      projectId: 0,
      startedAt: DateTime.now().startOf('month').plus({ days: 14 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().startOf('month').plus({ days: 16 }).toFormat('yyyy-MM-dd'),
      title: 'Planning',
    },
    {
      assignedUserID: currentUser.value.id,
      color: '97f7a9',
      description: '',
      distributeType: 'distributed',
      duration: 480,
      hoursPerDay: 8,
      ignoreCollisions: false,
      projectId: 0,
      startedAt: DateTime.now().endOf('month').minus({ days: 5 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().endOf('month').minus({ days: 3 }).toFormat('yyyy-MM-dd'),
      title: 'Retrospective',
    },
  ];

  const projectHealthUpdates = [
    {
      health: 1,
      text: 'Tasks are taking much longer than the time estimated for them. Assess if project requires a reassessment of estimates or if the over run has been due to an unforeseen circumstance not accounted for during planning.',
    },
    {
      health: 2,
      text: 'Planning stage is on track and ready to deliver to schedule. Potential delays due to non-responsive client for payment set up. Finance have to follow up with account manager/sales manager should they be unable to establish contact by next Wednesday.',
    },
    {
      health: 3,
      text: 'Steady rate of requests coming in for team to complete with minimal tasks falling outside of scope or time estimate. Review resource requirements should requests extend beyond 30 per week.',
    },
  ];

  // #endregion Sample Project Data

  // note: this fails if the sample client companies already exist
  async function createSampleClients() {
    return Promise.all(
      sampleClients.map(async ({ name, clientSampleProjectIds }) =>
        createCompany({ company: { name } })
          .then(({ id }) => ({ id, clientSampleProjectIds }))
          .catch(() => {}),
      ),
    );
  }

  async function createSampleUsers() {
    async function createSampleUser(email, firstName, lastName) {
      return sendInvite({
        person: {
          'email-address': email,
          'first-name': firstName,
          'last-name': lastName,
          autoGiveProjectAccess: true,
          sendInvite: false,
        },
      })
        .then(({ data }) => data.id)
        .catch(() => {});
    }

    const sampleUsers = await Promise.all(
      sampleUsersList.map((user) => createSampleUser(user.email, user.firstName, user.lastName)),
    );

    sampleUserIds.value = sampleUsers.filter(Boolean).map((id) => Number(id));
    const sampleProjectUserAId = sampleUserIds.value[0];
    const sampleProjectUserBId = sampleUserIds.value[1];

    if (Number.isInteger(sampleProjectUserAId) && Number.isInteger(sampleProjectUserBId)) {
      await Promise.allSettled([
        // autoGiveProjectAccess doesn't work on initial user creation, so we need to update them manually
        updatePerson({ id: sampleProjectUserAId, autoGiveProjectAccess: true }),
        updatePerson({ id: sampleProjectUserBId, autoGiveProjectAccess: true }),

        // Set billable and cost rates for sampleProjectUserAId
        updateUserRate({ userId: sampleProjectUserAId, userRate: 12000 }),
        updateUserCost({ userId: sampleProjectUserAId, userCost: 8500 }),
        updateUserRate({ userId: sampleProjectUserBId, userRate: 10000 }),
        updateUserCost({ userId: sampleProjectUserBId, userCost: 7000 }),
      ]);
    }

    return {
      sampleProjectUserAId,
      sampleProjectUserBId,
    };
  }

  async function updateInstallationsRate(userRate, userCost) {
    await Promise.all([
      updateUserRate({ userId: currentUser.value.id, userRate }),
      updateUserCost({ userId: currentUser.value.id, userCost }),
    ]).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  }

  function updateSampleProjectsIdsPreferences(projectIds, overwriteIds) {
    if (overwriteIds) {
      sampleProjectIds.value = projectIds;
    }
    sampleProjectsVisible.value = projectIds;
  }

  function dismissSampleProjectsBanner() {
    sampleProjectsBannerDismissed.value = true;
  }

  async function deleteSampleData({ onCheckout = false } = {}) {
    // Ordering matters – Delete users first
    if (areSampleProjectsVisible.value) {
      if (sampleUserIds.value.length > 0) {
        const unassignFromAll = false;
        await deletePeople(sampleUserIds.value, unassignFromAll).catch(() => {
          if (!onCheckout) {
            toast.critical(t('There was a problem hiding sample users'));
          }
        });
      }

      // Delete projects next
      const deleteProjects = [];
      for (const sampleProjectId of sampleProjectIds.value) {
        deleteProjects.push(deleteProject({ id: sampleProjectId }));
      }
      sampleProjectsVisible.value = [];
      await Promise.all(deleteProjects)
        .then(() => {
          if (isSampleProjectRoute.value) {
            router.push('/projects/list');
          }
          if (onCheckout) {
            sampleProjectsRemovedDuringCheckout.value = true;
          } else {
            toast.success(t('All sample projects were hidden'));
          }
        })
        .catch(() => {
          sampleProjectsVisible.value = sampleProjectIds.value;
          if (!onCheckout) {
            toast.critical(t('There was a problem hiding sample projects'));
          }
        });

      // Delete clients after projects
      const deleteClients = [];
      for (const sampleClientId of sampleClientIds.value) {
        deleteClients.push(deleteClient(sampleClientId));
      }
      await Promise.allSettled(deleteClients);
    }
  }

  async function restoreSampleProjects({ onLoad } = {}) {
    // Order matters – restore clients first
    const restoreClients = [];
    for (const sampleClientId of sampleClientIds.value) {
      restoreClients.push(restoreClient(sampleClientId));
    }
    await Promise.allSettled(restoreClients);

    // Restore users before projects
    const restoreUsers = [];
    for (const sampleUserId of sampleUserIds.value) {
      restoreUsers.push(restoreItem({ type: 'people', id: sampleUserId }));
    }
    await Promise.allSettled(restoreUsers);

    // Restore projects last
    const restoreProjects = [];
    for (const sampleProjectId of sampleProjectIds.value) {
      restoreProjects.push(restoreItem({ type: 'projects', id: sampleProjectId }));
    }
    await Promise.allSettled(restoreProjects).then(() => {
      sampleProjectsVisible.value = sampleProjectIds.value;
      if (!onLoad) {
        toast.success(t('All sample projects are visible'));
      }
    });
  }

  async function checkIsPaidAfterCheckout() {
    if (isPaid.value) {
      await deleteSampleData({ onCheckout: true });
    } else if (sampleProjectsRemovedDuringCheckout.value) {
      await restoreSampleProjects({ onLoad: true }).then(() => {
        sampleProjectsRemovedDuringCheckout.value = false;
      });
    }
  }

  async function setWorkingHours() {
    try {
      updatePerson({
        id: currentUser.value.id,
        workingHours: {
          entries: [
            { weekday: 'monday', taskHours: 8 },
            { weekday: 'tuesday', taskHours: 8 },
            { weekday: 'wednesday', taskHours: 8 },
            { weekday: 'thursday', taskHours: 8 },
            { weekday: 'friday', taskHours: 8 },
            { weekday: 'saturday', taskHours: 0 },
            { weekday: 'sunday', taskHours: 0 },
          ],
        },
      });
    } catch (error) {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  async function createSampleClientsAndUsers() {
    const sampleUsers = await createSampleUsers();
    const createdSampleClients = await createSampleClients();
    sampleClientIds.value = createdSampleClients.map((sampleClient) => Number(sampleClient?.id) || undefined);
    return {
      sampleUsers,
      createdSampleClients,
    };
  }

  async function createSampleProjects({ sampleUsers, createdSampleClients } = {}) {
    sampleProjectIds.value = [];

    const templatesPromises = [];
    sampleTemplates.forEach((template) => {
      const companyId = createdSampleClients?.find(({ clientSampleProjectIds } = {}) =>
        clientSampleProjectIds?.includes(template.id),
      )?.id;
      const payload = {
        templateId: template.id,
        installationId: account.value.id,
        sourceInstallationId: account.value.id,
        projectName: template.name,
        cloneProjectName: template.name,
        owner: { id: currentUser.value.id },
        activeView: 'table',
        startPage: 'table',
        companyId,
        cloneprojectAction: 'copy',
        ...sampleUsers,
      };
      templatesPromises.push(createProjectFromSampleTemplate(payload));
    });

    const sampleProjects = await Promise.all(templatesPromises).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    if (sampleProjects?.length > 1) {
      const setupSampleProjectsPromises = [];
      sampleProjects?.forEach(({ data }, index) => {
        updateSampleProjectsIdsPreferences([...sampleProjectIds.value, parseInt(data.projectId, 10)], true);

        const projectHealthUpdate = projectHealthUpdates[index % projectHealthUpdates.length]; // wraps around if there are more projects than updates

        const updateObject = {
          update: { ...projectHealthUpdate },
        };

        setupSampleProjectsPromises.push(createProjectUpdate(data.projectId, updateObject));
        setupSampleProjectsPromises.push(
          createAllocation({ ...allocations[index], projectId: parseInt(data.projectId, 10) }),
        );
      });
      if (!isExpA2301Variation.value) {
        setupSampleProjectsPromises.push(updateInstallationsRate(userBillableRate, userCostRate));
      }
      setupSampleProjectsPromises.push(setWorkingHours());

      // set banner un-dismissed, to be sure
      sampleProjectsBannerDismissed.value = false;

      await Promise.all(setupSampleProjectsPromises).catch((error) => {
        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
    }
  }

  return {
    isExpA27Variation,
    isExpA27V3Variation,
    isSampleProjectsBannerVisible,
    areSampleProjectsVisible,
    dismissSampleProjectsBanner,
    deleteSampleData,
    restoreSampleProjects,
    checkIsPaidAfterCheckout,
    createSampleClientsAndUsers,
    createSampleProjects,
  };
}
