<script setup>
defineProps({
  invoice: {
    type: Object,
    default: null,
  },
  projectId: {
    type: Number,
    default: null,
  },
});

const modelValue = defineModel({ type: Boolean, default: false });

const FinanceInvoiceExportXeroDialogPanel = defineAsyncComponent(
  () => import('./FinanceInvoiceExportXeroDialogPanel.vue'),
);
</script>

<template>
  <WidgetDialog v-model="modelValue">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceInvoiceExportXeroDialogPanel :invoice="invoice" :projectId="projectId" @close="close" />
    </template>
  </WidgetDialog>
</template>
