import { useLocalStorage as useLocalStorageFromVueUse, syncRef } from '@vueuse/core';

/**
 * The same as `useLocalStorage` from `@vueuse/core` but allows the `key` to be a ref.
 */
export function useLocalStorage(key, defaultValue, options) {
  const deep = options?.deep ?? true;
  const shallow = options?.shallow ?? false;
  const serializer = options?.serializer ?? undefined;

  const keyRef = shallowRef(key);
  const valueRef = shallow ? shallowRef() : ref();

  let scope = null;

  watch(
    keyRef,
    () => {
      scope?.stop();
      scope = effectScope(true);
      scope.run(() => {
        const storedValueRef = useLocalStorageFromVueUse(keyRef.value, defaultValue, { deep, serializer });
        syncRef(storedValueRef, valueRef, { deep });
      });
    },
    { immediate: true },
  );

  onScopeDispose(() => {
    scope?.stop();
  });

  return valueRef;
}
