import { invoke, until, useIntervalFn } from '@vueuse/core';
import axios from 'axios';
import { useI18n } from '@/util';
import { useCurrentAccountState } from '../account/useCurrentAccount';
import { usePricePlan } from '../account/usePricePlan';
import { usePermissions } from '../permissions/usePermissions';
import { useCurrentUserState } from '../user/useCurrentUser';
import { useFeatures } from './useFeatures';
import { useLaunchDarkly } from './useLaunchDarkly';
import { usePendoCommonMetrics } from './usePendoCommonMetrics';
import { isBypassExperimentsQueryEnabled, isLogTrackEventsQueryEnabled } from './util';

const symbol = Symbol('usePendo');

/**
 * Logs Pendo Track Event name and metadata to the console.
 *
 * @param {string} eventName Name of the LaunchDarkly key
 * @param {Object} metadata Object to be passed to pendo.track
 */
function logTrackEvent(eventName, metadata) {
  if (metadata && Object.entries(metadata).length > 0) {
    // eslint-disable-next-line no-console
    console.info(`=== Pendo LS: ${eventName}`, metadata);
  } else {
    // eslint-disable-next-line no-console
    console.info(`=== Pendo LS: ${eventName}`);
  }
}

// Try to stringify
function stringify(value) {
  if (typeof value === 'string') {
    return value;
  }

  try {
    return JSON.stringify(value);
  } catch {
    return value;
  }
}

function installAndInitializePendoAgent({
  apiKey,
  installMaxRetries = 10,
  installRetryTimeInMillis = 5000,
  initializeMaxRetries = 10,
  initializeRetryTimeInMillis = 5000,
} = {}) {
  if (!apiKey) {
    // eslint-disable-next-line no-console
    console.error('Undefined Pendo API key, Pendo will not function');
    return;
  }

  const { item: account, inSync: accountInSync } = useCurrentAccountState();
  const { item: user, inSync: userInSync } = useCurrentUserState();
  const { initialized: featuresInitialized } = useFeatures();
  const { ready: localizationReady, timeZoneName } = useI18n();
  const {
    canAddProjects,
    canManagePeople,
    canManagePortfolio,
    canManageProjectTemplates,
    isOwnerAdmin,
    isOwnerAccount,
    isSiteAdmin,
    isClientUser,
    isCollaborator,
  } = usePermissions();
  const { isInFreeTrial, isPaid, isPlanFree, paidUserCount, pricePlanId, pricePlanCode } = usePricePlan();

  function install({ apiKey: localApiKey, maxRetries, retryTimeInMillis }) {
    return new Promise((resolve, reject) => {
      let retryCount = 0;
      const { pause } = useIntervalFn(
        () => {
          let error;

          try {
            // The standard Pendo agent installation script.
            // https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo#the-pendo-agent-0-3
            /* eslint-disable */
            ((p, e, n, d, o) => {
              let v;
              let w;
              let x;
              let y;
              let z;
              o = p[d] = p[d] || {};
              o._q = [];
              v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
              w = 0;
              x = v.length;

              while (w < x) {
                (function (m) {
                  o[m] =
                    o[m] ||
                    function () {
                      return o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                    };
                })(v[w]);

                ++w;
              }

              y = e.createElement(n);
              y.async = !0;
              // We proxy requests to Pendo to avoid ad blockers.
              // Proxy target: `https://cdn.eu.pendo.io/agent/static/${pendoApiKey}/pendo.js`
              y.src = `https://content.usage.teamwork.com/agent/static/${localApiKey}/pendo.js`;
              z = e.getElementsByTagName(n)[0];
              z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo');
            /* eslint-enable */
          } catch (err) {
            error = err;
          }

          if (!error && Boolean(window?.pendo)) {
            resolve();
            pause();

            return;
          }

          if (retryCount >= maxRetries) {
            reject(error || 'window.pendo not defined');
            pause();

            return;
          }

          retryCount++;
        },
        retryTimeInMillis,
        { immediate: true, immediateCallback: true },
      );
    });
  }

  function initialize({ maxRetries, retryTimeInMillis }) {
    return new Promise((resolve, reject) => {
      let retryCount = 0;
      const { pause } = useIntervalFn(
        () => {
          let error;
          try {
            // Initialize installed Pendo agent
            window.pendo.initialize({
              visitor: {
                id: `${account.value.isStaging ? 'staging-' : ''}${account.value.id}-${user.value.id}`,
                // Required if user is logged in
                new_visitor_id: user.value.id ? 'true' : 'false',
                email: user.value.emailAddress,
                // Recommended if using Pendo Feedback, or NPS Email
                full_name: `${user.value.firstName} ${user.value.lastName}`,
                site_owner: isOwnerAdmin.value,
                site_admin: isSiteAdmin.value,
                client_user: isClientUser.value,
                site_baseUrl: account.value.URL,
                teamwork_ui_variant: 'lightspeed',
                in_owner_company: isOwnerAccount.value,
                collaborator: isCollaborator.value,
                add_projects: canAddProjects.value,
                manage_people_and_companies: canManagePeople.value,
                manage_portfolios: canManagePortfolio.value,
                timezone: timeZoneName.value,
                manage_task_templates: canManageProjectTemplates.value,
                is_trial: isInFreeTrial.value,
                is_paying: isPaid.value && !isPlanFree.value,
                // Recommended if using Pendo Feedback
                login_count: user.value.loginCount,
                screen_width: window.screen.width,
                screen_height: window.screen.height,
                screen_pixel_depth: window.screen.pixelDepth,
                screen_orientation_type: window.screen.orientation?.type ?? '',
                persona: user.value.personaType,
              },
              account: {
                id: `${account.value.isStaging ? 'staging-' : ''}${account.value.id}`,
                // Highly recommended
                name: `${account.value.name}: Projects`,
                is_paying: isPaid.value && !isPlanFree.value,
                // Recommended if using Pendo Feedback
                // monthly_value:    # Recommended if using Pendo Feedback
                // planLevel:        # Optional
                // planPrice:        # Optional
                // creationDate:     # Optional
                is_trial: isInFreeTrial.value,
                plan_id: pricePlanId.value,
                subscription_plan: pricePlanCode.value,
                license_count: paidUserCount.value,
                start_date: account.value.dateSignedUp?.toUTC().toISO(),
                industry: account.value.industryCategoryName,
                site_baseUrl: account.value.URL,
                sso_enabled: account.value.SSO?.enabled ?? false,
                server_region: account.value.awsRegion,
                shard_no: account.value.shardNo,
                teamwork_ui_variant: 'lightspeed',
                first_payment_date: account.value.installationDateFirstPayment,
                strict_branding: account.value.strictBranding,
                allow_tw_brand: account.value.allowTeamworkProjectsBrand,
                is_installation_id_even: account.value.id % 2 === 0,
                trial_plan_id: account.value.pricePlanTrialData?.id ?? 0,
                trial_plan_days_remaining: account.value.pricePlanTrialData?.remainingDays ?? 0,
                payment_method: account.value.paymentMethod,
                billing_period: account.value.billingPeriod,
                site_owner_email: account.value.siteOwnerEmail,
                is_pendo_onboarding_segment: false,
                companysizesId: account.value.companySizeId,
                utm_campaign: account.value.installationUtmCampaign,
              },
              events: {
                ready() {
                  resolve();
                  pause();
                },
              },
            });
          } catch (err) {
            error = err;
          }

          if (retryCount >= maxRetries) {
            reject(error || 'ready() callback not triggered');
            pause();

            return;
          }

          retryCount++;
        },
        retryTimeInMillis,
        { immediate: true, immediateCallback: true },
      );
    });
  }

  invoke(async () => {
    await until(
      computed(() =>
        Boolean(
          accountInSync.value &&
            account.value &&
            userInSync.value &&
            user.value &&
            featuresInitialized.value &&
            localizationReady.value,
        ),
      ),
    ).toBe(true);

    if (!account.value.installationTrackingEnabled) {
      return;
    }

    // Install the Pendo agent and if successful, initialize it.
    install({ apiKey, maxRetries: installMaxRetries, retryTimeInMillis: installRetryTimeInMillis })
      .then(() => initialize({ maxRetries: initializeMaxRetries, retryTimeInMillis: initializeRetryTimeInMillis }))
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Unable to init Pendo agent:', error));
  });
}

function Pendo() {
  if (import.meta.env.PROD && new URLSearchParams(window.location.search).get('pendo') !== 'disabled') {
    const API_KEY = '971852c3-745c-4f38-6402-71cb5c1fafb4';
    const MAX_RETRIES = 10;
    const RETRY_TIME_IN_MILLIS = 5000;

    installAndInitializePendoAgent({
      apiKey: API_KEY,
      installMaxRetries: MAX_RETRIES,
      installRetryTimeInMillis: RETRY_TIME_IN_MILLIS,
      initializeMaxRetries: MAX_RETRIES,
      initializeRetryTimeInMillis: RETRY_TIME_IN_MILLIS,
    });
  }

  const { allCommonMetricsInSync, getCommonMetrics } = usePendoCommonMetrics();
  const { client: launchDarklyClient } = useLaunchDarkly();
  const EVENT_CATEGORIES = Object.freeze({
    DISCOVERY: 'discovery_event',
    ADVANCED_DISCOVERY: 'advanced_discovery_event',
    ACTIVATION: 'activation_event',
    STANDARD_INTERACTION: 'standard_interaction_event',
    ERROR: 'error_event',
  });
  const loggedExperiments = new Set();
  const maxRetries = 10;
  const retryTimeinMilliseconds = 500;

  function isPendoReady() {
    return Boolean(window.pendo?.isReady?.() && allCommonMetricsInSync.value);
  }

  /**
   * Internal method for using our own metric enricher to track event in Pendo.
   * @param {string} event Event name
   * @param {Object} properties Metadata object
   * @param {string[]} includeMetrics List of metrics to retrieve from backend.
   * @returns {Promise}
   */
  function metricEnricherTrack(event, properties, includeMetrics) {
    return axios.post('/metric/api/track', {
      event,
      visitorId: window.pendo.visitorId,
      accountId: window.pendo.accountId,
      timestamp: Date.now(),
      properties,
      includeMetrics,
      context: {
        userAgent: window.navigator.userAgent,
        url: window.location.href,
        title: document.title,
      },
    });
  }

  /**
   * Internal method for tracking event in Pendo, with retry mechanism.
   * @param {string} eventName Event name
   * @param {Object} [metadata] Metadata object
   * @param {import('./usePendoCommonMetrics').CommonMetric[]} [commonMetrics] Common metrics that will be loaded and added to metadata object. List of available common metrics can be found in `usePendoCommonMetrics.js` -> `getCommonMetrics`
   * @param {import('./usePendoCommonMetrics').IncludeMetric[]} [includeMetrics] List of metrics to retrieve from backend.
   */
  function track(eventName, metadata, commonMetrics, includeMetrics = [], retryCount = 0) {
    // If its not a production deployment log the track event and return
    if (!import.meta.env.PROD && isLogTrackEventsQueryEnabled()) {
      const combinedMetadata = { ...getCommonMetrics(commonMetrics), ...metadata };
      logTrackEvent(eventName, combinedMetadata);
      return;
    }
    if (isPendoReady()) {
      const combinedMetadata = { ...getCommonMetrics(commonMetrics), ...metadata };
      if (isLogTrackEventsQueryEnabled()) {
        logTrackEvent(eventName, combinedMetadata);
        return;
      }
      if (includeMetrics.length > 0) {
        metricEnricherTrack(eventName, combinedMetadata, includeMetrics);
      } else {
        window.pendo.track(eventName, combinedMetadata);
      }
      return;
    }
    if (retryCount >= maxRetries) {
      return;
    }
    setTimeout(() => {
      track(eventName, metadata, commonMetrics, includeMetrics, retryCount + 1);
    }, retryTimeinMilliseconds);
  }

  /**
   * Method for tracking event in Pendo.
   * @param {Object} dataObject Pendo data object
   *
   * `dataObject.eventName` Event name
   *
   * `dataObject.metadata` Metadata object
   *
   * `dataObject.commonMetrics` Common metrics that will be loaded and added to metadata object. List of available common metrics can be found in `usePendoCommonMetrics.js` -> `getCommonMetrics`
   *
   * `dataObject.includeMetrics` List of metrics to retrieve from backend.
   *
   * `dataObject.launchDarklyFlagKey` LaunchDarkly flag key. When the key is passed, the event track action will only be sent if current user evaluation of this flag is in experiment or if it is bypassed by the `bypassExperiments` query parameter.
   *
   * `options.appLevelTargeting` Optional boolean parameter to check if user matches app level targeting. Defaults to `true` therefore if not passed, the check will be skipped.
   *
   * @param {string} dataObject.eventName
   * @param {Object} [dataObject.metadata]
   * @param {import('./usePendoCommonMetrics').CommonMetric[]} [dataObject.commonMetrics]
   * @param {import('./usePendoCommonMetrics').IncludeMetric[]} [dataObject.includeMetrics]
   * @param {string} [dataObject.launchDarklyFlagKey]
   * @param {MaybeRef<boolean>} [options.appLevelTargeting]
   */
  async function trackPendoEvent({
    eventName,
    metadata,
    commonMetrics,
    includeMetrics = [],
    launchDarklyFlagKey,
    appLevelTargeting = true,
  }) {
    if (launchDarklyFlagKey) {
      await launchDarklyClient.value.waitUntilReady();
      const matchesAppLevelTargeting = unref(appLevelTargeting);
      const expVariationDetail = launchDarklyClient.value.variationDetail(launchDarklyFlagKey);

      if (!matchesAppLevelTargeting && !expVariationDetail.reason?.inExperiment && !isBypassExperimentsQueryEnabled()) {
        return;
      }
    }

    track(eventName, metadata, commonMetrics, includeMetrics);
  }

  /**
   * Push experiment participation and variation to Pendo, if user is part of an experiment. Only one call per LaunchDarkly flag will be passed through during lifetime of service.
   * @param {Object} options Experiment options object
   *
   * `options.launchDarklyFlagKey` Name of the LaunchDarkly key for the experiment, the experiment track event will only be sent if current user evaluation of this flag is inExperiment or if it is bypassed by the `bypassExperiments` query parameter.
   *
   * `options.defaultValue` Default value for the variation to use if value could not be fetched.
   *
   * `options.ignoreInExperimentOnRuleMatch` Optional parameter to ignore `reason?.inExperiment` check and send event to Pendo regardless of LaunchDarkly experiment status. `EXPERIMENT` event will only be sent if `reason.kind` is `RULE_MATCH` and `ignoreInExperimentOnRuleMatch` is `true`. More info on {@link https://docs.launchdarkly.com/sdk/concepts/evaluation-reasons#understanding-the-reason-data LaunchDarkly Evaluation Reasons}.
   *
   * `options.appLevelTargeting` Optional boolean parameter to check if user matches app level targeting. Defaults to `true` therefore if not passed, the check will be skipped.
   *
   *  `options.experimentEventSuffix` Optional string parameter to add a suffix to the event name. eg. EXPERIMENT_R-24-03
   *
   * @param {string} options.launchDarklyFlagKey
   * @param {*} options.defaultValue
   * @param {boolean} [options.ignoreInExperimentOnRuleMatch]
   * @param {MaybeRef<boolean>} [options.appLevelTargeting]
   */
  async function trackExperimentInPendo({
    launchDarklyFlagKey,
    defaultValue,
    ignoreInExperimentOnRuleMatch = false,
    appLevelTargeting = true,
    experimentEventSuffix = '',
  }) {
    // to avoid possible spam, only post the experiment event to pendo once per LD flag per full page refresh/lifetime of the pendo service
    if (loggedExperiments.has(launchDarklyFlagKey)) {
      return;
    }

    await launchDarklyClient.value.waitUntilReady();
    const matchesAppLevelTargeting = unref(appLevelTargeting);
    const expVariationDetail = launchDarklyClient.value.variationDetail(launchDarklyFlagKey, defaultValue);
    const ruleMatchAndIgnoreInExperimentOnRuleMatch =
      expVariationDetail.reason?.kind === 'RULE_MATCH' && ignoreInExperimentOnRuleMatch;

    if (
      // if the bypassExperiments query parameter is set, send the event to pendo regardless of other conditions
      isBypassExperimentsQueryEnabled() ||
      // if the user matchesAppLevelTargeting and is part of an experiment, send the event to pendo
      (matchesAppLevelTargeting &&
        (expVariationDetail.reason?.inExperiment || ruleMatchAndIgnoreInExperimentOnRuleMatch))
    ) {
      let experimentVariationName = '';
      switch (expVariationDetail.value) {
        case true:
          experimentVariationName = 'test';
          break;
        case false:
          experimentVariationName = 'control';
          break;
        default:
          experimentVariationName = stringify(expVariationDetail.value);
      }

      loggedExperiments.add(launchDarklyFlagKey);
      track(`EXPERIMENT${experimentEventSuffix}`, {
        experiment_id: launchDarklyFlagKey,
        experiment_variation_index: expVariationDetail.variationIndex,
        experiment_variation_value: stringify(expVariationDetail.value),
        experiment_variation_name: experimentVariationName,
        experiment_rule_id: expVariationDetail.reason?.ruleId,
      });
    }
  }

  return {
    trackPendoEvent,
    trackExperimentInPendo,
    EVENT_CATEGORIES,
  };
}

export function providePendo() {
  provide(symbol, Pendo());
}

/** @type {typeof Pendo} */
export function usePendo() {
  return inject(symbol);
}
