<script setup>
import { usePermissions, usePendo } from '@/api';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import ProjectJumpToDrawerEmptyProjectsRecent from './ProjectJumpToDrawerEmptyProjectsRecent.svg';

const { t } = useI18n();
const { canAddProjects } = usePermissions();
const { showLegacyModal } = useLegacyBridge();
const { trackPendoEvent } = usePendo();

function openProjectModal() {
  const source = 'jump_to_sidebar_menu_recent';
  trackPendoEvent({
    eventName: 'PROJECTS_EVENT',
    commonMetrics: ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'],
    metadata: {
      page_type: 'projects',
      event_action: 'add_project_clicked',
      event_category: 'advanced_discovery_event',
      source,
    },
  });

  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: { source },
  });
}
</script>
<template>
  <LscEmptyState
    class="my-8"
    size="sm"
    :title="t('No recent projects')"
    :message="t('All your recent project activity would be shown here.')"
  >
    <template #image>
      <ProjectJumpToDrawerEmptyProjectsRecent />
    </template>
    <template #actions>
      <LscButton v-if="canAddProjects" variant="primary" prependIcon="lsi-add" @click.stop="openProjectModal">
        {{ t('Add project') }}
      </LscButton>
    </template>
  </LscEmptyState>
</template>
